<ng-http-loader
  [backgroundColor]="'#047bf8'"
  [spinner]="spinkit.skWave"
  [debounceDelay]="100"
  [minDuration]="300"
>
</ng-http-loader>
<div
  class="all-wrapper menu-side with-pattern"
  [ngClass]="{ 'partner-signup': bIsPartnerSignUp }"
>
  <div
    class="auth-box-w"
    *ngIf="
      (showSignUpForm && !isMerchantPersonnal) ||
      (showPhoneVerifyForm && !isMerchantPersonnal)
    "
  >
    <div class="logo-w">
      <span style="display: table">
        <a *ngIf="!bIsPartnerSignUp" routerLink="/"
        ><img alt="" src="assets/img/Paynote_-_New_Blue.svg"
        /></a>
        <img
          *ngIf="bIsPartnerSignUp"
          alt=""
          src="assets/img/Paynote_-_New_Blue.svg"
        />
        <i
          *ngIf="objPartner.logo && isPartnerName"
          class="fa fa-plus"
          style="margin: 0 20px"
        ></i>
        <img
          *ngIf="objPartner.logo && isPartnerName"
          alt=""
          [src]="objPartner.logo"
          style="height: 53px"
        />
      </span>
    </div>
    <div *ngIf="!isPartner && !isAdmin && !isRep" class="row" style="text-align: center">
      <div
        class="col-sm-12 col-md-12 col-lg-12"
        *ngIf="bIsPartnerSignUp && isPartnerName"
      >
        <h4
          class="signup-head w-color"
          style="color: black; margin-bottom: 2em"
        >
          This {{ role }} -
          <span
          ><b>{{ objPartner.name }}</b></span
          >
          is blocked.
        </h4>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!isPartnerName">
        <h4
          class="signup-head w-color"
          style="color: black; margin-bottom: 2em"
        >
          This {{ role }} is blocked.
        </h4>
      </div>
    </div>
    <div *ngIf="isPartner || isAdmin || isRep" [hidden]="!showSignUpForm">
      <h4 *ngIf="!bIsPartnerSignUp" class="auth-header">
        Create Account
      </h4>
      <div [ngClass]="{ row: bIsPartnerSignUp }">
        <div
          class="col-sm-12 col-md-6 col-lg-6 partner-signup-info"
          *ngIf="bIsPartnerSignUp && isPartnerName"
        >
          <h3 class="signup-head w-color" style="color: black">
            <b>{{ objPartner.name }}</b>
            {{ objPartner.slogan }}
          </h3>
          <br />
          <span [innerHTML]="objPartner.body"></span>
        </div>
        <div
          class="col-sm-12 col-md-6 col-lg-6 partner-signup-info"
          *ngIf="bIsPartnerSignUp && !isPartnerName"
        >
          &nbsp;
        </div>
        <form
          name="form"
          autocomplete="nope"
          (ngSubmit)="f.form.valid && signUpValidate()"
          [formGroup]="signUpForm"
          #f="ngForm"
          class="form-validation"
          [ngClass]="{
            'col-sm-12': bIsPartnerSignUp,
            'col-md-6': bIsPartnerSignUp,
            'col-lg-6': bIsPartnerSignUp
          }"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-error has-danger':
                signUpForm.get('name').errors &&
                (signUpForm.get('name').dirty || signUpForm.get('name').touched)
            }"
          >
            <label>Contact Name</label>
            <input
              name="name"
              class="form-control"
              placeholder="First and Last name"
              type="text"
              formControlName="name"
              required
            />
            <span class="pre-icon os-icon os-icon-user-male-circle top-33" ></span>
            <div
              *ngIf="
                signUpForm.get('name').errors &&
                (signUpForm.get('name').dirty || signUpForm.get('name').touched)
              "
              class="help-block form-text with-errors form-control-feedback"
            >
              <span *ngIf="signUpForm.get('name').errors.validFullName"
              >First and Last name not valid. Enter your First and Last
                name.</span
              >
              <span *ngIf="signUpForm.get('name').errors.required"
              >First and Last name is required</span
              >
            </div>
          </div>
         <div
           class="form-group"
           [ngClass]="{
             'has-error has-danger':
               signUpForm.get('businessName').errors &&
               (signUpForm.get('businessName').dirty ||
                 signUpForm.get('businessName').touched)
           }"
         >
           <label>Legal Business Name</label>
           <input
             name="businessName"
             class="form-control"
             placeholder="Legal Business Name"
             type="text"
             formControlName="businessName"
             required
             autocomplete="nope"
           />
           <span class="pre-icon os-icon os-icon-user-male-circle top-33"></span>
           <div
             *ngIf="
               signUpForm.get('businessName').errors &&
               (signUpForm.get('businessName').dirty ||
                 signUpForm.get('businessName').touched)
             "
             class="help-block form-text with-errors form-control-feedback"
           >
             <span *ngIf="signUpForm.get('businessName').errors.required"
             >Legal Business Name is required</span
             >
           </div>
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error has-danger':
                signUpForm.get('email').errors &&
                (signUpForm.get('email').dirty ||
                  signUpForm.get('email').touched)
            }"
          >
            <label>Business Email</label>
            <input
              name="email"
              class="form-control"
              placeholder="Business Email"
              type="email"
              formControlName="email"
              required
              autocomplete="nope"
            />
            <div class="pre-icon os-icon os-icon-email-2-at top-33"></div>
            <div
              *ngIf="
                signUpForm.get('email').errors &&
                (signUpForm.get('email').dirty ||
                  signUpForm.get('email').touched)
              "
              class="help-block form-text with-errors form-control-feedback"
            >
              <span *ngIf="signUpForm.get('email').errors.required"
              >Business Email is required</span
              >
            </div>
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error has-danger':
                signUpForm.get('phone').errors &&
                (signUpForm.get('phone').dirty ||
                  signUpForm.get('phone').touched)
            }"
          >
            <label>Mobile Phone</label>
            <input
              id="signUpPhoneInput"
              name="phone"
              class="form-control"
              placeholder="Mobile Phone"
              type="text"
              formControlName="phone"
              required
              [textMask]="{ mask: utility.maskPhone }"
            />
            <div class="pre-icon os-icon os-icon-phone top-33"></div>
            <div
              *ngIf="
                signUpForm.get('phone').errors &&
                (signUpForm.get('phone').dirty ||
                  signUpForm.get('phone').touched)
              "
              class="help-block form-text with-errors form-control-feedback"
            >
              <span *ngIf="signUpForm.get('phone').errors.validPhone"
              >Mobile Phone must be at least 10 digits long.</span
              >
              <span *ngIf="signUpForm.get('phone').errors.required"
              >Mobile Phone is required</span
              >
            </div>
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error has-danger':
                signUpForm.get('type_account').errors &&
                (signUpForm.get('type_account').dirty ||
                  signUpForm.get('type_account').touched)
            }"
          >
            <label style="display: block">Account purpose</label>
            <div style="float: left; margin-right: 20px">
              <input
                style="display: inline-block"
                type="radio"
                id="business"
                formControlName="type_account"
                value="business"
              />
              <label
                style="display: inline-block; margin-left: 6px"
                for="business"
                >Business</label
              >
            </div>
            <div>
              <input
                style="display: inline-block"
                type="radio"
                id="personal"
                formControlName="type_account"
                value="personal"
              />
              <label
                style="display: inline-block; margin-left: 6px"
                for="personal"
                >Personal</label
              >
            </div>
            <span
              class="pre-icon os-icon os-icon-user-male-circle top-33"
              style="top: 21px"
            ></span>
            <div
              *ngIf="
                signUpForm.get('type_account').errors &&
                (signUpForm.get('type_account').dirty ||
                  signUpForm.get('type_account').touched)
              "
              class="help-block form-text with-errors form-control-feedback"
            >
              <span *ngIf="signUpForm.get('type_account').errors.required">
                Purpose is required
              </span>
            </div>
          </div>

<!--          <div-->
<!--            class="form-group"-->
<!--            [ngClass]="{-->
<!--              'has-error has-danger':-->
<!--                signUpForm.get('password')?.errors &&-->
<!--                (signUpForm.get('password').dirty ||-->
<!--                  signUpForm.get('password').touched)-->
<!--            }"-->
<!--          >-->
<!--            <label-->
<!--            >Password-->
<!--              <i-->
<!--                ngbTooltip="{{ messages.get('PASSWORD_REQUIREMENTS') }}"-->
<!--                aria-hidden="true"-->
<!--                class="fa fa-question-circle"-->
<!--                placement="bottom"-->
<!--              >-->
<!--              </i>-->
<!--            </label>-->
<!--            <input-->
<!--              name="password"-->
<!--              class="form-control"-->
<!--              placeholder="Password"-->
<!--              type="password"-->
<!--              formControlName="password"-->
<!--              required-->
<!--              minlength="8"-->
<!--              autocomplete="nope"-->
<!--            />-->
<!--            <div-->
<!--              class="pre-icon os-icon os-icon-fingerprint top-33"-->
<!--              style="top: 32px"-->
<!--            ></div>-->
<!--            <div-->
<!--              *ngIf="-->
<!--                signUpForm.get('password')?.errors &&-->
<!--                (signUpForm.get('password').dirty ||-->
<!--                  signUpForm.get('password').touched)-->
<!--              "-->
<!--              class="help-block form-text with-errors form-control-feedback"-->
<!--            >-->
<!--              <span *ngIf="signUpForm.get('password')?.errors.minlength"-->
<!--              >Password must be at least 8 characters long.</span-->
<!--              >-->
<!--              <span *ngIf="signUpForm.get('password')?.errors.required"-->
<!--              >Password is required</span-->
<!--              >-->
<!--              <span *ngIf="signUpForm.get('password')?.errors.pattern"-->
<!--              >Password does not match requirements</span-->
<!--              >-->
<!--            </div>-->
<!--            <ng9-password-strength-bar-->
<!--              [passwordToCheck]="signUpForm.value.password"-->
<!--              [barLabel]="barLabel"-->
<!--              [barColors]="myColors"-->
<!--            >-->
<!--            </ng9-password-strength-bar>-->
<!--          </div>-->

<!--          <div-->
<!--            class="form-group"-->
<!--            [ngClass]="{-->
<!--              'has-error has-danger':-->
<!--                signUpForm.get('cpassword')?.errors &&-->
<!--                (signUpForm.get('cpassword').dirty ||-->
<!--                  signUpForm.get('cpassword').touched)-->
<!--            }"-->
<!--          >-->
<!--            <label>Confirm Password</label>-->
<!--            <input-->
<!--              name="cpassword"-->
<!--              class="form-control"-->
<!--              placeholder="Confirm Password"-->
<!--              type="password"-->
<!--              formControlName="cpassword"-->
<!--              required-->
<!--              autocomplete="off"-->
<!--              validateEqual="password"-->
<!--            />-->
<!--            <div class="pre-icon os-icon os-icon-fingerprint top-33"></div>-->
<!--            <div-->
<!--              *ngIf="-->
<!--                signUpForm.get('cpassword')?.errors &&-->
<!--                (signUpForm.get('cpassword').dirty ||-->
<!--                  signUpForm.get('cpassword').touched)-->
<!--              "-->
<!--              class="help-block form-text with-errors form-control-feedback"-->
<!--            >-->
<!--              Password mismatch-->
<!--            </div>-->
<!--          </div>-->

          <div
            class="form-group"
            *ngIf="isSubDomenSandBox()"
            [ngClass]="{
              'has-error has-danger':
                signUpForm.get('sandbox_register_code').errors &&
                (signUpForm.get('sandbox_register_code').dirty ||
                  signUpForm.get('sandbox_register_code').touched)
            }"
          >
            <label>Sandbox Registration Code</label>
            <input
              id="signUpSandboxCodeInput"
              name="sandbox_register_code"
              class="form-control"
              formControlName="sandbox_register_code"
              placeholder="Sandbox Registration Code"
              type="text"
              required
            />
            <div class="pre-icon os-icon">
              <i class="icon-feather-hash"></i>
            </div>
            <div
              *ngIf="
                signUpForm.get('sandbox_register_code').errors &&
                (signUpForm.get('sandbox_register_code').dirty ||
                  signUpForm.get('sandbox_register_code').touched)
              "
              class="help-block form-text with-errors form-control-feedback"
            ></div>
          </div>
          <div class="text-left">
            <label style="font-size: 14px; color: black">
              <input
                formControlName="comfirmHaveLegalUsBusiness"
                required
                type="checkbox"
                class="form-control"
              />&nbsp;&nbsp; I confirm I have a registered legal US Business.
            </label>
          </div>
          <div class="text-left">
            <label style="font-size: 14px; color: black">
              <input
                formControlName="acceptPolicy"
                required
                type="checkbox"
                class="form-control"
              />&nbsp;&nbsp; I have read and accept the Paynote
              <a
                href="https://www.seamlesschex.com/terms-of-service/"
                target="_blank"
              >
                <b>Terms of Service</b>
              </a>
              and
              <a
                href="https://www.seamlesschex.com/privacy-policy/"
                target="_blank"
              >
                <b>Privacy Policy</b> </a
              >, as well as our partner Dwolla’s
              <a href="https://www.dwolla.com/legal/tos/" target="_blank">
                <b>Terms of Service</b>
              </a>
              and
              <a href="https://www.dwolla.com/legal/privacy/" target="_blank">
                <b>Privacy Policy</b> </a
              >.
            </label>
          </div>

          <div class="buttons-w">
            <button
              [disabled]="!signUpForm.valid"
              type="submit"
              class="btn btn-primary btn-rounded btn-rounded-margin"
            >
              Create Account
            </button>
            <button
              *ngIf="!bIsPartnerSignUp"
              class="btn btn-default pull-right btn-rounded btn-rounded-margin"
              routerLink="/login"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>

    <div [hidden]="!showPhoneVerifyForm">
      <div [ngClass]="{ row: bIsPartnerSignUp }">
        <div
          class="col-sm-12 col-md-6 col-lg-6 partner-signup-info"
          style="padding-bottom: 40px"
          *ngIf="bIsPartnerSignUp"
        >
          <h3 class="signup-head w-color" style="color: black">
            <b>{{ objPartner.name }}</b>
            {{ objPartner.slogan }}
          </h3>
          <br />
          <span [innerHTML]="objPartner.body"></span>
        </div>
          <h4
          class="auth-header"
          style="padding-right: 40px; font-size: 20px; margin-bottom: 30px">
              <span>
                <span >Verify your phone</span>
              </span>
      </h4>

        <form
          name="form2FA"
          (ngSubmit)="f2FA.form.valid && signUp()"
          #f2FA="ngForm"
          autocomplete="off"
          [formGroup]="verifyPhoneForm"
          [ngClass]="{
            'col-sm-12': bIsPartnerSignUp,
            'col-md-6': bIsPartnerSignUp,
            'col-lg-5': bIsPartnerSignUp
          }"
          (keydown)="signUp($event)"
          style="padding-top: 0">

        <p

            class="auth-header1"
            style="padding-right: 40px; font-size: 15px; margin-bottom: 30px"
        >
            <span>{{ getVerificationTitle() }}</span>
        </p>
          <div
            class="form-group"
            [ngClass]="{
              'has-error has-danger':
                verifyPhoneForm.get('phone_pin').errors &&
                (verifyPhoneForm.get('phone_pin').dirty ||
                  verifyPhoneForm.get('phone_pin').touched)
            }"
          >
            <label for="phone_pin">
              <span *ngIf="!isVoiceCode()">Pin Code</span>
            </label>
            <input
              name="phone_pin"
              class="form-control"
              placeholder="Code"
              type="text"
              maxlength="6"
              appNumbers
              formControlName="phone_pin"
              required
              id="phone_pin"
            />
            <div class="pre-icon" style="top: +45%">
              <i class="icon-feather-hash"></i>
            </div>
            <div
              *ngIf="
                verifyPhoneForm.get('phone_pin').errors &&
                (verifyPhoneForm.get('phone_pin').dirty ||
                  verifyPhoneForm.get('phone_pin').touched)
              "
              class="help-block form-text with-errors form-control-feedback"
            >
              <span *ngIf="verifyPhoneForm.get('phone_pin').errors.required">
                Code is required
              </span>
              <span *ngIf="verifyPhoneForm.get('phone_pin').errors.minlength">
                Code must be at least 6 digits long.
              </span>
            </div>
          </div>

          <button
            style="padding: 0; font-size: 12px; float: left"
            [disabled]="loading || timerService.isTimerRun"
            type="button"
            class="btn btn-link"
            (click)="sendVerifyPhoneCode()"
          >
            <span>Resend Code </span>
            <span [innerHTML]="timerService.getRemainigTime()"></span>
          </button>
          <button
            style="padding: 0; font-size: 12px; float: right"
            type="button"
            [disabled]="loading"
            class="btn btn-link"
            (click)="backToSignUpForm()"
          >
            <span
            >Change Phone</span
            >
          </button>
          <br />
          <span style="display: none"
          ><countdown-timer
            *ngIf="timerService.isTimerRun"
            [countDownTimerConfig]="timerService.configCD"
          ></countdown-timer
          ></span>
          <div class="buttons-w">
            <button
              [disabled]="loading"
              type="button"
              class="btn btn-default btn-rounded"
              (click)="backToSignUpForm()"
            >
              Back
            </button>
            &nbsp;
            <button
              type="submit"
              [disabled]="loading || !verifyPhoneForm.valid"
              class="btn btn-primary pull-right btn-rounded"
            >
              Submit & Verify
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="showAlreadyRegisteredError" class="row email-error-div">
      <a
        class="strong"
        routerLink="/login"
        style="margin: 10px 50px; color: black"
      >
        It appears you already registered. Click here to login
      </a>
    </div>
  </div>
  <div
    class="auth-box-w personnal-signup-thanks"
    *ngIf="
      (!showSignUpForm && isMerchantPersonnal) ||
      (!showPhoneVerifyForm && isMerchantPersonnal)
    "
  >
    <div class="logo-w">
      <span>
        <a *ngIf="!bIsPartnerSignUp" routerLink="/"
        ><img alt="" src="assets/img/Paynote_-_New_Blue.svg"
        /></a>
        <img
          *ngIf="bIsPartnerSignUp"
          alt=""
          src="assets/img/Paynote_-_New_Blue.svg"
        />
        <i
          *ngIf="objPartner.logo && isPartnerName"
          class="fa fa-plus"
          style="margin: 0 20px"
        ></i>
        <img
          *ngIf="objPartner.logo && isPartnerName"
          alt=""
          [src]="objPartner.logo"
          style="height: 53px"
        />
      </span>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-12 pst-text">
      <p style="font-weight: initial">
        Thank you for your interest in Paynote.<br />
        Unfortunately, we do not support personal accounts at this time.<br />
        We will reach out via email once personal accounts are supported.<br />
        If you would like to use Paynote for business and have a business bank
        account, please email
        <a href="mailto:paynote@seamlesschex.com">paynote@seamlesschex.com</a>.
      </p>

      <p></p>
      <p style="font-weight: initial">Thanks,</p>
      <p style="font-weight: initial">-The Paynote Team</p>
      <a
        class="btn btn-link pull-right"
        routerLink="/login"
        style="padding: 0px"
      >Login</a
      >
    </div>
  </div>
</div>
