import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {TimerService} from '../../../_services/timer.service';
import {UserService} from '../../../_services/user.service';
import {TopAlertsService} from '../../../_services/top-alerts.service';
import {TwoFaService} from '../../../_services/two-fa.service';
import {FormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-two-fa-popup',
  templateUrl: './two-fa-popup.component.html',
  styleUrls: ['./two-fa-popup.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    NgIf
  ]
})
export class TwoFaPopupComponent implements OnInit, OnDestroy {

  code: string = '';
  loading: boolean = false;
  title: string = '';
  form: any = {
    code: '',
    u_token: this.userService.getToken(this.userService.isManagerLogined() ? 'manager' : null)
  };
  twoFaService: TwoFaService;

  constructor(
    private dialogRef: MatDialogRef<TwoFaPopupComponent>,
    private http: HttpClient,
    public timerService: TimerService,
    public userService: UserService,
    public toasterService: TopAlertsService,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: { request: HttpRequest<any>, response: HttpResponse<any> },
  ) {
    this.timerService.initTimerCD('down');
  }

  ngOnInit() {
    this.timerService.setTime(60);
    this.timerService.downCount();
    if (this.data?.response?.body?.verify_by_google_app !== undefined && this.data?.response?.body?.verify_by_google_app) {
      this.timerService.timerFinish('STOP');
    }
    this.title = this.data.response.body.message;
    if (this.injector.get(TwoFaService)) {
      this.twoFaService = this.injector.get(TwoFaService);
    }
  }

  ngOnDestroy() {
  }

  submit(event = null) {
    let bValid = false;
    if (event && event.key == 'Enter' && event.keyCode == 13 && this.form.code.length == 6) {
      bValid = true;
    }
    if (!bValid) {
      return;
    }

    const clonedRequest = this.data.request.clone({
      setParams: {code: this.form.code}
    });

    this.http.request(clonedRequest).subscribe({
      next: (response: any) => {
        this.form.code = '';
        if (response instanceof HttpResponse) {
          if (response.body) {
            this.timerService.timerFinish('STOP');
          }
          this.dialogRef.close(response);
        }
      },
      error: (error) => {
        if (error.error.message !== 'Invalid verification code.') {
          this.dialogRef.close(error);
        } else {
          this.toasterService.popToast(
            'error',
            '',
            error.error.message
          );
        }
      }
    });
  }

  close() {
    return this.dialogRef.close();
  }

  resend() {
    if (!this.timerService.isTimerRun) {
      return this.http.request(this.data.request).subscribe((e: any) => {
        if (e instanceof HttpResponse) {
          if (e.body?.success) {
            this.timerService.setTime(60);
            this.title = e?.body?.message;
            this.toasterService.popToast('success', '', e.body.message);
          }
        }
      });
    }
  }
}
