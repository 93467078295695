import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { JqueryService } from "../../_services/jquery.service";
import { Utility } from "../../_helpers/utility";

import { AuthenticationService } from "../../auth.service";
import { ErrorService } from "../../_services/error.service";
import { validateFullName } from "../../_vaidators/fullName";
import { validateEmail } from "../../_vaidators/email";
import { validatePhone } from "../../_vaidators/phone";
import { Spinkit } from "ng-http-loader";
import { MessagesService } from "../../_services/messages.service";
import { UserService } from "../../_services/user.service";
import { TopAlertsService } from "../../_services/top-alerts.service";
import { FakersService } from "../../_services/fakers.service";
import { TimerService } from "../../_services/timer.service";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
  providers: [ErrorService],
})
export class SignUpComponent implements OnInit {
  public spinkit = Spinkit;
  public host: string;
  public model: any = {};
  public invitation: any = {};
  public loading = false;

  public isSuccessSendCode = false;
  public successSendCodeMessage = "";
  public errorSendCodeMessage = "";
  public isErrorSendCode = false;
  public isMerchantPersonnal = false;
  public showSignUpForm = true;
  public showPhoneVerifyForm = false;
  public myColors = ["#DD2C00", "#FF6D00", "#FFD600", "#AEEA00", "#00C853"];
  public barLabel = "";
  public strInviteCode = "";
  public strPartnerCode = "";
  public strRepoCode = "";
  public objPartner: any = {
    name: "",
    slogan: <string>(
      "invites you to sign up for Paynote. The easiest way to send and receive Payments Online."
    ),
    // tslint:disable-next-line:max-line-length
    body: <string>(
      '<p> <i class="picons-thin-icon-thin-0154_ok_successful_check"></i> Send Digital Checks Via Email </p><p> <i class="picons-thin-icon-thin-0154_ok_successful_check"></i> Request Checks and Send Invoices Via Email </p><p> <i class="picons-thin-icon-thin-0154_ok_successful_check"></i> Send Mass Payouts with CSV upload </p><p> <i class="picons-thin-icon-thin-0154_ok_successful_check"></i> Easily Schedule Recurring Payments </p><p> <i class="picons-thin-icon-thin-0154_ok_successful_check"></i> Fast Next Day Electronic Transfers </p>'
    ),
    logo: <string>"",
  };
  public strPhoneType = "";
  public bIsInvite = false;
  public bIsPartnerSignUp = false;
  public isPartnerName = false;
  public isPartner = true;
  public isRep = false;
  public isAdmin = false;
  public role = "partner";
  public bIsPartnerLogo = false;
  public isShowVerifyPhone = false;
  public showAlreadyRegisteredError = false;

  public signUpForm: FormGroup;
  public verifyPhoneForm: FormGroup;
  verificationTitle: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private fb: FormBuilder,
    private _formBuilder: FormBuilder,
    public utility: Utility,
    private jqueryService: JqueryService,
    private activatedRoute: ActivatedRoute,
    public messages: MessagesService,
    private userService: UserService,
    public topAlertsService: TopAlertsService,
    public fakersService: FakersService,
    public timerService: TimerService
  ) {
    this.host = environment.host;
  }

  ngOnInit() {
    this.timerService.initTimerCD("down");
    if (this.activatedRoute.snapshot.paramMap.get("invite_code")) {
      this.strInviteCode =
        this.activatedRoute.snapshot.paramMap.get("invite_code");
      this.bIsInvite = true;
    }
    if (this.activatedRoute.snapshot.paramMap.get("partner_name")) {
      this.strPartnerCode =
        this.activatedRoute.snapshot.paramMap.get("partner_name");
      this.bIsPartnerSignUp = true;
    }
    if (this.activatedRoute.snapshot.paramMap.get("rep_name")) {
      this.strRepoCode = this.activatedRoute.snapshot.paramMap.get("rep_name");
    }
    this.authenticationService.clearLogin();
    this.jqueryService.removeSelector(".modal-backdrop.fade");
    this.jqueryService.removeSelector(".modal.fade.show.d-block");

    this.signUpForm = this._formBuilder.group({
      phone: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14),
          validatePhone,
        ]),
      ],
      type_account: ["business", Validators.compose([Validators.required])],
      // password: [
      //   "",
      //   Validators.compose([
      //     Validators.required,
      //     Validators.minLength(8),
      //     Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
      //     //        Validators.minLength(8)
      //   ]),
      // ],
      // cpassword: [
      //   "",
      //   Validators.compose([Validators.required, Validators.minLength(8)]),
      // ],
      sandbox_register_code: [""],
      //      phone_pin: ['', Validators.compose([
      //        Validators.required,
      //        Validators.minLength(6),
      //        Validators.maxLength(6)])],
      name: ["", Validators.compose([Validators.required, validateFullName])],
      businessName: ["", Validators.compose([Validators.required])],
      email: ["", Validators.compose([Validators.required, validateEmail])],
      acceptPolicy: [false, Validators.compose([Validators.required])],
      comfirmHaveLegalUsBusiness: [false, Validators.compose([Validators.required])],
    });

    this.verifyPhoneForm = this._formBuilder.group({
      phone_pin: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
    });

    if (this.bIsInvite) {
      this.signUpForm.get("email").disable();
      this.getInviteEmail();
    }
    if (this.bIsPartnerSignUp) {
      this.getPartnerInfo();
    }

    this.autoFill();
  }

  autoFill() {
    if (environment.subDomen != "local" && environment.subDomen != "dev") {
      return;
    }

    this.signUpForm.patchValue({
      name: this.fakersService.getFullName(),
      email: this.fakersService.getEmail(),
      businessName: this.fakersService.getCompanyName(),
      phone: "1122334455",
      password: "Qwerty22",
      cpassword: "Qwerty22",
      acceptPolicy: false,
      comfirmHaveLegalUsBusiness: false
    });
  }

  getPartnerInfo() {
    const objRequest = {
      partner_id: this.strPartnerCode,
    };
    this.http
      .get<any>(this.host + "/user/partner", { params: objRequest })
      .subscribe(
        (response) => {
          if (response.success) {
            this.objPartner.name = response.name;
            /*this.getUserDetails(response.u_token);*/
            this.getPartnerLogo(response.u_token);
            this.isPartnerName = true;
            /*this.isPartner = response.is_partner == 1 ? true : false;*/
            if (response.is_partner == 1) {
              this.isPartner = true;
            } else {
              this.isPartner = false;
            }
            this.isRep = response.is_rep;
            this.isAdmin = response.is_admin;
            if (this.isRep) {
              this.role = "rep";
            }
            if (this.isAdmin) {
              this.role = "agent";
            }
          }
        },
        (err) => {
          if (err.error) {
            console.log(err.error);
            this.isPartner = false;
            // this.topAlertsService.popToast('error', 'Error', err.error.message);
          }
        }
      );
  }

  getUserDetails(u_token) {
    this.http
      .get<any>(this.host + "/user/merchant/details", {
        params: {
          u_token: u_token,
        },
      })
      .subscribe(
        (response) => {
          if (response.success) {
            if (response.partner_logo) {
              this.bIsPartnerLogo = true;
              this.objPartner.logo = response.partner_logo;
            }
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            /*this.errorService.getMessageError(errResponse.error);*/
            this.topAlertsService.popToast("error", "Error", errResponse.error);
          }
        }
      );
  }

  getPartnerLogo(u_token) {
    this.http
      .get<any>(this.host + "/user/partner/logo", {
        params: {
          u_token: u_token,
        },
      })
      .subscribe(
        (response) => {
          if (response.success) {
            if (response.partner_logo) {
              this.bIsPartnerLogo = true;
              this.objPartner.logo = response.partner_logo.logo;
              this.objPartner.slogan = response.partner_logo.slogan;
              this.objPartner.body = response.partner_logo.body;
            }
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            /*this.errorService.getMessageError(errResponse.error);*/
            this.topAlertsService.popToast("error", "Error", errResponse.error);
          }
        }
      );
  }

  getInviteEmail() {
    this.http
      .post<any>(this.host + "/user/invitation/retrieve", {
        inv_token: this.strInviteCode,
      })
      .subscribe(
        (response) => {
          if (response.success && response.invitation) {
            this.invitation = response.invitation;
            this.signUpForm.patchValue({ email: response.invitation.email });
          } else {
            this.bIsInvite = false;
            this.signUpForm.get("email").enable();
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  showVerifyPhone() {
    this.sendVerifyPhoneCode();
    this.isShowVerifyPhone = true;
  }

  sendVerifyPhoneCode(): any {
    this.timerService.setTime(60, 's');
    this.http
      .post<any>(this.host + "/user/phone/register", {
        phone: this.signUpForm.value.phone,
        email: this.signUpForm.value.email,
        phone_type: this.strPhoneType,
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.topAlertsService.popToast(
              'success',
              '',
              response.message,
            );
            this.verificationTitle = response.message;
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            if (
              errResponse.error.label == "PHONE_IS_NOT_VALID" ||
              errResponse.error.label == "PHONE_ALREDY_IN_USE"
            ) {
              this.signUpForm.get("phone").setValue("");
            }
            this.showSignUpForm = true;
            this.showPhoneVerifyForm = false;
            this.utility.getMessageError(errResponse.error);
            if (errResponse.error.message.indexOf("Too Many Attempts") !== -1) {
              this.topAlertsService.popToast(
                "error",
                "Error",
                "Too Many Attempts."
              );
            } else {
              this.topAlertsService.popToast(
                "error",
                "Error",
                this.utility.errorMessage
              );
            }
          }
        }
      );
  }

  availableSignUpByEmal() {
    return environment.availableSignUpByEmal;
  }

  signUpVerifyPhone() {
    /*if (this.isSubDomenSandBox() || this.availableSignUpByEmal()) {
      setTimeout(() => this.sendVerifyEmailCode(), 1500);
    } else {
    }*/
    setTimeout(this.sendVerifyPhoneCode(), 1500);
    this.showSignUpForm = false;
    this.showPhoneVerifyForm = true;
  }

  signUpValidate() {
    this.showAlreadyRegisteredError = false;
    this.loading = true;
    let objRequest;
    if (this.bIsInvite) {
      objRequest = Object.assign(this.signUpForm.value, {
        email: this.invitation.email,
        inv_token: this.invitation.inv_token,
      });
    } else {
      objRequest = Object.assign(this.signUpForm.value);
    }
    this.http
      .post<any>(this.host + "/user/merchant/register/validate", objRequest)
      .subscribe(
        (response) => {
          this.loading = false;
          if (response.success) {
            if (objRequest.type_account == "personal") {
              this.signUp(null, true);
            } else {
              if (this.isSubDomenSandBox() || this.availableSignUpByEmal()) {
                // this.topAlertsService.popToast(
                //   "success",
                //   "",
                //   'Welcome to Paynote! Please confirm your account by entering the code sent to your email.'
                // );
              } else {
                this.verificationTitle = response.message;
                this.topAlertsService.popToast(
                  'success',
                  '',
                  response.message,
                );
                // this.topAlertsService.popToast(
                //   "success",
                //   "",
                //   'Welcome to Paynote! Please confirm your account by entering the code sent to your phone.'
                // );
              }

              this.timerService.setTime(60, "s");
              this.strPhoneType = response.phone_type;
              this.signUpVerifyPhone();
            }
          }
        },
        (errResponse) => {
          this.loading = false;
          if (errResponse?.error) {
            if (
              errResponse.error.label == "PHONE_IS_NOT_VALID" ||
              errResponse.error.label == "PHONE_ALREDY_IN_USE"
            ) {
              this.signUpForm.get("phone").setValue("");
            }
            if (
              errResponse.error.label == "EMAIL_IS_UNDELIVERABLE" ||
              errResponse.error.label == "EMAIL_ALREDY_IN_USE"
            ) {
              this.signUpForm.get("email").setValue("");
            }
            if (errResponse.error.messages.includes("already_registered")) {
              errResponse.error.messages.splice(
                errResponse.error.messages.indexOf("already_registered"),
                1
              );
              this.showAlreadyRegisteredError = true;
            }
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  isVoiceCode() {
    return this.strPhoneType === "voip" || this.strPhoneType === "landline";
  }

  backToSignUpForm() {
    this.verifyPhoneForm.reset();
    this.showSignUpForm = true;
    this.showPhoneVerifyForm = false;
  }

  signUp(event: any = null, skipVerification: any = false) {
    let bValid = false;
    if (
      event &&
      event.key == "Enter" &&
      event.keyCode == 13 &&
      this.verifyPhoneForm.valid
    ) {
      bValid = true;
    }
    if (!event && this.verifyPhoneForm.valid) {
      bValid = true;
    }
    if (!bValid && !skipVerification) {
      return;
    }

    this.loading = true;
    let signUpForm;
    if (this.bIsInvite) {
      signUpForm = Object.assign(
        this.signUpForm.value,
        this.verifyPhoneForm.value,
        {
          email: this.invitation.email,
          inv_token: this.invitation.inv_token,
          partnerInviteCode: this.strPartnerCode,
        }
      );
    } else {
      signUpForm = Object.assign(
        this.signUpForm.value,
        this.verifyPhoneForm.value,
        { skipVerification: skipVerification },
        { is_onboarding: true },
        { partnerInviteCode: this.strPartnerCode, repoInviteCode: this.strRepoCode }
      );

      if (skipVerification) {
        signUpForm.phone_pin = null;
      }
    }
    if (localStorage.getItem("google_campaign")) {
      signUpForm.google_campaign = JSON.parse(
        localStorage.getItem("google_campaign")
      );
    }

    this.authenticationService.signUp(signUpForm).subscribe(
      (result) => {
        this.showSignUpForm = false;
        this.showPhoneVerifyForm = false;
        this.loading = false;
        const objResp = <any>result;
        if (
          objResp.success &&
          objResp.token &&
          objResp.user.type != "personal"
        ) {
          this.timerService.timerFinish('STOP');
          localStorage.removeItem("google_campaign");
          this.jqueryService.pushLeadConversion();
          // this.topAlertsService.popToast(
          //   "success",
          //   "",
          //   this.messages.get("PLEASE_SET_YOUR_PASSWORD")
          // );
          this.router.navigateByUrl("/password-set/"+objResp.password_token)
        }
        if (
          objResp.success &&
          objResp.token &&
          objResp.user.type == "personal"
        ) {
          this.isMerchantPersonnal = true;
        }
        return false;
      },
      (errResponse) => {
        this.loading = false;
        if (errResponse.error) {
          if (
            this.signUpForm.value.type_account == "personal"
            && (errResponse.error.message.includes('During the sign-up your email has been blocked. Please contact the admin')
            || errResponse.error.message == 'Invalid verification code.')
          ) {
            this.isMerchantPersonnal = true;
          } else {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      }
    );
  }

  sendVerifyEmailCode() {
    this.timerService.setTime(60, 's');
    this.authenticationService
      .sendVerifyCodeToEmail(this.signUpForm.value.email)
      .subscribe(
        (response) => {
          if (response.success) {
            this.topAlertsService.popToast("success", "", response.message);
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            // if (errResponse.error.label == 'PHONE_IS_NOT_VALID' || errResponse.error.label == 'PHONE_ALREDY_IN_USE') {
            //   this.signUpForm.get('phone').setValue('');
            // }
            this.showSignUpForm = true;
            this.showPhoneVerifyForm = false;
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  isSubDomenSandBox() {
    return this.userService.isSubDomenSandBox();
  }

  getVerificationTitle() {
    if (this.verificationTitle !== undefined && this.verificationTitle) {
      return this.verificationTitle;
    }
    return 'Verify your phone';
  }
}
