import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'loading',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.css']
})
export class LoadingModalComponent {
  private modal: any;
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    if (loading) {
    this.modal = this.modalService.open(
      this.loadingModal,
      {
        centered: true,
        keyboard: false,
        backdrop: 'static',
        backdropClass: 'bg-secondary',
        windowClass: 'transparent',
        container: '#modal-container'
      });
    } else {
      this.modal.close();
    }
  }
  _loading = false;
  @ViewChild('loadingModal', { static: true })
  private loadingModal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
  ) { }
}
