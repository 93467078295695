
<ng-progress color="purple"  [thick]="true"></ng-progress>


<div class="all-wrapper menu-side with-pattern">
    <div class="auth-box-w">
        <div class="logo-w">
            <a routerLink="/"><img alt="" src="assets/img/Paynote_-_New_Blue.svg"></a>
        </div>
        <h4 class="text-center">
            Multi-factor Authentication
        </h4>

        <form name="form2FA" (ngSubmit)="f2FA.form.valid && login(null)" (keydown)="login($event)"  #f2FA="ngForm" autocomplete="off">
            <p>{{ getVerificationMessage() }}</p>
            <div class="form-group" [ngClass]="{ 'has-error has-danger': !pinCode.valid && (pinCode.dirty || pinCode.touched) }">
                <label>Verification Code</label>
                <input name="pinCode" class="form-control" placeholder="Enter MFA Code" type="text"
                       [(ngModel)]="model.pinCode" #pinCode="ngModel" maxlength="6" required>
                <div class="pre-icon">
                    <i class="icon-feather-hash"></i>
                </div>
                <div *ngIf="!pinCode.valid && (pinCode.dirty || pinCode.touched)"
                     class="help-block form-text with-errors form-control-feedback">Verification Code is required</div>
            </div>
            <div class="buttons-w">
                <button [disabled]="loading || !f2FA.form.valid" type="submit" class="pull-right btn btn-primary btn-rounded btn-rounded-margin">Submit</button>
                <button [disabled]="loading || timerService.isTimerRun" *ngIf="!isVerifyByGoogleApp"
                        class="btn btn-default  btn-rounded btn-rounded-margin" (click)="resend()">
                        <span *ngIf="!isUseLandingPhone">
                          Resend Code
                        </span>
                        <span *ngIf="isUseLandingPhone">
                          Resend Code
                        </span>
                    <span [innerHTML]="timerService.getRemainigTime()"></span>
                </button>

                <br><br>
                <a (click)="backToLogin()" class="strong" href="javascript:void(0)">Cancel</a>
            </div>
            <span style="display: none;" *ngIf="!isVerifyByGoogleApp"><countdown-timer *ngIf="timerService.isTimerRun" [countDownTimerConfig]="timerService.configCD"></countdown-timer></span>
            <br>
        </form>
    </div>

</div>

<loading [loading]="loading"></loading>
