import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthenticationService} from '../auth.service';
import {TwoFaService} from '../_services/two-fa.service';

@Injectable()
export class TwoFaInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          TwoFaService.$onRequest.next({req, response: event});
        }
      }),
      catchError((err: any) => {
        if (err?.error?.message === 'FINGERPRINT_AUTHORIZE_REQUIRED') {
          this.authService.$FPBVerification.next(true);
        }
        if (err.error.message !== 'Invalid verification code.') {
          TwoFaService.$onError.next(err);
        }
        return throwError(err);
      })
    );
  }

}
