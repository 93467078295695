import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '../../_services/user.service';
import {PermissionsService} from '../../_services/permissions.service';
import {AuthenticationService} from '../../auth.service';
import {JqueryService} from '../../_services/jquery.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Utility} from '../../_helpers/utility';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {DialogService} from '../../_services/dialog.service';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordValidator} from '../../_directives/passwordValidator';
import {matchOtherValidator} from '../settings/matchOtherValidator';
import {MessagesService} from '../../_services/messages.service';
import {JwtInterceptor} from '../../_helpers/jwt.interceptor';
import {TimerService} from '../../_services/timer.service';
import {TwoFaService} from '../../_services/two-fa.service';

@Component({
  selector: 'app-manager-account',
  templateUrl: './manager-account.component.html',
  styleUrls: ['./manager-account.component.css'],
})
export class ManagerAccountComponent implements OnInit, OnDestroy {
  public host: string = environment.host;
  public nextCallBack: any;
  public objUserInfo: any = {};
  public modalRef: any;
  tokenRole = 'manager';
  res: Object;
  message: string;
  cssAnimateFlag = false;
  isShowCurrentPass = false;
  isShowNewPass = false;
  isShowNewConfPass = false;
  isOldPassError = false;
  rewritePass: FormGroup;
  verificationTitle = '2FA Verification';
  public objPreviewQRcode: any = {
    code: '',
    u_token: this.userService.getManager().u_token,
  };
  public modelVerify: any = {
    pin_code: '',
    u_token: this.userService.getToken(this.tokenRole)
  };
  errorObject = {
    error: false,
    message: '',
  };
  modelChangePassword: any;
  isPhoneVerification: boolean = false;

  @ViewChild('verifiChange2faType', {static: true})
  private verifiChange2faType: TemplateRef<any>;

  @ViewChild('editPhoneNumber', {static: true})
  private editPhoneNumber: TemplateRef<any>;

  @ViewChild('addChangePassword', {static: true})
  private addChangePassword: TemplateRef<any>;

  @ViewChild('showQRcodePreview', { static: true })
  private showQRcodePreview: TemplateRef<any>;

  public isLoading: boolean;
  public objAccountComp: any = {
    twofa_type: this.userService.getManager().settings.twofa_type,
  };

  constructor(
    private http: HttpClient,
    public userService: UserService,
    public permissionsService: PermissionsService,
    public authenticationService: AuthenticationService,
    public _jqueryService: JqueryService,
    private modalService: NgbModal,
    public utility: Utility,
    public topAlertsService: TopAlertsService,
    public dialogService: DialogService,
    public messages: MessagesService,
    public timer: TimerService,
    private twoFaService: TwoFaService,
  ) {
    this.modelChangePassword = {
      currentPassword: '',
      newtPassword: '',
      newtPasswordConfirm: '',
      pinCode: '',
    };
    this.rewritePass = new FormGroup({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          PasswordValidator.patternValidator(/\d/, {
            hasNumber: true,
          }),
          // check whether the entered password has upper case letter
          PasswordValidator.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          // check whether the entered password has a lower case letter
          PasswordValidator.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          // check whether the entered password has a special character
          PasswordValidator.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true,
            }
          ),
          Validators.minLength(8),
        ])
      ),
      newPasswordConfirm: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          matchOtherValidator('newPassword'),
        ])
      ),
    });
  }

  ngOnInit(): void {
    JwtInterceptor.logeined = 'manager';
    this.objAccountComp.twofa_type = this.userService.getManager().settings.twofa_type;
  }

  eventVerify2Fa(callback: any, secondCallback: any = null) {
    if (callback == 'sms') {
      this.isPhoneVerification = true;
      this.verificationTitle = 'Phone verification';
      this.nextCallBack = () => {
        this.verificationTitle = '2FA Verification';
        this.topAlertsService.popToast(
          'success',
          'Phone number Successfully verified',
          '',
        );
        if (secondCallback) {
          secondCallback();
        }
        this.closeModal();
      };
    } else {
      this.nextCallBack = callback;
    }
    this.timer.setTime(60, 's');
    switch (this.isPhoneVerification ? 'sms' : this.userService.getManager().settings.twofa_type) {
      case 'sms': {
        this.authenticationService.sendVerifyPhoneCodeForAuthUser(this.userService.getToken(this.tokenRole)).subscribe(e => {
          this.topAlertsService.popToast(
            'info',
            '',
            e.message,
          );
        });
        break;
      }
      default: {
        this.authenticationService.sendVerifyCodeToEmail(
          this.userService.getEmail(this.tokenRole)
        ).subscribe(
          e => this.topAlertsService.popToast('info', '', e.message)
        );
      }
    }
    this.openDialogModal(this.verifiChange2faType, false, '#model_verify_pin_code');
  }

  openDialogModal(content: any, bIsBig: boolean = false, setFocusTo: string | null = null) {
    if (this.modalRef !== undefined && this.modalRef !== null) {
      this.modalRef.close();
    }
    if (!bIsBig) {
      this.modalRef = this.modalService.open(content);
    } else {
      this.modalRef = this.modalService.open(content, {size: 'lg'});
    }
    if (setFocusTo) {
      this._jqueryService.setFocus(setFocusTo);
    }
  }

  openChangePhoneNumber() {
    this.objUserInfo.phone = this.userService.getManager().phone;
    this.closeModal();
    this.openDialogModal(this.editPhoneNumber, false, '#update_phone_number');
  }

  closeModal() {
    if (this.modalRef) {
      this.objUserInfo.phone = this.userService.getManager().phone;
      this.modalRef.close();
      this.modalRef = null;
    }
  }

  openAddChangePassword() {
    this.closeModal();
    this.openDialogModal(this.addChangePassword, false, '#currentPassword');
  }

  showError(error: any) {
    this.isLoading = false;
    this.utility.getMessageError(error);
    this.topAlertsService.popToast(
      'error',
      'Error',
      this.utility.errorMessage
    );
  }


  change2FAAuthType() {
    const objDataDialog = {
      title: 'Please confirm',
      text: 'Are you sure you want to change 2FA method',
      button_cancel_text: 'Cancel',
      button_confirm_text:
        this.utility.getMessageUsability('confirm_and_accept'),
      checkbox_confirm: false,
    };

    this.dialogService
      .openDialog(objDataDialog)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (this.objAccountComp.twofa_type == 'sms' && this.userService.getManager().settings.required_phone_verification) {
            this.eventVerify2Fa('sms');
          } else {
          this.submitTwoFaType();
          }
        } else {
          this.objAccountComp.twofa_type = this.userService.getManager().settings.twofa_type;
        }
      });
  }

  submitTwoFaType = () => {
    const objRequest = {
      u_token: this.userService.getToken(this.tokenRole),
      twofa_type: this.objAccountComp.twofa_type,
    };

    this.twoFaService
      .verify('/user/twofa/type', objRequest)
      .subscribe(
        (response) => {
          console.log('resp', response);
          if (response) {
            this.closeModal();
            this.userService.reInitClient();
            this.isLoading = false;
            if ( this.objAccountComp.twofa_type == 'google_app' && !this.userService.isTwofaAuthAppInstalled(this.tokenRole) ) {
              this.objPreviewQRcode = Object.assign( this.objPreviewQRcode, response );
              this.openDialogModal( this.showQRcodePreview, false, '#pin_code_add_app_auth' );
            } else {
              this.topAlertsService.popToast(
                'success',
                'Success',
                '2FA method changed successfully '
              );
            this.isLoading = false;

              this.closeModal();
            }

          } else {
            this.ngOnInit();
          }
        },
        (err) => {
          if (err?.error) {
            this.showError( err.error );
          }
          this.isLoading = false;
        }
      );
  }

  cancelChange2FaType() {
    this.objAccountComp.twofa_type = this.userService.getManager().settings.twofa_type;
  }

  updateGoogle2FA( event: any = null ) {
    let bValid = false;
    if ( event && event.key == 'Enter' && event.keyCode == 13 && this.objPreviewQRcode.code.length == 6 ) {
      bValid = true;
    }

    if ( event && !bValid ) { return; }

    this.http
      .post<any>(this.host + '/user/twofa/verifyGoogle2FA', this.objPreviewQRcode)
      .subscribe(
        (response) => {
          if (response.success) {
            this.closeModal();
            this.userService.reInitClient();
            this.isLoading = false;
            this.topAlertsService.popToast(
              'success',
              'Success',
              '2FA verified successfully'
            );

          }
        },
        (err) => { if (err.error) { this.showError( err.error ); } }
      );
  }


  verify2FaCode( event: any = null ) {

    let bValid = false;
    if ( event && event.key == 'Enter' && event.keyCode == 13 && this.modelVerify.pin_code.length == 6 ) {
      bValid = true;
    }

    if ( event && !bValid ) { return; }

    this.isLoading = true;

    switch (this.isPhoneVerification ? 'sms' : this.userService.getManager().settings.twofa_type) {
      case 'google_app': {
        this.authenticationService.verifyGoogleAuthCode( this.modelVerify.pin_code, this.modelVerify.u_token )
          .subscribe(
            result => {
              if ( result.success && this.nextCallBack ) {
                this.nextCallBack();
                this.isLoading = false;
              }
            },
            (err) => {
              if (err.error) {
                this.showError(err.error);
              }
            }
          );
        break;
      }
      case 'sms': {
        this.authenticationService.verifyPhoneCodeForAuthUser( this.userService.getToken(this.tokenRole), this.modelVerify.pin_code )
          .subscribe(
            result => {
              if ( result && this.nextCallBack ) {
                this.isPhoneVerification = false;
                this.nextCallBack();
                this.isLoading = false;
              }
            },
            (err) => {
              if (err.error) {
                this.showError(err.error);
              }
            }
          );
        break;
      }
      default: {
        this.authenticationService.verifyEmailCode( this.userService.getManager().email, this.modelVerify.pin_code )
          .subscribe(
            result => {
              if ( result && this.nextCallBack ) {
                this.nextCallBack();
                this.isLoading = false;
              }
            },
            (err) => {
              if (err.error) {
                this.showError(err.error);
              }
            }
          );
      }
    }
  }

  public submitPhoneNumber() {
    this.isLoading = true;
    this.twoFaService
      .verify('/user/phone/change', {
        phone: this.objUserInfo.phone,
        u_token: this.userService.getToken(this.tokenRole),
      })
      .subscribe(
        (response) => {
          if (response) {
            this.userService.reInitClient();
            this.topAlertsService.popToast(
              'success',
              'Success',
              'Phone number updated successfully'
            );
            this.closeModal();
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.errorResponce(errResponse.error);
            this.isLoading = false;
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        }
      );
  }

  errorResponce(errorObj) {
    this.errorObject = errorObj;
    if (this.errorObject.message === 'Invalid previous password.') {
      this.isOldPassError = true;
      this.rewritePass.controls['currentPassword'].setErrors({
        incorrect: true,
      });
    }
  }
  public onChangePassword() {
    this.isLoading = true;
    this.twoFaService
      .verify('/user/password/change', {
        old_password: this.rewritePass.value.currentPassword,
        password: this.rewritePass.value.newPassword,
        cpassword: this.rewritePass.value.newPasswordConfirm,
        u_token: this.userService.getToken(this.tokenRole),
        skeep_verify_pin_code: true
      })
      .subscribe(
        (response) => {
          if (response) {
            this.topAlertsService.popToast(
              'success',
              'Success',
              this.messages.get('UPDATE_PASSWORD_SUCCESSFULLY')
            );
            this.closeModal();
          }
        },
      );
  }

  clearModalData() {
    this.rewritePass.reset();
  }

  onKeydownCurrentPass() {
    this.isOldPassError = false;
  }

  showCurrentPassword() {
    this.isShowCurrentPass = !this.isShowCurrentPass;
  }
  hideCurrentPassword() {
    this.isShowCurrentPass = false;
  }

  showNewPassword() {
    this.isShowNewPass = !this.isShowNewPass;
  }


  getErrorItem(validity) {
    this.cssAnimateFlag = false;
    if (validity.hasError('required')) {
      this.message = 'Password is required';
      this.cssAnimateFlag = true;
      return true;
    }
    if (validity.hasError('hasNumber')) {
      this.message = 'Must contain at least 1 number!';
      this.cssAnimateFlag = true;
      return true;
    }
    if (validity.hasError('hasCapitalCase')) {
      this.message = 'Must contain at least 1 in Capital Case!';
      this.cssAnimateFlag = true;
      return true;
    }
    if (validity.hasError('hasSmallCase')) {
      this.message = 'Must contain at least 1 Letter in Small Case!';
      this.cssAnimateFlag = true;
      return true;
    }
    if (validity.hasError('hasSpecialCharacters')) {
      this.message = 'Must contain at least 1 Special Character!';
      this.cssAnimateFlag = true;
      return true;
    }
    if (validity.hasError('minlength')) {
      this.message = 'Must be at least 8 characters!!';
      this.cssAnimateFlag = true;
      return true;
    }
    return true;
  }
  showNewConfirmPassword() {
    this.isShowNewConfPass = !this.isShowNewConfPass;
  }
  hideNewConfirmPassword() {
    this.isShowNewConfPass = false;
  }

  ngOnDestroy(): void {
    JwtInterceptor.logeined = null;
  }
}
