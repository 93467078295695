import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../auth.service';
import {UserService} from '../../_services/user.service';
import {JqueryService} from '../../_services/jquery.service';
import {environment} from '../../../environments/environment';
import {MessagesService} from '../../_services/messages.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {Utility} from '../../_helpers/utility';
import {TimerService} from '../../_services/timer.service';
import {TwoFaService} from '../../_services/two-fa.service';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
  verificationTitle: string = 'We sending you a verification code';
  model: any = { pinCodeParts: ['', '', '', '', '', ''] };
  loading = false;
  showLoginForm = true;
  show2FAForm = false;
  showResetPassword = false;
  isSuccessLogin = false;
  isQuickLogin = false;
  isSuccessResetPas = false;
  isUseLandingPhone = false;
  successMessageLogin = '';
  successMessageResetPas = '';
  isErrorResetPas = false;
  isVisibleLoginForm = true;
  isVerifyByGoogleApp = false;
  errorMessageResetPas = '';
  public wp_auth_token: string;
  google_link: string = environment.google_sign_in_url;
  steps: string = '1/1';
  activeIndex: number = 0;
  codeIncompleteError: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    public messages: MessagesService,
    public jqueryService: JqueryService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    public topAlertsService: TopAlertsService,
    public utility: Utility,
    public timerService: TimerService,
    public twoFaService: TwoFaService
  ) {}

  ngOnInit() {
    AuthenticationService.initFPB();
    this.activatedRouter.queryParams.subscribe(params => {
      if (params?.error !== undefined && params?.error) {
        this.topAlertsService.popToast('error', 'Error', params?.error);
        this.router.navigate([], { queryParams: {} });
      }
    });
    if (environment.subDomen === 'paynote') {
      this.authenticationService.clearLogin();
    }
    this.isQuickLogin = environment.subDomen !== 'demo' && environment.subDomen !== 'paynote' && environment.subDomen !== 'dev';
    this.timerService.initTimerCD('down');
    this.wp_auth_token = this.activatedRouter.snapshot.paramMap.get('wp_auth_token');
    if (this.wp_auth_token) {
      this.isVisibleLoginForm = false;
      this.loginWordpress();
    } else {
      this.checkSandBoxDemoMode();
    }
    this.jqueryService.removeSelector('.modal-backdrop.fade');
    this.jqueryService.removeSelector('.modal.fade.show.d-block');
  }

  checkSandBoxDemoMode() {
    if (environment.isSandBoxMode && localStorage.getItem('currentUserV4')) {
      this.userService.initUser();
    } else {
      this.authenticationService.logout();
    }
  }

  backToLogin() {
    this.isUseLandingPhone = false;
    this.showFormLogin();
  }

  availableSignUpByEmal() {
    return environment.availableSignUpByEmal;
  }

  login(event: any = null) {
    let bValid = false;
    if (event && event.key == 'Enter' && event.keyCode == 13 && this.model.password && this.model.username) {
      bValid = true;
    }
    if (!event && this.model.password && this.model.username) {
      bValid = true;
    }
    if (!bValid) { return; }
    if (!this.isQuickLogin) {
      this.timerService.setTime(60, 's');
    }
    this.loading = true;
    this.authenticationService.login(this.model.username, this.model.password, this.model.remember_me)
      .subscribe(
        result => {
          if (result.success && result.token) {
            if ((result.verify_by_google_app || result.message) && result.success) {
              if (result.message && result.message.indexOf('call to your phone') > -1) {
                this.isUseLandingPhone = true;
              }
              this.showForm2FA();
              this.isVerifyByGoogleApp = !!result.verify_by_google_app;
              this.verificationTitle = result.message;
              this.loading = false;
            } else if (result.success && result.token) {
              AuthenticationService.handleSuccessLogin(result);
              this.initLogin();
            }
          } else if (!result.action.success) {
            this.verificationTitle = result.message;
            this.timerService.setTime(60, 's');
            this.steps = result.action.steps;
            this.showForm2FA();
            this.model.pinCodeParts = ['', '', '', '', '', ''];
            this.activeIndex = 0;
            this.loading = false;
          } else {
            this.topAlertsService.popToast('error', '', this.messages.get('USERNAME_OR_PASSWORD_IS_INCORRECT'));
            this.loading = false;
          }
        },
        errResponse => {
          this.loading = false;
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  validateNumber(event: KeyboardEvent) {
    if (event.key && !/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  }

  onInput(event: any, index: number) {
    const input = event.target;
    const value = input.value;
    if (/^\d$/.test(value)) {
      if (index === this.activeIndex) {
        this.model.pinCodeParts[index] = value;
        if (this.activeIndex < 5) {
          this.codeIncompleteError = false;
          this.activeIndex = index + 1;
          const nextInput = document.getElementsByName('code' + this.activeIndex)[0];
          setTimeout(() => nextInput.focus());
        } else {
          this.activeIndex = 6;
          this.codeIncompleteError = false;
          this.login2FA();
        }
      }
    } else {
      input.value = '';
      this.model.pinCodeParts[index] = '';
    }
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    if (event.key === 'Backspace') {
      const input = event.target as HTMLInputElement;
      if (!input.value && index > 0) {
        this.activeIndex = index - 1;
        this.model.pinCodeParts[this.activeIndex] = '';
        const prevInput = document.getElementsByName('code' + this.activeIndex)[0];
        setTimeout(() => prevInput.focus());
        event.preventDefault();
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData.getData('text').trim();
    if (/^\d{6}$/.test(pastedText)) {
      this.model.pinCodeParts = pastedText.split('');
      this.activeIndex = 6;
      this.codeIncompleteError = false;
      event.preventDefault();
      this.login2FA();
    } else {
      event.preventDefault();
      this.codeIncompleteError = true;
    }
  }

  pasteCode($event) {
    navigator.clipboard.readText().then(text => {
      const pastedText = text.trim();
      if (/^\d{6}$/.test(pastedText)) {
        this.model.pinCodeParts = pastedText.split('');
        this.activeIndex = 6;
        this.codeIncompleteError = false;
        this.login2FA();
      } else {
        this.codeIncompleteError = true;
      }
    });
    $event.preventDefault();
  }

  onBlur() {
    if (this.activeIndex < 6) {
      this.codeIncompleteError = true;
    } else {
      this.codeIncompleteError = false;
    }
  }

  onFocus() {
    if (this.activeIndex < 6) {
      this.codeIncompleteError = false;
    }
  }

  autoLogin(strMessage: string = '') {
    const regExp = /\(([^)]+)\)/;
    const pinCode = regExp.exec(strMessage);
    if (pinCode && pinCode[1] && pinCode[1].length === 6) {
      this.model.pinCodeParts = pinCode[1].split('');
      this.activeIndex = 6;
      this.login2FA();
    }
  }

  loginWordpress() {
    this.loading = true;
    this.authenticationService.wpLogin(this.wp_auth_token)
      .subscribe(
        result => {
          if (result) {
            this.initLogin();
          }
        },
        errResponse => {
          this.loading = false;
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  login2FA() {
    this.model.pinCode = this.model.pinCodeParts.join('');
    this.loading = true;
    this.authenticationService.login2FA(this.model.username, this.model.password, this.model.pinCode, this.model.remember_me)
      .subscribe(
        (result: any) => {
          if (result.success && result.token !== undefined) {
            this.initLogin();
          } else if (!result.action.success) {
            this.verificationTitle = result.message;
            this.timerService.setTime(60, 's');
            this.steps = result.action.steps;
            this.loading = false;
            this.model.pinCodeParts = ['', '', '', '', '', ''];
            this.activeIndex = 0;

          } else {
            this.topAlertsService.popToast('error', '', this.messages.get('USERNAME_OR_PASSWORD_IS_INCORRECT'));
            this.loading = false;
          }
        },
        errResponse => {
          this.loading = false;
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            if (errResponse.error.message == 'Invalid verification code.') {
              this.model.pinCodeParts = ['', '', '', '', '', ''];
              this.activeIndex = 0;
              setTimeout(() =>  document.getElementsByName('code' + this.activeIndex)[0].focus());
            }
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  initLogin() {
    this.userService.clearIntervals();
    this.userService.lastActive = Date.now();
    this.userService.initUser(true);
    setTimeout(() => { this.userService.initReloginModule(); }, 2000);
    this.userService.retrieveUserLimits();
    this.timerService.timerFinish('STOP');
  }

  showFormLogin() {
    this.showLoginForm = true;
    this.show2FAForm = false;
    this.showResetPassword = false;
  }

  showFormResetPassword() {
    this.showLoginForm = false;
    this.show2FAForm = false;
    this.showResetPassword = true;
  }

  showForm2FA() {
    this.showLoginForm = false;
    this.show2FAForm = true;
    this.showResetPassword = false;
  }

  resetPassword() {
    this.loading = true;
    this.authenticationService.recoveryPassword(this.model.emailPassword)
      .subscribe(
        result => {
          if (result) {
            this.isSuccessResetPas = true;
            this.successMessageResetPas = result.message;
            this.loading = false;
          }
        },
        errResponse => {
          this.loading = false;
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
            this.isErrorResetPas = true;
          }
        }
      );
  }
}
