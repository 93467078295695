<div class="element-wrapper account-settings" #beneficialScrollMe id="beneficialScrollMe">
    <h6 class="element-header">Account Settings</h6>
    <div class="element-box">
        <div class="row">
            <div class="col-lg-6 text-left">
                <h4 class="form-header">{{userService.getManager().name}}</h4>
            </div>
        </div>
        <div class="os-tabs-w">
            <div class="tab-content">
                <!--        TAB Details        -->
                <div class="tab-pane active" id="tab_details">
                    <div class="row">
                        <div class="col-lg-6">
                            <h6 style="display: inline-block;margin-right: 14px;">
                                <b>Contact Info</b>
                            </h6>
                            <div>
                                <div class="table-responsive">
                                    <table class="table table-lightborder">
                                        <tbody>
                                        <tr>
                                            <td class="text-uppercase">First name</td>
                                            <td class="" [innerHTML]="userService.getManagerFirstName()"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-uppercase">Last name</td>
                                            <td class="" [innerHTML]="userService.getManagerLastName()"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-uppercase">Phone</td>
                                            <td class="">
                                                {{userService.getManager().phone}}&nbsp;&nbsp;
                                                <a (click)="openChangePhoneNumber()" *ngIf="!userService.getManager()?.settings?.required_phone_verification" href="javascript:void(0)"
                                                   title="Update Phone Number" style="font-size: 22px;color: #b0b5bb;display: inline-block;">
                                                    <i class="picons-thin-icon-thin-0001_compose_write_pencil_new"></i>
                                                </a>
                                                <a (click)="eventVerify2Fa('sms')" *ngIf="userService.getManager()?.settings?.required_phone_verification" href="javascript:void(0)"
                                                   title="Verify Phone Number" style="font-size: 22px;color: #b0b5bb;display: inline-block;text-decoration: none;">
                                                    <i class="picons-thin-icon-thin-0061_error_warning_alert_attention text-warning" style="text-decoration: none;"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-uppercase">Email</td>
                                            <td class="">{{userService.getManager().email}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="col-lg-6">
                            <div>
                                <h6><b>Security</b></h6>
                                <hr>
                                <div>
                                    <dl>
                                        <dt class="text-uppercase account-billing-plan">Notification Preference:</dt>
                                        <dd class="text-capitalize">
                                            <mat-radio-group aria-label="Select an option" [(ngModel)]="objAccountComp.twofa_type" (change)="change2FAAuthType()">
                                                <mat-radio-button value="sms">Text</mat-radio-button>&nbsp;&nbsp;&nbsp;
                                                <mat-radio-button value="email">Email</mat-radio-button>&nbsp;&nbsp;&nbsp;
                                            </mat-radio-group>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt class="text-uppercase account-billing-plan" style="text-align: left;">Password:</dt>
                                        <dd>
                                            <button
                                                type="button"
                                                class="btn btn-outline-secondary btn-rounded btn-rounded-margin"
                                                style="margin-top: 5px; margin-bottom: 5px"
                                                (click)="openAddChangePassword()">
                                                Change Password
                                            </button>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #showQRcodePreview let-c="close" let-d="dismiss">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">Set up authenticator app</h4>
            <button type="button" class="close" aria-label="Close" (click)="cancelChange2FaType()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-center" style="margin-bottom: 30px">
            <ul style="text-align: left;padding-left: 10px;">
                <li>In the Google Authenticator app tab the <b>+</b></li>
                <li>Choose <b>Scan a QR code</b></li>
            </ul>
            <img
                [src]="objPreviewQRcode.str_url_inline"
                style="max-width: 300px"
            />

            <ul style="text-align: left;padding-left: 10px;">
                <li>Enter the 6-digit code you see in the app</li>
            </ul>
            <form (keydown)="updateGoogle2FA($event)" class="form-validation">
                <input class="form-control" placeholder="Verification Code" type="text" [(ngModel)]="objPreviewQRcode.code" id="pin_code_add_app_auth" name="code" required appDigits>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary slick-prev slick-arrow" (click)="cancelChange2FaType(); c('Close click')">Cancel</button>
            <button [disabled]="objPreviewQRcode.code.length < 6" class="slick-next slick-arrow" type="submit" (click)="updateGoogle2FA()">Verify</button>
        </div>
    </div>
</ng-template>

<ng-template #verifiChange2faType let-c="close" let-d="dismiss">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ verificationTitle }}

            </h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <span *ngIf="['email', 'sms'].includes(userService.getManager().settings.twofa_type)">
                        A 6-digit verification code has been sent to your {{ isPhoneVerification ? 'phone ' : 'current 2FA method'}} -
                        <span *ngIf="userService.getManager().settings.twofa_type == 'email' && !isPhoneVerification">{{userService.getManager().email.substring(0, 3)}}*******{{ userService.getManager().email.slice(-7) }}</span>
                        <span *ngIf="userService.getManager().settings.twofa_type == 'sms' || isPhoneVerification">*******{{ userService.getManager().phone.slice(-4) }}</span>
                    </span>
                    <span *ngIf="userService.isTwoFaGoogleAuth()">
                        To ensure your account’s security, please enter your verification code from your Auth App:
                    </span>
                </div>
                <div class="col-sm-12">
                    <div *ngIf="['email', 'sms'].includes(userService.getManager().settings.twofa_type)" style="margin-bottom: 10px;">
                        <b>Note:</b> If you don’t receive the code, please wait a few minutes and try again, or reach out to support for assistance.
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <form (keydown)="verify2FaCode($event)">
                            <!-- <label for=""><i class="icon-feather-hash"></i> Code</label> -->
                            <input class="form-control" id="model_verify_pin_code" placeholder="Verification Code" type="text" [(ngModel)]="modelVerify.pin_code" name="pin_code" required maxlength="6" #pin_code="ngModel" appDigits>
                            <div class="help-block form-text with-errors form-control-feedback" *ngIf="!pin_code.valid && (pin_code.dirty || pin_code.touched)">
                                <span *ngIf="pin_code.errors.required">
                                    Verification Code is required
                                </span>
                                <span *ngIf="pin_code.errors.minlength">
                                    Verification Code must be at least 6 digits long.
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="slick-prev slick-arrow" (click)="cancelChange2FaType(); c('Close click')">Cancel</button>
            <button [disabled]="isLoading || timer.isTimerRun"
                    class="slick-center" (click)="eventVerify2Fa(this.nextCallBack)">
            <span>
              Resend Code
            </span>
                <span [innerHTML]="timer.getRemainigTime()"></span>
            </button>
            <button [disabled]="isLoading || modelVerify.pin_code.length < 6" class="slick-next slick-arrow" (click)="verify2FaCode()">Verify</button>
        </div>
    </div>
</ng-template>


<ng-template #addChangePassword let-c="close" let-d="dismiss" let-create="addFundSources">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">Change Password
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click');clearModalData()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="rewritePass" novalidate (ngSubmit)="onChangePassword()">
            <div class="modal-body">
                <div class="form-group form-element-height">
                    <label for="currentPassword">Current Password</label>
                    <div class="input-group">
                        <input class="form-control" type="{{ isShowCurrentPass ? 'text' : 'password' }}" name="currentPassword" id="currentPassword" placeholder="Current Password" formControlName="currentPassword" (keydown)="onKeydownCurrentPass()" />
                        <div class="input-group-prepend">
                            <div class="input-group-text" (mousedown)="showCurrentPassword()" (mouseup)="hideCurrentPassword()">
                                <i *ngIf="isShowCurrentPass" class="fa fa-eye" aria-hidden="true"></i>
                                <i *ngIf="!isShowCurrentPass" class="fa fa-eye-slash" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-control-feedback error-color" *ngIf="rewritePass.controls['currentPassword'].invalid && rewritePass.controls['currentPassword'].touched">
                        <div class="help-block form-text with-errors form-control-feedback">
                            <div *ngIf="rewritePass.controls['currentPassword'].errors.required">Current password is required
                            </div>
                            <div *ngIf="isOldPassError">{{ errorObject.message }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6 form-element-height">
                            <label for="newPassword">New Password</label>
                            <div class="input-group">
                                <input class="form-control" type="{{ isShowNewPass ? 'text' : 'password' }}" name="newPassword" formControlName="newPassword" placeholder="New Password" />
                                <div class="input-group-prepend">
                                    <div class="input-group-text" (mousedown)="showNewPassword()" (mouseup)="showNewPassword()">
                                        <i *ngIf="isShowNewPass" class="fa fa-eye" aria-hidden="true"></i>
                                        <i *ngIf="!isShowNewPass" class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <ng9-password-strength-bar [passwordToCheck]="rewritePass.value.newPassword" [barLabel]="barLabel" [barColors]="myColors">
                            </ng9-password-strength-bar>
                            <div class="help-block form-text with-errors form-control-feedback" *ngIf="rewritePass.controls['newPassword'].invalid && rewritePass.controls['newPassword'].touched">
                                <div *ngIf="getErrorItem(rewritePass.controls['newPassword'])" [ngClass]="cssAnimateFlag ? 'bounceIn' : 'bounceOut'">{{message}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 form-element-height">
                            <label for="newPasswordConfirm">New Password (confirm)</label>
                            <div class="input-group">
                                <input class="form-control" [type]="isShowNewConfPass ? 'text' : 'password'" name="newPasswordConfirm" formControlName="newPasswordConfirm" placeholder="New Password (confirm)" />
                                <div class="input-group-prepend">
                                    <div class="input-group-text" (mousedown)="showNewConfirmPassword()" (mouseup)="hideNewConfirmPassword()">
                                        <i *ngIf="isShowNewConfPass" class="fa fa-eye" aria-hidden="true"></i>
                                        <i *ngIf="!isShowNewConfPass" class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="help-block form-text with-errors form-control-feedback" style="padding-top: 20px;" *ngIf="rewritePass.controls['newPasswordConfirm'].invalid && rewritePass.controls['newPasswordConfirm'].touched">
                                <div class="bounceIn" *ngIf="!rewritePass.controls['newPasswordConfirm'].errors.required && rewritePass.controls['newPasswordConfirm'].errors.matchOther">
                                    Password is not confirm</div>
                                <div class="bounceIn" *ngIf="rewritePass.controls['newPasswordConfirm'].errors.required">Password is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between pt-4 pb-4">
                <button class="btn btn-link btn-paynote mt-1" type="button" (click)="c('Close click');clearModalData();">
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    Cancel</button>
                <button class="btn btn-link btn-paynote mt-1" [disabled]="isLoading || rewritePass.invalid" [style.cursor]="rewritePass.invalid ? 'not-allowed' : 'pointer'">
                    Update
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #editPhoneNumber let-c="close" let-d="dismiss">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">Update Phone Number</h4>
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form name="form" #f="ngForm">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group" [ngClass]="{'has-error has-danger': !phone.valid && (phone.dirty || phone.touched)}">
                            <!-- <label for="">Phone Number</label> -->
                            <input class="form-control" placeholder="Phone Number" type="text" required name="updatePhone"
                                   [(ngModel)]="objUserInfo.phone" #phone="ngModel" id="update_phone_number"
                                   [textMask]="{ mask: utility.maskPhone }">
                            <div class="help-block form-text text-muted form-control-feedback" *ngIf="!phone.valid && (phone.dirty || phone.touched)">
                                <span *ngIf="phone.errors.required">Phone Number is required</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="slick-prev slick-arrow" type="button" (click)="c('Close click')">Cancel</button>
            <button [disabled]="isLoading || !f.form.valid" class="slick-next slick-arrow" type="submit" (click)="submitPhoneNumber()">Update</button>
        </div>
    </div>
</ng-template>
