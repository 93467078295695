<div class="onboarding-modal">
<!--  <div class="header">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>-->
  <mat-dialog-content>
    <div class="manual-verification">
      <div class="modal-body">
        <div class="manual-verification-title">
          <h4 class="modal-title" style="margin-top: 20px;" *ngIf="data.title">{{ data.title }}</h4>
        </div>
        <div class="modal-body">
          <p class="mf-text"><p [innerHTML]="data.text"></p>
        </div>
      </div>
      <div class="modal-footer" style="position: relative;">
        <button class="slick-prev slick-arrow" [ngClass]="{'hide_arrow': data.hide_arrows}" type="button" (click)="cancel()" *ngIf="data.button_cancel_text">
          {{data.button_cancel_text}}
        </button>
        <button class="slick-next slick-arrow" [ngClass]="{'hide_arrow': data.hide_arrows}" type="button" (click)="confirm()">{{data.button_confirm_text}}</button>
      </div>
    </div>
  </mat-dialog-content>
<!--  <mat-dialog-actions>
    <div style="position: relative;">
      <button class="slick-pev slick-arrow" (click)="cancel()">{{data.button_cancel_text}}</button>
      <button class="slick-pev slick-arrow" cdkfocusinitial (click)="confirm()">
              {{data.button_confirm_text}}
      </button>
    </div>
  </mat-dialog-actions>-->
</div>


<!--<div class="manual-verification">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="manual-verification-title">
      <h4 class="modal-title">{{ data.title }}</h4>
    </div>
    <div class="modal-body">
      <p class="mf-text"><p [innerHTML]="data.text"></p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="slick-prev slick-arrow" type="button" (click)="cancel()">
      {{data.button_cancel_text}}
    </button>
    <button class="slick-next slick-arrow" type="button" (click)="confirm()">{{data.button_confirm_text}}</button>
  </div>
</div>-->
