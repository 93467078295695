import 'hammerjs';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
} from '@angular/core';
import {
  LocationStrategy,
  CurrencyPipe,
  HashLocationStrategy,
  CommonModule,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgProgressModule } from '@ngx-progressbar/core/';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { Uploader } from './_helpers/uploader/uploader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClipboardModule } from 'ngx-clipboard';
import { AppComponent } from './app.component';
import { AuthGuard } from './_guards/auth.guard';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { AuthenticationService } from './auth.service';
import { TextMaskModule } from 'angular2-text-mask';
import { Ng9PasswordStrengthBarModule } from 'ng9-password-strength-bar';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ToasterModule } from 'angular2-toaster';
import { DirectivesModule } from './_shared_modules/directives/directives.module';
import { NgxTimerModule } from 'ngx-timer';
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { StatusPageComponent } from './views/status-page/status-page.component';
import { AppRoutingModule } from './app.routing';
import { NavigationComponent } from './views/navigation/navigation.component';
import { LeftSidebarComponent } from './views/left-sidebar/left-sidebar.component';
import { NumbersDirective } from './_directives/numbers.directive';
import { DeleteConfirmDialogComponent } from './views/delete-confirm-dialog/delete-confirm-dialog.component';
import { SignUpComponent } from './views/sign-up/sign-up.component';
import { OnboardRegisterInDwollaComponent } from './views/onboard-register-in-dwolla/onboard-register-in-dwolla.component';
import { RecoveryPasswordComponent } from './views/recovery-password/recovery-password.component';
import { MerchantTransactionsComponent } from './views/merchant-transactions/merchant-transactions.component';
import { DigitalCheckComponent } from './views/digital-check/digital-check.component';
import { ConfirmDialogComponent } from './views/confirm-dialog/confirm-dialog.component';
import { Utility } from './_helpers/utility';
import { EqualValidator } from './_directives/validateEqual';
import { PaginationModule } from 'ngx-pagination-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmEmailComponent } from './views/confirm-email/confirm-email.component';
import { InvoiceFromCustomersComponent } from './views/invoice-from-customers/invoice-from-customers.component';
import { ThankYouPageComponent } from './views/thank-you-page/thank-you-page.component';
import { OnboardRegisterInSandboxComponent } from './views/onboard-register-in-sandbox/onboard-register-in-sandbox.component';
import { PasswordSetupComponent } from './views/password-setup/password-setup.component';
import { UserActionsComponent } from './views/user-actions/user-actions.component';
import { CreatePasswordComponent } from './views/create-password/create-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { CreatePasswordSecureComponent } from './views/create-password-secure/create-password-secure.component';
import { PurchasePlanModule } from './_shared_modules/purchase-plan/purchase-plan.module';
import { environment } from '../environments/environment';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import {CaptureAuthComponent} from './views/capture-auth/capture-auth.component';
import { ExpressCheckoutComponent } from './views/express-checkout/express-checkout.component';
import { ClientInvoicesModule } from './views/client-invoices/client-invoices.module';
import { AcceptChecksModule } from './views/accept-checks/accept-checks.module';
import { MobileVerificationLightComponent } from './views/mobile-verification-light/mobile-verification-light.component';
import { AppPasswordDirective } from './_directives/app-password.directive';
import { ExternalBankAccauntComponent } from './views/external-bank-accaunt/external-bank-accaunt.component';
import { SignupManagerComponent } from './views/signup-manager/signup-manager.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { loadRemoteEntry } from './utils/module-federation';
import {PasswordSetComponent} from './views/password-set/password-set.component';
import { StarRatingModule } from 'angular-star-rating';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { FingerprintjsProAngularModule } from '@fingerprintjs/fingerprintjs-pro-angular';
import {SocialiteAuthComponent} from './views/socialite-auth/socialite-auth.component';
import {UiFormsModule} from './_shared_modules/ui/ui-forms.module';
import {ManagerAccountComponent} from './views/manager-account/manager-account.component';
import { TwoFaPopupComponent } from './views/two-fa-popup/two-fa-popup/two-fa-popup.component';
import {TwoFaInterceptor} from './_helpers/twofa.interceptor';
import {TwoFaService} from './_services/two-fa.service';


const APP_CONTAINERS = [DefaultLayoutComponent];

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
};


export function initializeApp(): () => void {
  return async () => {
    await loadRemoteEntry(environment.react_remote_module);
  };
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTooltipModule,
    PaginationModule,
    TooltipModule.forRoot(),
    TextMaskModule,
    MatSlideToggleModule,
    Ng9PasswordStrengthBarModule,
    NgHttpLoaderModule,
    PurchasePlanModule,
    ToasterModule.forRoot(),
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    MatAutocompleteModule,
    ClipboardModule,
    CurrencyMaskModule,
    ClientInvoicesModule,
    AcceptChecksModule,
    NgxTimerModule,
    DirectivesModule.forRoot(),
    ColorPickerModule,
    NgSelectModule,
    StarRatingModule.forRoot(),
    NgxPlaidLinkModule,
    FingerprintjsProAngularModule.forRoot({
      loadOptions: {
        apiKey: 'pkZZ9tWwyHezPNotbSUY'
      }
    }),
    UiFormsModule,
  ],
  declarations: [
    SocialiteAuthComponent,
    AppComponent,
    EqualValidator,
    NumbersDirective,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    StatusPageComponent,
    RegisterComponent,
    NavigationComponent,
    LeftSidebarComponent,
    DeleteConfirmDialogComponent,
    SignUpComponent,
    OnboardRegisterInDwollaComponent,
    RecoveryPasswordComponent,
    MerchantTransactionsComponent,
    DigitalCheckComponent,
    ConfirmDialogComponent,
    ConfirmEmailComponent,
    ThankYouPageComponent,
    OnboardRegisterInSandboxComponent,
    PasswordSetupComponent,
    PasswordSetComponent,
    UserActionsComponent,
    CreatePasswordComponent,
    ResetPasswordComponent,
    CreatePasswordSecureComponent,
    ExpressCheckoutComponent,
    MobileVerificationLightComponent,
    AppPasswordDirective,
    ExternalBankAccauntComponent,
    SignupManagerComponent,
    InvoiceFromCustomersComponent,
    CaptureAuthComponent,
    ManagerAccountComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
    },
    AuthGuard,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TwoFaInterceptor,
      multi: true
    },
    TwoFaService,
    AuthenticationService,
    Uploader,
    Utility,
    CurrencyPipe,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
  exports: [EqualValidator, NumbersDirective, DirectivesModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
