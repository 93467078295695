import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {map, tap} from 'rxjs/operators';
import {FingerprintjsProAngularService} from '@fingerprintjs/fingerprintjs-pro-angular';
import {JqueryService} from './_services/jquery.service';
import {Subject} from 'rxjs';

declare var Fingerprint2: any;

@Injectable()
export class AuthenticationService {
  public static fpbService: FingerprintjsProAngularService;
  public token: string;
  public host = environment.host;
  public hostNoSandbox: string = environment.hostWithOutSandbox;
  public headers: any;
  public browComponent: any = {};

  set objUser(user: any) {
    this._user = user;
    this.$authUser.next(user);
  }

  get objUser() {
    return this._user;
  }

  $authUser: Subject<any> = new Subject();
  public _user: any;
  private checkFPBInterval;
  $FPBVerification: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fingerprintService: FingerprintjsProAngularService,
    private jquery: JqueryService,
  ) {
    AuthenticationService.fpbService = fingerprintService;
    this.getInfoComponents();
    this.host = environment.host;
    const currentUserV4 = JSON.parse(localStorage.getItem('currentUserV4'));
    this.token = currentUserV4 && currentUserV4.token;
    this.objUser = currentUserV4;

  }

  static handleSuccessLogin(response: any) {
    if (response.success && response.token) {
      localStorage.setItem('currentUserV4', JSON.stringify(response));
      return response;
    }
    return response;
  }

  static async initFPB() {
    const options = {};
    Fingerprint2.get(options, function (components: any) {
      let values = components.map(function (component: any) {
        return component.value;
      });
      const hash = Fingerprint2.x64hash128(values.join(''), 31);
      localStorage.setItem('FPB-old', 'ov:' + hash);
      values = null;
    });
    const data = await AuthenticationService.fpbService.getVisitorData();
    localStorage.setItem('FPB', data.visitorId);
    localStorage.setItem('FPB_request_id', data.requestId);
    localStorage.setItem('FPB_extended', JSON.stringify(data));
  }

  async initFPBCheck() {
    const action = async () => {
      const user = JSON.parse(localStorage.getItem('currentUserV4'));
      if (user !== undefined && user?.token) {
        await this.jquery.getClientIp().then();
        await AuthenticationService.initFPB();
        this.checkFPB();
      }
    };
    action().then();
    this.checkFPBInterval = setInterval(action, 60000 * 15);
  }

  checkFPB() {
    return this.http.post(this.host + '/check-fpb', {
      fpb: this.getFingerPrintBrowser(),
      fpb_request_id: this.getFingerPrintBrowserRequestId(),
      browser: this.browComponent,
    },
      {
        headers: {
          'X-hidden': '',
        }
      }
    ).subscribe(
      () => null,
      error => {
        if (error.error.message === 'FINGERPRINT_AUTHORIZE_REQUIRED') {
          this.$FPBVerification.next(true);
        }
      },
    );
  }

  login(username: string, password: string, remember_me: boolean = false) {
    const ipClient = localStorage.getItem('ipClient') || null;
    return this.http.post(this.host + '/user/login', JSON.stringify(
      {
        email: username, password: password,
        fpb: this.getFingerPrintBrowser(),
        fpb_request_id: this.getFingerPrintBrowserRequestId(),
        browser: this.browComponent,
        ipAddress: ipClient,
        remember_me
      }
    ))
      .pipe(
        map(AuthenticationService.handleSuccessLogin),
        tap((res) => {
          if (res.success && res.token && res?.action?.success !== undefined && res.action.success) {
            window.clearInterval(this.checkFPBInterval);
            this.initFPBCheck();
          }
        }),
      );
  }

  sendVerifyPhoneCode(phone: string, email: string) {
    return this.http.post(this.host + '/user/phone/register', JSON.stringify({phone: phone, email: email}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  sendVerifyCodeToEmail(email: string) {
    return this.http.post(this.host + '/user/email/verification/pincode', JSON.stringify({email: email}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  sendVerifyTextVoiceCode(phone: string, phone_pin: string, email: string) {
    return this.http.post(this.host + '/user/phone/verification/pincode', JSON.stringify({phone: phone, phone_pin: phone_pin, email: email}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  sendVerifyCodeToTextMessage(phone: string, email: string) {
    return this.http.post(this.host + '/user/phone/sms/verification/pincode', JSON.stringify({phone: phone, email: email}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  sendVerifyCodeToPhoneCall(phone: string, email: string) {
    return this.http.post(this.host + '/user/phone/voice/verification/pincode', JSON.stringify({phone: phone, email: email}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  sendVerifyPhoneCodeForAuthUser(u_token: string) {
    return this.http.post(this.host + '/user/mfa/verification', JSON.stringify({u_token: u_token}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  sendVerifyPhoneCodeForNewUser(phone: any) {
    return this.http.post(this.host + '/user/phone/verify/initiate', JSON.stringify({phone: phone}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }


  sendAchProcessingProfile(states: any) {
    return this.http.post(this.host + '/user/merchant/save/ach-processing-profile', JSON.stringify(states))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  sendVerifyVoiceCode(u_token: any) {
    return this.http.post(this.host + '/user/merchant/voice/verification', JSON.stringify({u_token}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  verifyGoogleAuthCode(code: string, u_token: string) {
    return this.http.post(this.host + '/user/twofa/verifyGoogle2FA', JSON.stringify({code, u_token}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  verifyVoiceCode(u_token: string, phone_pin: string) {
    return this.http.post(this.host + '/user/merchant/voice/verification/pincode', JSON.stringify({u_token, phone_pin}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  verifyPhoneCode(phone: string, email: string, code: string) {
    return this.http.post(this.host + '/user/phone/verify', JSON.stringify({phone: phone, email: email, phone_pin: code}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  verifyEmailCode(email: string, code: string) {
    return this.http.post(this.host + '/user/email/verify', JSON.stringify({email: email, pin: code}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  verifyPhoneCodeForAuthUser(u_token: string, code: string, type: string = null) {
    return this.http.post(this.host + '/user/mfa/verification/pincode', JSON.stringify({u_token: u_token, phone_pin: code, type}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

    login2FA(username: string, password: string, pinCode: string, remember_me: boolean = false) {
    return this.http.post(this.host + '/user/login/mfa', JSON.stringify(
      {
        email: username, password: password, phone_pin: pinCode,
        fpb: this.getFingerPrintBrowser(),
        fpb_request_id: this.getFingerPrintBrowserRequestId(),
        remember_me,
      }
    ))
      .pipe(map(response => {
        const objResp = <any> response;
        if (objResp.success && objResp.token) {
          localStorage.setItem('currentUserV4', JSON.stringify(objResp));
          window.clearInterval(this.checkFPBInterval);
          this.initFPBCheck();
        }
        return response;
      }));
  }

  wpLogin(wp_auth_token: string) {
    return this.http.get(this.host + '/user/auth/' + wp_auth_token
    ).pipe(map(response => {
      const objResp = <any>response;
      if (objResp.success) {
        localStorage.setItem('currentUserV4', JSON.stringify(objResp));
        window.clearInterval(this.checkFPBInterval);
        this.initFPBCheck();
        return true;
      }
      return false;
    }));
  }


  retrieveUser(u_token: string) {
    return this.http.get(this.host + '/user/retrieve',
      {
        params:
          {
            u_token: u_token,
            fpb: this.getFingerPrintBrowser(),
            fpb_request_id: this.getFingerPrintBrowserRequestId(),
          }
      }
    );
  }

  retrieveUserLimits(u_token: string) {
    return this.http.get(this.host + '/user/merchant/remaining/limits',
      {
        params:
          {
            u_token: u_token,
            fpb: this.getFingerPrintBrowser(),
            fpb_request_id: this.getFingerPrintBrowserRequestId(),
          }
      }
    );
  }

  getFingerPrintBrowser() {
    const fpb = localStorage.getItem('FPB');
    if (fpb !== undefined && fpb !== null && fpb !== '') {
      return fpb;
    }
    const old = localStorage.getItem('FPB-old');
    if (old !== undefined) {
      return old;
    }

    return '';
  }

  getFingerPrintBrowserRequestId() {
    if (localStorage.getItem('FPB_request_id')) {
      return localStorage.getItem('FPB_request_id');
    }

    return '';
  }

  getInfoComponents() {
    const options: any = {};
    const vm = this;
    Fingerprint2.get(options, function (components: any) {
      components.map(function (component: any) {
        switch (component.key) {
          case 'userAgent':
          case 'language':
          case 'timezoneOffset':
          case 'timezone':
          case 'platform':
            vm.browComponent[component.key] = component.value;
            break;
        }
      });
    });
  }

  retrieveMerchantRemainings(u_token: string) {
    return this.http.get(this.host + '/subscription/customer/remainings', {params: {u_token: u_token}});
  }

  retrieveDemoUser() {
    return this.http.get(this.host + '/user/login/demo');
  }

  signUp(userSignUp: any) {
    userSignUp.fpb = this.getFingerPrintBrowser();
    userSignUp.fpb_request_id = this.getFingerPrintBrowserRequestId(),
      userSignUp.browser = this.browComponent;
    userSignUp.ipAddress = localStorage.getItem('ipClient') || null;
    return this.http.post(this.host + '/user/merchant/register', JSON.stringify(userSignUp));
  }

  signUpManager(userSignUp: any) {
    userSignUp.fpb = this.getFingerPrintBrowser();
    userSignUp.fpb_request_id = this.getFingerPrintBrowserRequestId(),
      userSignUp.browser = this.browComponent;
    userSignUp.ipAddress = localStorage.getItem('ipClient') || null;
    return this.http.post(this.host + '/user/manager/register', JSON.stringify(userSignUp));
  }

  sendBugReport(bugReport: object) {
    return this.http.post(this.host + '/user/bugreport/send', JSON.stringify(bugReport));
  }

  setupPassword(userPasswor: object) {
    return this.http.post(this.host + '/user/customer/client/setup-password', JSON.stringify(userPasswor));
  }

  createPasswordAndPhone(userPasswor: object) {
    return this.http.post(this.host + '/user/password/create', JSON.stringify(userPasswor));
  }

  setupPasswordAndPhone(userPasswor: object) {
    return this.http.post(this.host + '/user/password/phone/register', JSON.stringify(userPasswor));
  }

  signUpByPaymentLink(userSignUp: any) {
    userSignUp.fpb = this.getFingerPrintBrowser();
    userSignUp.fpb_request_id = this.getFingerPrintBrowserRequestId(),
      userSignUp.browser = this.browComponent;
    return this.http.post(this.host + '/user/customer/client/register/payment-link', JSON.stringify(userSignUp));
  }

  signUpByInvoiceLink(userSignUp: any) {
    userSignUp.fpb = this.getFingerPrintBrowser();
    userSignUp.fpb_request_id = this.getFingerPrintBrowserRequestId(),
      userSignUp.browser = this.browComponent;
    return this.http.post(this.host + '/check/invoice/payment/create/manual-verification', JSON.stringify(userSignUp));
  }

  initiateCreatePassword(userEmail: string) {
    return this.http.post(this.host + '/user/password/create/initiate', JSON.stringify({email: userEmail}));
  }

  recoveryPassword(userEmail: string) {
    return this.http.post(this.host + '/user/password/forget', JSON.stringify({email: userEmail}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  resetPassword(passToken: string, newPass: string, confirmPass: string) {
    return this.http.post(this.host + '/user/password/recovery', JSON.stringify({token: passToken, password: newPass, cpassword: confirmPass}))
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }


  clearLogin() {
    localStorage.removeItem('currentUserV4');
    localStorage.removeItem('adminUser');
  }

  clearAdminLogin() {
    localStorage.removeItem('adminUser');
  }


  logout(action: string = ''): void {
    // clear token remove user from local storage to log user out
    if (action != '') {
      console.log(action);
    }
    this.token = null;
    localStorage.removeItem('currentUserV4');
    localStorage.removeItem('adminUser');
    this.router.navigate(['/login']);
  }

  reLogin() {
    this.getUser()
      .subscribe(
        result => {
          if (result.success) {
            localStorage.setItem('currentUserV4', JSON.stringify(result));
            this.$authUser.next(result);
          }
        },
        err => {
          if (err?.error?.message === 'FINGERPRINT_AUTHORIZE_REQUIRED') {
            this.$FPBVerification.next(true);
          } else {
            this.router.navigate(['/login']);
          }
        }
      );
  }

  refreshToken() {
    return this.http.get(this.host + '/auth/refresh')
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp;
        }
        return false;
      }));
  }

  getUser() {
    return this.http.get(this.host + '/user/auth', {params: {fpb: this.getFingerPrintBrowser(), fpb_request_id: this.getFingerPrintBrowserRequestId()}})
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          this.$authUser.next(response);
          return objResp;
        }
        return objResp;
      }));
  }

  getStatusCertifiedBeneficialOwner(u_token: string = '') {
    return this.http.get<any>(this.hostNoSandbox + '/dwl/customer/beneficial-owner/status', {params: {u_token: u_token}})
      .pipe(map(response => {
        const objResp = <any>response;
        if (objResp.success) {
          return objResp.data.status;
        }
        return false;
      }));
  }
}
