<!-- Google Tag Manager (noscript) -->
<!--<noscript>-->
<!--  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W7RK4F" height="0" width="0" style="display:none;visibility:hidden"></iframe>-->
<!--</noscript>-->
<!-- End Google Tag Manager (noscript) -->
<ng-progress color="purple" [thick]="true"></ng-progress>
<div class="all-wrapper menu-side with-pattern">
    <div class="auth-box-w" *ngIf="isVisibleLoginForm">
        <div class="logo-w">
            <a routerLink="/"><img alt="" src="assets/img/Paynote_-_New_Blue.svg"></a>
        </div>
        <div [hidden]="!showLoginForm">
            <h4 class="auth-header">Login</h4>
            <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" class="form-validation" (keydown)="login($event)">
                <div class="form-group" [ngClass]="{ 'has-error has-danger': !username.valid && (username.dirty || username.touched) }">
                    <label>Email</label>
                    <input name="username" class="form-control" placeholder="Enter your email" type="text" [(ngModel)]="model.username" #username="ngModel" required>
                    <div class="pre-icon os-icon os-icon-user-male-circle top-33"></div>
                    <div *ngIf="!username.valid && (username.dirty || username.touched)" class="help-block form-text with-errors form-control-feedback">
                        Email is required
                    </div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error has-danger': !password.valid && (password.dirty || password.touched) }">
                    <label>Password</label>
                    <input name="password" class="form-control" placeholder="Enter your password" type="password" [(ngModel)]="model.password" #password="ngModel" required>
                    <div class="pre-icon os-icon os-icon-fingerprint top-33"></div>
                    <div *ngIf="!password.valid && (password.dirty || password.touched)" class="help-block form-text with-errors form-control-feedback">
                        Password is required
                    </div>
                </div>
                <div class="text-left">
                    <label style="font-size: 14px; color: black">
                        <input name="remember_me" [(ngModel)]="model.remember_me" #remember_me="ngModel" type="checkbox" class="form-control" />
                        Remember this device.
                    </label>
                </div>
                <div class="buttons-w">
                    <button type="submit" [disabled]="loading || !f.form.valid" class="btn btn-primary btn-rounded btn-rounded-margin">Login</button>
                    <button type="button" class="btn btn-default pull-right btn-rounded btn-rounded-margin" routerLink="/sign-up">Sign Up</button>
                    <br><br>
                    <a routerLink="/reset-password" class="btn btn-link" style="padding: 0px;">Reset Password</a>
                    <a routerLink="/create-password" class="btn btn-link pull-right" style="padding: 0px;">Create Password</a>
                </div>
                <br>
                <div [hidden]="!isSuccessLogin" class="alert alert-success" role="alert" [innerHTML]="successMessageLogin"></div>
            </form>
        </div>
        <div [hidden]="!show2FAForm">
            <h4 class="auth-header">Multi-factor Authentication</h4>
            <form name="form2FA" (ngSubmit)="f2FA.form.valid && login2FA()" #f2FA="ngForm" autocomplete="off">
                <p><span class="text-primary">{{ twoFaService.validateSteps(steps) }}</span> {{ verificationTitle }}</p>
                <div class="form-group">
                    <label>Verification Code</label>
                    <div class="code-input" (focusout)="onBlur()">
                        <input type="text" maxlength="1" class="digit-box"
                               [(ngModel)]="model.pinCodeParts[0]" name="code0"
                               [disabled]="(activeIndex !== 0) || (activeIndex === 6)"
                               (input)="onInput($event,0)" (keydown)="onKeyDown($event,0)"
                               (paste)="onPaste($event)" (focus)="onFocus()"
                               (keypress)="validateNumber($event)" required>
                        <input type="text" maxlength="1" class="digit-box"
                               [(ngModel)]="model.pinCodeParts[1]" name="code1"
                               [disabled]="(activeIndex !== 1) || (activeIndex === 6)"
                               (input)="onInput($event,1)" (keydown)="onKeyDown($event,1)"
                               (paste)="onPaste($event)" (focus)="onFocus()"
                               (keypress)="validateNumber($event)" required>
                        <input type="text" maxlength="1" class="digit-box"
                               [(ngModel)]="model.pinCodeParts[2]" name="code2"
                               [disabled]="(activeIndex !== 2) || (activeIndex === 6)"
                               (input)="onInput($event,2)" (keydown)="onKeyDown($event,2)"
                               (paste)="onPaste($event)" (focus)="onFocus()"
                               (keypress)="validateNumber($event)" required>
                        <input type="text" maxlength="1" class="digit-box"
                               [(ngModel)]="model.pinCodeParts[3]" name="code3"
                               [disabled]="(activeIndex !== 3) || (activeIndex === 6)"
                               (input)="onInput($event,3)" (keydown)="onKeyDown($event,3)"
                               (paste)="onPaste($event)" (focus)="onFocus()"
                               (keypress)="validateNumber($event)" required>
                        <input type="text" maxlength="1" class="digit-box"
                               [(ngModel)]="model.pinCodeParts[4]" name="code4"
                               [disabled]="(activeIndex !== 4) || (activeIndex === 6)"
                               (input)="onInput($event,4)" (keydown)="onKeyDown($event,4)"
                               (paste)="onPaste($event)" (focus)="onFocus()"
                               (keypress)="validateNumber($event)" required>
                        <input type="text" maxlength="1" class="digit-box"
                               [(ngModel)]="model.pinCodeParts[5]" name="code5"
                               [disabled]="(activeIndex !== 5) || (activeIndex === 6)"
                               (input)="onInput($event,5)" (keydown)="onKeyDown($event,5)"
                               (paste)="onPaste($event)" (focus)="onFocus()"
                               (keypress)="validateNumber($event)" required>
                    </div>
                    <div *ngIf="codeIncompleteError" class="code-error">Verification Code is required</div>
<!--                    <a href="#" class="link" (click)="pasteCode($event)">Paste Code</a>-->
                </div>
                <div class="buttons-w">
                    <button [disabled]="loading || !f2FA.form.valid" class="btn btn-primary btn-rounded btn-rounded-margin">Submit</button>
                    <button [disabled]="loading || timerService.isTimerRun" *ngIf="!isVerifyByGoogleApp" class="btn btn-default pull-right btn-rounded btn-rounded-margin" (click)="login()">
                        <span *ngIf="!isUseLandingPhone">Resend Code &nbsp;</span>
                        <span *ngIf="isUseLandingPhone">Resend <span *ngIf="!availableSignUpByEmal()">Voice</span> Code &nbsp;</span>
                        <span [innerHTML]="timerService.getRemainigTime()"></span>
                    </button>
                    <br><br>
                    <a (click)="backToLogin()" class="strong" href="javascript:void(0)">Cancel</a>
                </div>
                <span style="display: none;" *ngIf="!isVerifyByGoogleApp">
          <countdown-timer *ngIf="timerService.isTimerRun" [countDownTimerConfig]="timerService.configCD"></countdown-timer>
        </span>
                <br>
            </form>
        </div>
        <div [hidden]="!showResetPassword">
            <h4 class="auth-header">Reset Password</h4>
            <form name="form2FA" (ngSubmit)="fResetPas.form.valid && resetPassword()" #fResetPas="ngForm" autocomplete="off">
                <div *ngIf="isSuccessResetPas" class="alert alert-info" role="alert">{{ successMessageResetPas }}</div>
                <div class="form-group" [ngClass]="{ 'has-error has-danger': !emailPassword.valid && (emailPassword.dirty || emailPassword.touched) }">
                    <label>Email</label>
                    <input name="pinCode" class="form-control" placeholder="Please enter email" type="email" [(ngModel)]="model.emailPassword" #emailPassword="ngModel" required>
                    <div class="pre-icon os-icon os-icon-email-2-at"></div>
                    <div *ngIf="!emailPassword.valid && (emailPassword.dirty || emailPassword.touched)" class="help-block form-text with-errors form-control-feedback">
                        Email is required
                    </div>
                </div>
                <div class="buttons-w">
                    <button [disabled]="loading || !fResetPas.form.valid" class="btn btn-primary">Submit</button>
                    <br><br>
                    <a (click)="backToLogin()" class="strong" href="javascript:void(0)">Cancel</a>
                </div>
                <br>
                <div [hidden]="!isErrorResetPas" class="alert alert-danger" role="alert" [innerHTML]="errorMessageResetPas"></div>
            </form>
        </div>
    </div>
</div>
