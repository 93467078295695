import {Injectable} from '@angular/core';
import {AuthenticationService} from '../auth.service';
import {JqueryService} from './jquery.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {MessagesService} from './messages.service';
import {PermissionsService} from './permissions.service';
import {TopAlertsService} from './top-alerts.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Subject, timer} from 'rxjs';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import {JwtHelperService} from '@auth0/angular-jwt';

declare var Fingerprint2: any;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static _isGhostLogin = false;
  $authUser: Subject<any> = new Subject();

  set objUser(user: any) {
    this._user = user;
    this.$authUser.next(user);
  }

  get objUser() {
    return this._user;
  }

  private _user: any;
  public ROLE_ADMIN = 'Admin';
  public ROLE_SUPER_ADMIN = 'SuperAdmin';
  public ROLE_MERCHANT = 'Merchant';
  public ROLE_CLIENT = 'Client';
  public ROLE_CUSTOMER = 'Customer';
  public ROLE_DEMO = 'Demo';
  public ROLE_PARTNER = 'Partner';
  public ROLE_REP = 'Rep';
  public ROLE_MANAGER = 'Manager';

  private objAdminUser: any;
  public isCertifiedBeneficialOwner = true;
  public isRequestCertifyBeneficialOwner = false;
  public isRequestVerifydBeneficialOwner = false;
  public isRequestAddBeneficialOwner = false;
  public isCanManageBeneficialOwner = false;
  public isFerifyBeneficialOwner = false;

  get isGhostLogin() {
    return UserService._isGhostLogin;
  }

  set isGhostLogin(isGhostLogin: boolean) {
    UserService._isGhostLogin = isGhostLogin;
  }

  public isDemoLogin = false;
  public checkToProcessedPlan = true;
  public countMinSendRemaining = 3;
  public countMinReceivedRemaining = 3;
  public menu: any = [];
  public warningMessages: any = [];
  public lastActive = 0;
  public isExpressCheckout = false;
  public objPlanLimits: any = {};
  private hasReinitClient = true;
  public canShowVerifyMicroDeposit = false;
  public intervalTimerCountDown: any;
  public intervalTimerSession: any;
  public objReloginPopup: any = {
    showloginAlert: false,
    log_sec: 120,
  };
  private objLabels: any = {
    havePersonalSignupLock: false,
  };
  public defaultSettings = {
    verified_personal: {
      receive_limit: {
        Daily: 2000,
        Invoices: 1000,
        Monthly: 15000,
      },
      send_limit: {
        Checks: 1000,
        Daily: 2000,
        Monthly: 15000,
      },
    },
    verified_business: {
      receive_limit: {
        Daily: 10000,
        Invoices: 2500,
        Monthly: 25000,
      },
      send_limit: {
        Checks: 2500,
        Daily: 10000,
        Monthly: 25000,
      },
    },
    unverified: {
      receive_limit: {
        Weekly: 5000,
      },
      send_limit: {},
    },
    preplan: {
      send_over: 0,
      send_num: 0,
      remaining_amount: 0,
      receive_verify: 0,
    },
    rtp: 0,
    rtp_transaction_fee: 3,
    rtp_transaction_rate: 1,
    signal_fee: 1,
    signal_rules: [
      {type: 'bank', risk: 1, value: 2, status: 'approve'},
      {type: 'bank', risk: 2, value: 4, status: 'approve'},
      {type: 'bank', risk: 3, value: 5, status: 'hold'},
      {type: 'bank', risk: 4, value: 6, status: 'declined'},
      {type: 'bank', risk: 5, value: 8, status: 'declined'},
      {type: 'customer', risk: 1, value: 1, status: 'approve'},
      {type: 'customer', risk: 2, value: 2, status: 'approve'},
      {type: 'customer', risk: 3, value: 3, status: 'hold'},
      {type: 'customer', risk: 4, value: 4, status: 'declined'},
      {type: 'customer', risk: 5, value: 5, status: 'declined'},
    ],
    signal_rules_type: 'Default',
    billing_cycle: 'day',
  };


  public DOC_FAILURE_REASONS = {
    'BusinessDocNotSupported': '<b>Unsupported Business Document:</b><span> The document provided is not accepted as valid business documentation.</span>',
    'BusinessNameMismatch': '<b>Business Name Mismatch:</b><span> The business name on the account does not match the name on the supporting documents.</span>',
    'BusinessTypeMismatch': '<b>Entity Type Mismatch:</b><span> The selected entity type does not align with the information in the provided documents.</span>',
    'ScanDobMismatch': '<b>Date of Birth Mismatch:</b><span> The date of birth provided does not match the one listed in the supporting documents.</span>',
    'ScanFailedOther': '<b>Invalid Identification Document:</b><span> The ID provided is invalid or not recognized as acceptable.</span>',
    'ScanIdExpired': '<b>Expired Identification Document:</b><span> The ID provided has expired and is no longer valid.</span>',
    'ScanIdTypeNotSupported': '<b>Unsupported ID Type:</b><span> The type of ID submitted is not supported.</span>',
    'ScanIdUnrecognized': '<b>Unrecognized Identification Document:</b><span> The ID provided is not recognized.</span>',
    'ScanNameMismatch': '<b>Owner Name Mismatch:</b><span> The name of the account owner does not match the name on the provided ID.</span>',
    'ScanNotReadable': '<b>Unreadable Identification Document:</b><span> The ID provided cannot be read due to poor image quality or other issues.</span>',
    'ScanNotUploaded': '<b>Non-Identification Document Uploaded:</b><span> The uploaded file is not a valid ID.</span>',
  };

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private jqueryService: JqueryService,
    public messages: MessagesService,
    private sanitizer: DomSanitizer,
    public topAlertsService: TopAlertsService,
    public permissionsService: PermissionsService
  ) {
    this.authenticationService.$authUser.subscribe(user => {
      this.objUser = user;
    });
    if (
      localStorage.getItem('currentUserV4') ||
      localStorage.getItem('adminUser')
    ) {
      this.reInitClient(this.initUser());
      this.checkCertifiedBeneficialOwner();

      const timerReInitUser = timer(5000, 10000);
      timerReInitUser.subscribe(() => {
        this.initUser();
      });
      if (!this.isAdmin() && !this.isSuperAdmin()) {
        const timerReLoginClient = timer(15000, 60000);
        timerReLoginClient.subscribe(() => {
          this.reInitClient();
        });
        this.retrieveUserLimits();
      }
    }
    this.lastActive = Date.now();
    if (!this.isGhostLogin && this.isLogined()) {
      setTimeout(() => this.initReloginModule(), 5000);
    }
  }

  getAuthHeaders() {
    const currentUserV4 = <any>JSON.parse(localStorage.getItem('currentUserV4'));
    const headers: any = {};

    if (localStorage.getItem('ipClient')) {
      headers['Remote-IP'] = localStorage.getItem('ipClient');
      headers['X-Remote-IP'] = localStorage.getItem('ipClient');
    }

    if (currentUserV4 && currentUserV4.manager && currentUserV4.manager.u_token) {
      headers['X-Mng-Token'] = currentUserV4.manager.u_token;
    }

    const ghostLogin = JSON.parse(localStorage.getItem('adminUser'));
    if (UserService._isGhostLogin && ghostLogin?.user?.email) {
      headers['X-Ghost-Log'] = ghostLogin?.user?.email;
      if (currentUserV4?.user?.email !== undefined && currentUserV4?.user?.email) {
        headers['X-Ghost-Owner'] = currentUserV4?.user?.email;
      }
    }

    if (currentUserV4 && currentUserV4.token && !headers.hasOwnProperty('Authorization')) {
      headers['Authorization'] = 'Bearer ' + currentUserV4.token;
    }
    return headers;
  }

  clearUser() {
    this.objUser = {};
  }

  initUser(bRedirect: boolean = false) {
    if (localStorage.getItem('adminUser')) {
      this.objAdminUser = JSON.parse(localStorage.getItem('adminUser'));
      if (this.objAdminUser.success) {
        this.isGhostLogin = true;
      } else {
        this.isGhostLogin = false;
        localStorage.removeItem('adminUser');
      }
    } else {
      this.isGhostLogin = false;
    }
    if (
      localStorage.getItem('currentUserV4') &&
      localStorage.getItem('currentUserV4') != '{}' &&
      localStorage.getItem('currentUserV4') != 'null'
    ) {
      this.objUser = JSON.parse(localStorage.getItem('currentUserV4'));
      if (this.isLocked() && !this.isGhostLogin) {
        this.logout();
        return;
      }
      // this.setTokenInCookies();
      if (!this.isPartner() && !this.isRep()) {
        this.initMicroDeposit();
        this.initRequireBenefisiarOwners();
        this.initBussinesAccount();
        this.initVerifyBankAccount();
        this.initLabels();
      }
    }

    if (!this.objUser || !this.objUser.user) {
      return;
    }

    if (this.objUser.manager) {
      this.permissionsService.init(this.objUser.manager);
    }

    if (this.isMerchant()) {
      this.canShowVerifyMicroDeposit = true;
    }

    if (this.isSuperAdmin() || this.isAdmin()) {
      localStorage.removeItem('sandBoxMode');
    }

    if (!this.objUser.user && !this.isExpressCheckout) {
      // this.authenticationService.logout();
      return;
    }
    this.initMenu();

    if (bRedirect) {
      this.lastActive = Date.now();
      const redirectFromGhostMode = localStorage.getItem(
        'redirectFromGhostMode'
      );
      if (redirectFromGhostMode && !this.isGhostLogin) {
        localStorage.removeItem('redirectFromGhostMode');
        if (redirectFromGhostMode == '/') {
          this.redirectLoginedUser();
        } else {
          this.redirectJustSimple(redirectFromGhostMode);
        }
      } else {
        this.redirectLoginedUser();
      }
    }
  }

  initReloginModule() {
    if (this.intervalTimerCountDown || this.intervalTimerSession) {
      this.clearIntervals();
    }

    if (
      this.isGhostLogin ||
      !this.isLogined() ||
      environment.subDomen == 'demo' ||
      environment.subDomen == 'sandbox'
    ) {
      return;
    }
    const helperJwtservice = new JwtHelperService();
    const decodedToken = helperJwtservice.decodeToken(this.getAuthToken());
    const expirationDate = helperJwtservice.getTokenExpirationDate(
      this.getAuthToken()
    );

    this.intervalTimerSession = setInterval(() => {
      if (!this.isLogined()) {
        this.clearIntervals();
        return;
      }

      if (helperJwtservice.isTokenExpired(this.getAuthToken())) {
        this.clearUser();
        this.authenticationService.logout(
          'From initReloginModule: Token Expired'
        );
        this.clearIntervals();
      }

      const t = Date.now();
      const exp = decodedToken.exp * 1000;
      const expTime = (exp - t) / 1000;
      const d = (t - this.lastActive) / 1000;
      const sec = Math.round(d);

      if (
        (sec >= 600 || (expTime >= 0 && expTime <= 600)) &&
        this.isLogined()
      ) {
        if (this.isGhostLogin) {
          return this.clearIntervals();
        }

        this.refreshUser();
      }
    }, 420000);
  }

  clearIntervals() {
    this.jqueryService.closeModal('.alert-relogin-modal');
    this.objReloginPopup.log_sec = 120;
    this.objReloginPopup.showloginAlert = false;
    clearInterval(this.intervalTimerCountDown);
    clearInterval(this.intervalTimerSession);
  }

  refreshUser() {
    // this.objReloginPopup.log_sec = 120;
    // this.objReloginPopup.showloginAlert = false;
    // this.jqueryService.closeModal(".alert-relogin-modal");
    this.lastActive = Date.now();
    this.refreshClient();
    this.clearIntervals();
    setTimeout(() => this.initReloginModule(), 3000);
  }

  isGuestUrl() {
    const url = location.pathname;
    if (
      url.indexOf('login') ||
      url.indexOf('sign-up') ||
      url.indexOf('create-password') ||
      url.indexOf('reset-password') ||
      url.indexOf('check') ||
      url.indexOf('invoice') ||
      url.indexOf('confirm-email')
    ) {
      return true;
    }

    return false;
  }

  retrieveUserLimits() {
    // if ( !this.isMerchant() ) {
    //   return;
    // }
    if (this.isPartner() || this.isRep()) {
      return;
    }
    this.authenticationService
      .retrieveUserLimits(this.getToken())
      .subscribe((response: any) => {
        this.objPlanLimits = response;
        this.initLimits();
      });
  }

  isAvailablePartner() {
    return environment.availablePartner;
  }

  initLimits() {
    this.objPlanLimits.alarm = false;
    this.objPlanLimits.alarmMessage = '';
    if (
      this.objPlanLimits.limits.receive_daily >=
      this.objPlanLimits.limits.receive_limit_daily
    ) {
      this.objPlanLimits.alarm = true;
      this.objPlanLimits.alarmMessage +=
        'Receive Daily Limit limit reached, please <a href="mailto:paynote@seamlesschex.com">contact support</a><br>';
    }
    if (
      this.objPlanLimits.limits.receive_monthly >=
      this.objPlanLimits.limits.receive_limit_monthly
    ) {
      this.objPlanLimits.alarm = true;
      this.objPlanLimits.alarmMessage +=
        'Receive Monthly Limit limit reached, please <a href="mailto:paynote@seamlesschex.com">contact support</a><br>';
    }

    if (
      this.objPlanLimits.limits.send_daily >=
      this.objPlanLimits.limits.send_limit_daily
    ) {
      this.objPlanLimits.alarm = true;
      this.objPlanLimits.alarmMessage +=
        'Send Daily Limit limit reached, please <a href="mailto:paynote@seamlesschex.com">contact support</a><br>';
    }
    if (
      this.objPlanLimits.limits.send_monthly >=
      this.objPlanLimits.limits.send_limit_monthly
    ) {
      this.objPlanLimits.alarm = true;
      this.objPlanLimits.alarmMessage +=
        'Send Monthly Limit limit reached, please <a href="mailto:paynote@seamlesschex.com">contact support</a><br>';
    }
  }

  initLabels() {
    if (!this.objUser.user.labels) {
      return;
    }
    if (!this.objUser.user.labels.length) {
      return;
    }
    const vm = this;
    this.objUser.user.labels.forEach(function (label: string) {
      if (label === 'Personal Signup Lock') {
        vm.objLabels.havePersonalSignupLock = true;
      }
    });
  }

  initMicroDeposit() {
    if (this.getMicroDeposit()) {
      this.warningMessages = [];
      const microDeposits = this.getMicroDeposit();
      for (const key in microDeposits) {
        const microDeposit = microDeposits[key];
        if (
          microDeposit.status == 'pending' ||
          microDeposit.status == 'verified' ||
          microDeposit.status == 'verify'
        ) {
          continue;
        }
        const tempObjMessage = {
          action: 'verify_funding_source',
          message:
            'Verify the two micro-deposits credited to your ' +
            microDeposit.bank +
            ' account ending in ' +
            microDeposit.number +
            ' on ' +
            this.getDateFormat(microDeposit.date.date, 'MMM D') +
            ', or',
          data: microDeposit,
        };
        this.warningMessages.push(tempObjMessage);
      }
    } else {
      this.warningMessages = [];
    }
  }

  initBussinesAccount() {
    if (
      this.isIndividualAccount() ||
      this.isAdmin() ||
      this.isSuperAdmin() ||
      this.isPartner() ||
      this.isRep()
    ) {
      return;
    }

    const objDwlCustomer = this.getDwollaCustomer();

    if (!objDwlCustomer) {
      return;
    }

    if (objDwlCustomer.dwl_customer && objDwlCustomer.dwl_customer._links) {
      if (objDwlCustomer.dwl_customer._links['beneficial-owners']) {
        this.isCanManageBeneficialOwner = true;
      }
      if (objDwlCustomer.dwl_customer._links['verify-beneficial-owners']) {
        this.isFerifyBeneficialOwner = true;
      }
    }
  }

  initRequireBenefisiarOwners() {
    if (this.isIndividualAccount()) {
      return;
    }
    this.isRequestCertifyBeneficialOwner = false;
    this.isRequestVerifydBeneficialOwner = false;
    this.isRequestAddBeneficialOwner = false;

    if (!this.objUser.user.dwl_require) {
      return false;
    }

    if (this.objUser.user.dwl_require && this.objUser.user.dwl_require.length) {
      const vm = this;
      this.objUser.user.dwl_require.forEach(function (require: string) {
        switch (require) {
          case 'verify-beneficial-owners':
            vm.isRequestVerifydBeneficialOwner = true;
            break;
          case 'certify-beneficial-ownership':
            if (!localStorage.getItem('certify-beneficial-ownership')) {
              vm.isRequestCertifyBeneficialOwner = true;
            } else {
              const nowTime = Date.now();
              const certifyTime = <any>(
                localStorage.getItem('certify-beneficial-ownership')
              );
              const milisec = nowTime - certifyTime;
              if (Math.floor(milisec / 1000) > 600) {
                localStorage.removeItem('certify-beneficial-ownership');
                vm.isRequestCertifyBeneficialOwner = true;
              }
            }
            break;
          case 'beneficial-owners':
            vm.isRequestAddBeneficialOwner = true;
            break;
        }
      });
    }
  }

  initVerifyBankAccount() {
    if (!this.hasReinitClient) {
      return;
    }
    if (
      this.isAdmin() ||
      this.isSuperAdmin() ||
      this.isClient() ||
      this.isPartner() ||
      this.isRep()
    ) {
      return;
    }
    if (!this.isHaveBankAccount()) {
      return;
    }
    if (this.isHaveBankAccount() && this.isHaveVerifyBankAccount()) {
      return;
    }

    if (
      this.isHaveBankAccount() &&
      !this.isHaveVerifyBankAccount() &&
      this.hasReinitClient
    ) {
      this.hasReinitClient = false;
      this.reInitClient();
      setTimeout(() => (this.hasReinitClient = true), 10000);
    }
  }

  // deprecated - need to remove
  setFingerPrintBrowser() {
    const options = {};
    Fingerprint2.get(options, function (components: any) {
      let values = components.map(function (component: any) {
        return component.value;
      });
      const hash = Fingerprint2.x64hash128(values.join(''), 31);
      localStorage.setItem('FPB-old', hash);
      values = null;
    });
  }

  goToSandBoxMode() {
    localStorage.setItem('sandBoxMode', '1');
    window.location.reload();
  }

  goToLiveMode() {
    localStorage.setItem('sandBoxMode', '0');
    window.location.reload();
  }

  getDateFormatWithOutTZ(date: string, format: string = 'MMM D, YYYY') {
    return moment(date).format(format);
  }

  getDateFormat(date: string, format: string = 'MMM D, YYYY') {
    const dateUtc = moment.utc(date);
    return moment(dateUtc).local().format(format);
  }

  kilometersToMiles(kilometers: number = 0) {
    return kilometers / 1.6;
  }

  getDateFormatCustom(
    date: string = null,
    format: string = 'MMM D, YYYY',
    days: number = 0,
    month: number = 0
  ) {
    const time = days > 0 ? 'days' : 'months';
    const countTime = days > 0 ? days : month;
    if (date) {
      const dateUtc = moment.utc(date);
      return moment(dateUtc).add(countTime, time).local().format(format);
    } else {
      return moment().add(countTime, time).local().format(format);
    }
  }

  getDateFormatNew(date: string, format: string = 'MMM D, YYYY hh:mm') {
    const dateUtc = moment.utc(date);
    return moment(dateUtc).format(format);
  }

  getDateSimpleFormat(date: string, format: string = 'MMM D, YYYY hh:mm') {
    return moment(date).format(format);
  }

  getMaskedPhone() {
    return this.objUser.masked_phone;
  }

  getTimeZone() {
    return momentTZ.tz.guess();
  }

  getCountFreeChecks() {
    const settings = this.getSettings();
    if (settings && settings.free_checks + 0 > 0) {
      return Number(settings.free_checks);
    }

    return 0;
  }

  getCountFreeReceivs() {
    const settings = this.getSettings();
    if (settings && settings.free_receive + 0 > 0) {
      return Number(settings.free_receive);
    }

    return 0;
  }

  getDefaultSettings() {
    if (this.isDwollaUnVerified()) {
      return this.defaultSettings.unverified;
    }

    return this.isIndividualAccount()
      ? this.defaultSettings.verified_personal
      : this.defaultSettings.verified_business;
  }

  requiredVerifyPhone() {
    const settings = this.getSettings();

    return (settings && settings?.required_phone_verification) ?? true;
  }

  getUser() {
    if (this.objUser.user) {
      return this.objUser.user;
    }
    return null;
  }

  getManager() {
    if (this.objUser.manager) {
      return this.objUser.manager;
    }
    return null;
  }

  isManagerLogined() {
    if (this.objUser?.manager?.token !== undefined && this.objUser?.manager?.token) {
      return true;
    }
    return false;
  }

  getMicroDeposit() {
    return this.objUser.user.wait_microdeposits;
  }

  getManagerFirstName() {
    return this.objUser.manager.name.split(' ')[0];
  }

  getManagerLastName() {
    return this.objUser.manager.name.split(' ')[1];
  }

  getUserRole() {
    if (this.objUser && this.objUser.user) {
      return this.objUser.user.role;
    }
    return '';
  }

  getUserBranding() {
    if (this.objUser && this.objUser.user) {
      return this.objUser.branding;
    }
    return null;
  }

  getAppStatus() {
    if (this.objUser && this.objUser.user) {
      return this.objUser.user.app_status;
    }
    return '';
  }

  getManagerSignature() {
    if (this.objUser && this.objUser.user) {
      return this.objUser.user.signature;
    } else {
      return false;
    }
  }

  getToken(role: string = null) {
    if (!this.objUser || !this.objUser.user) {
      return false;
    }
    switch (role) {
      case 'manager': {
        return this.objUser.manager.u_token;
      }
    }
    return this.objUser.user.u_token;
  }

  getSignature() {
    if (this.objUser.signature != '' || this.objUser.signature != null) {
      return this.objUser.signature;
    } else {
      return false;
    }
  }

  updateSignature(signature: string = '') {
    this.objUser.signature = signature;
    localStorage.setItem('currentUserV4', JSON.stringify(this.objUser));
    this.initUser();
    this.isLogined();
  }

  getAuthToken() {
    return this.objUser.token;
  }

  getEmail(role: string = null) {
    switch (role) {
      case 'manager':
        return this.objUser.manager.email;
    }
    if (this.objUser.user) {
      return this.objUser.user.email;
    } else {
      return false;
    }
  }

  getBussinesName() {
    return this.objUser.user ? this.objUser.user.business_name : '';
  }

  getArrayNames() {
    return this.objUser.user.name.split(' ');
  }

  getFullName(bAsDba = false) {
    return bAsDba
      ? this.getDoingBusinessAs()
      : this.objUser.user.name.replace('&amp;', '&');
  }

  getFirstName() {
    return this.getArrayNames()[0];
  }

  getLastName() {
    const arrNames = this.getArrayNames();
    if (arrNames.length > 1) {
      return arrNames.slice(1, arrNames.length).join(' ');
    } else if (!this.isIndividualAccount()) {
      const objDwlCustomer = this.getDwollaCustomer();
      if (objDwlCustomer && objDwlCustomer.dwl_customer.last_name) {
        return objDwlCustomer.dwl_customer.last_name;
      } else {
        return this.getFirstName();
      }
    } else {
      return this.getFirstName();
    }

    return this.getFirstName();
  }

  getDoingBusinessAs(bOnlyDBA = false) {
    const objDwlCust = this.getDwollaCustomer();
    if (objDwlCust.dwl_customer) {
      if (objDwlCust.dwl_customer.doing_business_as) {
        objDwlCust.dwl_customer.doing_business_as =
          objDwlCust.dwl_customer.doing_business_as.replace('&amp;', '&');
      }
      if (objDwlCust.dwl_customer.doing_business_as) {
        objDwlCust.dwl_customer.doing_business_as =
          objDwlCust.dwl_customer.doing_business_as.replace('&amp;', '&');
      }
    }
    if (!objDwlCust || objDwlCust.type == 'personal') {
      return this.getFullName();
    }

    if (bOnlyDBA && !objDwlCust.dwl_customer.doing_business_as) {
      return null;
    }

    return objDwlCust.dwl_customer.doing_business_as
      ? objDwlCust.dwl_customer.doing_business_as
      : objDwlCust.dwl_customer.business_name;
  }

  getPhone() {
    return this.objUser.user.phone;
  }

  hasPhone() {
    return !!(this.objUser?.manager?.phone || this.objUser?.user?.phone);
  }

  getStatus() {
    return this.objUser.user.status;
  }

  getSettings() {
    if (!this.objUser || !this.objUser.user || !this.objUser.user.settings) {
      return null;
    }
    return this.objUser.user.settings;
  }

  getRoleName() {
    let strRole = '';
    switch (this.getUserRole()) {
      case 'SuperAdmin':
      case 'Admin':
        strRole = 'Administrator';
        break;
      case '':
    }

    return strRole;
  }

  getAccountType() {
    if (!this.isLogined()) {
      return;
    }
    if (this.objUser.user.type == 'personal') {
      return 'Individual';
    }
    return this.objUser.user.type;
  }

  getAccountTypeOrigin() {
    return this.objUser.user.type;
  }

  getSubscription() {
    return this.objUser.subscriptions && this.objUser.subscriptions.length
      ? this.objUser.subscriptions[0]
      : null;
  }

  isLegacyPlan() {
    return this.objUser.legacy_subscription ? true : false;
  }

  isHavePlan() {
    return this.getSubscription() ? true : false;
  }

  getDwollaStatus() {
    return this.objUser.user.dwl_status;
  }

  isUnverified() {
    return this.getDwollaStatus() == 'unverified';
  }

  getDwollaUserInfo() {
    return this.objUser.user_info;
  }

  getDwollaUserBeneficialInfo() {
    return this.objUser.beneficials_info['0'];
  }

  getDwollaStatusForClient() {
    let status = '';
    if (this.isMerchant()) {
      if (this.getDwollaStatus() === 'document') {
        status = this.isHaveDocumentInPending()
          ? 'Document Pending'
          : 'Document Requested';
      } else {
        status = this.getDwollaStatus();
      }
    }
    if (this.isClient()) {
      status = !this.isDwollaVerified() ? 'Receive only account' : 'Verified';
    }

    return status;
  }

  getDwollaCustomer() {
    if (this.objUser.dwl_customer) {
      return this.objUser.dwl_customer;
    }

    return null;
  }

  getDwollaVerificationDocumentsNeeded() {
    if (!this.getDwollaCustomer()) {
      return null;
    }

    return this.getDwollaCustomer().dwl_customer.verification_documents_needed
      ? this.getDwollaCustomer().dwl_customer.verification_documents_needed
      : null;
  }

  getMinSendAmount() {
    const settings = this.getSettings();
    const plan = this.getSubscription();
    if ((settings && settings.free_checks > 0) || !plan) {
      return 1;
    }

    if (plan && plan.remaining.send.over > 0) {
      return plan.remaining.send.over * 2;
    }

    return 6;
  }

  getAchProcessingProfile() {
    const settings = this.getSettings();
    return settings && settings.ach_processing_profile
      ? settings.ach_processing_profile
      : null;
  }

  getMinRequestAmount() {
    const settings = this.getSettings();
    if (
      settings &&
      settings.receive_limit &&
      settings.receive_limit.transaction_fee
    ) {
      const fee = Number(settings.receive_limit.transaction_fee) + 1;
      return fee * 2;
    }
    return 2;
  }

  getCountBankAccount() {
    return this.objUser.count_bank_accounts || 0;
  }

  getUserApiId() {
    return this.objUser.user.ext_app_id || null;
  }

  isEnable2FA() {
    const settings = this.getSettings();
    if (!settings) {
      return true;
    }

    return settings.twofa == undefined || settings.twofa == 1;
  }

  isItemLoginRequired() {
    return this.objUser.item_login_required ? true : false;
  }

  getItemLoginRequired() {
    return this.objUser.item_login_required;
  }

  canShowPopupReSyncBA() {
    const settings = this.getSettings();

    return !settings ? true : (settings.disallow_re_sync_bank_account && settings.disallow_re_sync_bank_account == 1 ? false : true);
  }

  get2FAType(role: string = null) {
    const settings = this.getSettings();

    switch (role) {
      case 'manager':
        return this.getManager()?.settings?.twofa_type;
    }
    if (!settings) {
      return 'sms';
    }
    if (!settings?.twofa_type) {
      return 'sms';
    }

    return settings?.twofa_type;
  }

  isTwoFaSms() {
    return this.get2FAType() == 'sms';
  }

  isTwoFaEmail() {
    return this.get2FAType() == 'email';
  }

  isTwoFaGoogleAuth() {
    return this.get2FAType() == 'google_app';
  }

  isTwofaAuthAppInstalled(role: string = null) {
    const settings = this.getSettings();

    if (!settings && !role) {
      return false;
    }

    switch (role) {
      case 'manager':
        return this.getManager()?.settings.twofa_auth_app_installed === 1;
      default:
        return settings?.twofa_auth_app_installed == 1;
    }
  }

  isBankStatementVerified() {
    const settings = this.getSettings();

    if (!settings) {
      return false;
    }

    return settings.bank_statement_verified == 1 ? true : false;
  }

  isBankStatementUploaded() {
    const settings = this.getSettings();

    if (!settings) {
      return false;
    }

    return settings.bank_statement_uploaded == 1 ? true : false;
  }

  onboardingIsComplete() {
    const settings = this.getSettings();

    if (!settings) {
      return false;
    }

    return settings.onboarding_is_complete == 1 && !this.isHaveSubscription()
      ? true
      : false;
  }

  hasOnboardingIsCompleteParam() {
    const settings = this.getSettings();

    return (settings && settings.onboarding_is_complete == undefined) ||
    !settings
      ? false
      : true;
  }

  updateOnboardingComplete() {
    if (!this.objUser || !this.objUser.user || !this.objUser.user.settings) {
      return null;
    }
    this.objUser.user.settings.onboarding_is_complete = 0;
  }

  onboardingIsCompleteOldMetchants() {
    return this.isDwollaVerified() && !this.isHaveSubscription();
  }

  isHaveSubscription() {
    return this.objUser.subscription_active;
  }

  isApiSandBoxMode() {
    return (
      localStorage.getItem('sandBoxMode') &&
      localStorage.getItem('sandBoxMode') === '1'
    );
  }

  isSubDomenSandBox() {
    return environment.subDomen === 'sandbox';
  }

  isSubDomenDevOrDemo() {
    return (
      environment.subDomen === 'dev' ||
      environment.subDomen === 'demo' ||
      environment.subDomen === 'local'
    );
  }

  isHaveApiApp() {
    return this.objUser.user.ext_app_id ? true : false;
  }

  isHaveReachedLimits() {
    return this.objPlanLimits.alarm;
  }

  isLoginedAsNumber() {
    return this.isLogined() ? 1 : -1;
  }

  isLogined() {
    if (!this.objUser || !this.objUser.user) {
      return false;
    }
    return this.objUser.user ? true : false;
  }

  isLocked() {
    return this.objUser.user && this.objUser.user.status == 'Locked'
      ? true
      : false;
  }

  isAdmin() {
    return this.getUserRole() == this.ROLE_ADMIN;
  }

  isSuperAdmin() {
    return this.getUserRole() == this.ROLE_SUPER_ADMIN;
  }

  isManager() {
    return this.getUserRole() == this.ROLE_MANAGER;
  }

  isMerchant() {
    return (
      this.getUserRole() == this.ROLE_MERCHANT ||
      this.getUserRole() == this.ROLE_DEMO
    );
  }

  isPartner() {
    /* return false;*/
    if (!this.objUser || !this.objUser.user) {
      return false;
    }
    return (
      this.getUserRole() == this.ROLE_PARTNER || this.objUser.user.is_partner
    );
  }

  isRep() {
    /* return false;*/
    if (!this.objUser ) {
      return false;
    }
    return this.getUserRole() == this.ROLE_REP;
  }

  isDashboardRep() {
    if (!this.isRep()) {
      return false;
    }

    return this.objUser.user.permissions?.rep_dashboard;
  }

  isResidualsRep() {
    if (!this.isRep()) {
      return false;
    }

    return this.objUser.user.permissions?.residuals;
  }

  isAdminAccessRep() {
    if (!this.isRep()) {
      return false;
    }

    return this.objUser.user.permissions?.admin_access;
  }

  isMarketplace() {
    return (
      this.getUserRole() == this.ROLE_MERCHANT &&
      this.objUser.user.is_marketplace
    );
  }

  isClient() {
    return this.getUserRole() == this.ROLE_CLIENT;
  }

  isCustomer() {
    return (
      this.getUserRole() == this.ROLE_CUSTOMER ||
      this.getUserRole() == this.ROLE_CLIENT
    );
  }

  isDemoUser() {
    return this.getUserRole() == this.ROLE_DEMO;
  }

  isDwollaUnVerified() {
    return this.getDwollaStatus() == 'unverified' ? true : false;
  }

  isDwollaVerified() {
    return this.getDwollaStatus() == 'verified' ? true : false;
  }

  isDwollaRetry() {
    return this.getDwollaStatus() == 'retry' ? true : false;
  }

  isDwollaSuspended() {
    return this.getDwollaStatus() == 'suspended' ? true : false;
  }

  isDwollaDocument() {
    return this.getDwollaStatus() == 'document' ? true : false;
  }

  isDwollaNeedControllerId() {
    return this.getDwollaVerificationDocumentsNeeded() == 'verify-with-document'
      ? true
      : false;
  }

  isDwollaNeedControllerIdAndBusinessDoc() {
    return this.getDwollaVerificationDocumentsNeeded() ==
    'verify-controller-and-business-with-document'
      ? true
      : false;
  }

  isDwollaNeedBusinessDoc() {
    return this.getDwollaVerificationDocumentsNeeded() ==
    'verify-business-with-document'
      ? true
      : false;
  }

  isDwollaNeedUploadBusDoc() {
    return this.isDwollaNeedControllerId() ||
    this.isDwollaNeedBusinessDoc() ||
    this.isDwollaNeedControllerIdAndBusinessDoc()
      ? true
      : false;
  }

  isNeedUploadDbaDoc() {
    const settings = this.getSettings();
    if (settings == null) {
      return false;
    }
    return settings.dba_doc_required == 1;
  }

  isPasswordSet() {
    const settings = this.getSettings();
    if (settings == null) {
      return true;
    }

    if (
      settings.password_setup == undefined ||
      settings.password_setup == null
    ) {
      return true;
    }
    return settings.password_setup == 1;
  }

  isController() {
    if (!this.objUser || !this.objUser.dwl_customer) {
      return false;
    }
    return this.objUser.dwl_customer.dwl_customer.controller ? true : false;
  }

  isIndividualAccount() {
    return this.getAccountType() == 'Individual' ? true : false;
  }

  isPersonalAccount() {
    return this.objUser.user.type === 'personal';
  }

  isEmailConfirmation() {
    const settings = this.getSettings();
    if (settings && !settings.email_confirmation) {
      return false;
    }
    return true;
  }

  isFilledInfoForDwolla() {
    const settings = this.getSettings();
    if (settings && !settings.filled_info) {
      return false;
    }
    return true;
  }

  isOnDemandEnabled() {
    const settings = this.getSettings();
    return !!(settings && settings.on_demand_auth);
  }

  isUploadBenefisiarDocument() {
    const settings = this.getSettings();
    if (settings && !settings.beneficial_document_status) {
      return false;
    }
    return !(
      settings &&
      (settings.beneficial_document_status == 'uploaded' ||
        settings.beneficial_document_status == 'approved')
    );
  }

  isclientNotHaveBankAccount() {
    return this.isClient() && !this.isHaveBankAccount();
  }

  isHaveVerifyBankAccount() {
    if (!this.isHaveBankAccount()) {
      return false;
    }

    const objBankAccount = this.objUser.accounts[0];
    if (objBankAccount.status == 'unverified') {
      return false;
    }
    return true;
  }

  isHaveDocumentInPending() {
    const settings = this.getSettings();
    if (
      settings &&
      settings.document_status &&
      settings.document_status == 'pending'
    ) {
      return true;
    }
    return false;
  }

  isCanShowDownloadStatementTab() {
    const settings = this.getSettings();
    return settings && settings.display_tab_statement_download ? true : false;
  }

  isHaveBankAccount() {
    return this.objUser.accounts && this.objUser.accounts.length ? true : false;
  }

  isNotCertifiedBeneficialOwner() {
    if (this.isIndividualAccount()) {
      return true;
    }

    if (!this.isController()) {
      return true;
    }

    return this.isCertifiedBeneficialOwner;
  }

  isSendEnabled() {
    if (
      this.isAdmin() ||
      this.isSuperAdmin() ||
      this.isPartner() ||
      this.isRep()
    ) {
      return true;
    }
    const settings = this.getSettings();
    if (settings && settings.send_limit && settings.send_limit.Enabled == 1) {
      return true;
    }

    return false;
  }

  isRequestEnabled() {
    if (
      this.isAdmin() ||
      this.isSuperAdmin() ||
      this.isPartner() ||
      this.isRep()
    ) {
      return true;
    }

    const settings = this.getSettings();
    if (
      settings &&
      settings.receive_limit &&
      settings.receive_limit.Enabled == 1
    ) {
      return true;
    }

    return false;
  }

  isPaymentLinkEnabled() {
    if (
      this.isAdmin() ||
      this.isSuperAdmin() ||
      this.isPartner() ||
      this.isRep()
    ) {
      return true;
    }

    const settings = this.getSettings();
    if (settings && settings.billing_link) {
      return true;
    }

    return false;
  }

  isAdditionalVerificationRequired() {
    if (this.isIndividualAccount()) {
      return false;
    }

    const settigs = this.getSettings();
    if (
      settigs.additional_voice_verification &&
      settigs.additional_voice_verification == 1
    ) {
      return false;
    }

    const emailInfo = this.getEmailInfo();
    if (!emailInfo) {
      return false;
    }

    return emailInfo.is_free ? true : false;
  }

  isPlanPaymentFailed() {
    if (!this.isMerchant()) {
      return false;
    }
    const settings = this.getSettings();
    if (settings && settings.plan_payment_failed == 1) {
      return true;
    }

    return false;
  }

  isPersonalSignupApprove() {
    if (
      this.isAdmin() ||
      this.isSuperAdmin() ||
      this.isPartner() ||
      this.isRep()
    ) {
      return true;
    }

    const settings = this.getSettings();
    if (settings && settings.personal_signup_approve == 1) {
      return true;
    }

    return false;
  }

  isHavePersonalSignupLock() {
    return this.objLabels.havePersonalSignupLock;
  }

  canVoidCheck() {
    return (
      this.isSuperAdmin() ||
      this.isAdmin() ||
      this.isMerchant() ||
      this.isClient()
    );
  }

  canTransferToBank() {
    if (this.objUser.user.disable_transfer_to_bank === 1) {
      return false;
    }

    return true;
  }

  canSendFundsFromBalance() {
    const settings = this.getSettings();

    if (settings && settings.send_funds_from_balance == 1) {
      return true;
    }

    return false;
  }

  isLockedToPaynoteBalance() {
    const settings = this.getSettings();

    if (settings && settings.lock_to_paynote_balance == 1) {
      return true;
    }

    return false;
  }

  isSignalEnabled() {
    const settings = this.getSettings();

    if (settings && settings.signal == 1) {
      return true;
    }

    return false;
  }

  canBuyPlan() {
    const settings = this.getSettings();
    if (
      (settings && settings.interview_required == 1) ||
      this.isHavePersonalSignupLock()
    ) {
      return false;
    }

    return true;
  }

  logout() {
    this.objUser = null;
    this.authenticationService.logout();
    this.clearUser();
    this.clearIntervals();
    // this.objReloginPopup.showloginAlert = false;
  }

  reLogin() {
    return this.authenticationService.reLogin();
  }

  getDwlToken() {
    return this.objUser.user.dwl_token;
  }

  getClientPartner() {
    return this.objUser.user.owner_id;
  }

  getEmailInfo() {
    return this.objUser.email_info;
  }

  getSandboxRegisterCode() {
    return this.objUser.sandbox_register_code
      ? this.objUser.sandbox_register_code
      : '';
  }

  getDocTypeString(strDocType) {
    let fullDocType = '';
    switch (strDocType) {
      case 'reserve_agreement':
        fullDocType = 'Reserve Agreement';
        break;
      case 'ach_agreement':
        fullDocType = 'ACH Agreement';
        break;
      case 'passport':
        fullDocType = 'Passport';
        break;
      case 'license':
        fullDocType = 'License';
        break;
      case 'idCard':
        fullDocType = 'IdCard';
        break;
      case 'bank_statement':
        fullDocType = 'Bank Statement';
        break;
      case 'processing_statement':
        fullDocType = 'Processing Statement';
        break;
      case 'credit_card_statement':
        fullDocType = 'Credit Card Statement';
        break;
      case 'tax_id_ein':
        fullDocType = 'Tax ID/EIN Letter/SS4';
        break;
      case 'filed_and_stamped_articles':
        fullDocType =
          'Filed and stamped Articles of Organization or Incorporation';
        break;
      case 'sales_use_tax_license':
        fullDocType = 'Sales/Use Tax License';
        break;
      case 'business_license':
        fullDocType = 'Business License';
        break;
      case 'certificate_of_good_standing':
        fullDocType = 'Certificate of Good Standing';
        break;
      case 'dba_doc':
        fullDocType = 'DBA Document';
        break;
      case 'other':
        fullDocType = 'Other';
        break;
      case 'signed_referral_agreement':
        fullDocType = 'Signed Referral Agreement';
        break;
      case 'w_nine':
        fullDocType = 'W-9';
        break;
      case 'bank_letter_or_voided_check':
        fullDocType = 'Bank letter or Voided Check';
        break;
      default:
        fullDocType = strDocType;
        break;
    }

    return fullDocType;
  }

  isRegisterInDwl() {
    if (this.getDwlToken() == '' || this.getDwlToken() == null) {
      return false;
    }

    return true;
  }

  ghostLogin(u_token: string) {
    localStorage.setItem(
      'redirectFromGhostMode',
      window.location.hash.replace('#', '')
    );
    this.jqueryService.isInitMenu = false;
    this.jqueryService.os_init_mobile_link();
    this.authenticationService
      .retrieveUser(u_token)
      .subscribe((response: any) => {
        const objGhostUser: any = response;
        if (objGhostUser) {
          if (objGhostUser.user.is_partner == 0) {
            objGhostUser.token = this.getAuthToken();
          }
          localStorage.setItem(
            'adminUser',
            localStorage.getItem('currentUserV4')
          );
          localStorage.setItem('currentUserV4', JSON.stringify(objGhostUser));
          this.isGhostLogin = true;
          this.initUser(true);
          this.retrieveUserLimits();
        }
      });
  }

  demoLogin() {
    this.authenticationService.retrieveDemoUser().subscribe((response: any) => {
      const objGhostUser: any = response;
      if (objGhostUser) {
        localStorage.setItem('adminUser', '');
        localStorage.setItem('currentUserV4', JSON.stringify(objGhostUser));
        this.isDemoLogin = true;
        this.initUser(true);
      }
    });
  }

  backToSuperAdmin() {
    localStorage.removeItem('sandBoxMode');
    localStorage.setItem('currentUserV4', localStorage.getItem('adminUser'));
    localStorage.removeItem('adminUser');
    this.initUser(true);
    this.initReloginModule();
    this.isGhostLogin = false;
    this.isDemoLogin = false;
    this.lastActive = Date.now();

    setTimeout(() => this.jqueryService.initInterface(), 2000);
  }

  redirectLoginedUser() {
    if (this.isSuperAdmin() || this.isAdmin()) {
      this.router.navigateByUrl('/users');
    }

    if (this.isMerchant() && !this.isPartner()) {
      this.router.navigateByUrl('/transactions');
    }

    if (this.isPartner()) {
      this.router.navigateByUrl('/partner-analytics');
    }

    if (this.isRep()) {
      this.router.navigateByUrl('/partner-analytics');
    }

    if (this.isCustomer()) {
      this.router.navigateByUrl('/transactions');
    }
  }

  redirectJustSimple(
    strUrl: string = null,
    bHasParams: boolean = false,
    objParams: any = {}
  ) {
    if (
      window.location.pathname == strUrl &&
      (strUrl == '/account/details/beneficial-verify' ||
        strUrl == '/account/details/beneficial-sertify' ||
        strUrl == '/account/details/beneficial-upload-document')
    ) {
      this.jqueryService.scrollWrapper('html', 10000);
    }
    if (strUrl) {
      if (!bHasParams) {
        this.router.navigateByUrl(strUrl);
      } else {
        //        this.router.navigateByUrl(strUrl)
        this.router.navigate([strUrl, objParams], {replaceUrl: true});
      }
    }
  }

  canSwitchToSandBox() {
    if (
      this.isMerchant() &&
      environment.canSwitchToSandBox &&
      !this.isDemoUser()
    ) {
      return true;
    }

    return false;
  }

  canSwitchToLive() {
    if (
      !environment.canSwitchToSandBox &&
      this.isMerchant() &&
      !this.isDemoUser()
    ) {
      return true;
    }

    return false;
  }

  canSignUpToLive() {
    if (this.isSandBoxMode() && this.isDemoUser()) {
      return true;
    }

    return false;
  }

  canSendAction() {
    if (!this.checkToProcessedPlan) {
      return true;
    }

    if (this.isSendEnabled()) {
      return true;
    }

    if (this.getCountFreeChecks() > 0) {
      return true;
    }

    if (this.isSubscriptionPaid()) {
      return true;
    }

    return false;
  }

  canRequestAction() {
    if (!this.checkToProcessedPlan) {
      return true;
    }

    if (this.isRequestEnabled()) {
      return true;
    }

    if (this.getCountFreeReceivs() > 0) {
      return true;
    }

    if (this.isSubscriptionPaid()) {
      return true;
    }

    return false;
  }

  canPaymentLinkAction() {
    if (!this.checkToProcessedPlan) {
      return true;
    }

    if (this.isPaymentLinkEnabled()) {
      return true;
    }

    if (this.getCountFreeReceivs() > 0) {
      return true;
    }

    if (this.isSubscriptionPaid()) {
      return true;
    }

    return false;
  }

  isSubscriptionPaid() {
    if (!this.isMerchant()) {
      return false;
    }

    //    let plan = this.getSubscription()
    //    if (plan && plan.status == 'processed') {
    //      return true
    //    }

    return this.objUser.subscription_active;
  }

  isSandBoxMode() {
    return environment.isSandBoxMode;
  }

  isNeedToProcessBeneficiar() {
    return (
      this.isRequestAddBeneficialOwner ||
      this.isRequestVerifydBeneficialOwner ||
      this.isRequestCertifyBeneficialOwner
    );
  }

  checkAvailableActions(action: string = '', strUrlRedirect: string = '') {
    localStorage.removeItem('redirect_url_after');
    if (strUrlRedirect.length) {
      localStorage.setItem('redirect_url_after', strUrlRedirect);
    }

    if (this.isHavePersonalSignupLock()) {
      return false;
    }

    if (
      this.isMerchant() &&
      !this.isEmailConfirmation() &&
      this.getCountFreeChecks() == 0
    ) {
      this.jqueryService.showModal('.alert-verify-email-modal', {
        backdrop: 'static',
        keyboard: false,
        showClose: true,
      });
      return false;
    }

    if (
      (this.isMerchant() || this.isClient()) &&
      this.isBankStatementVerified() &&
      !this.isFilledInfoForDwolla() &&
      action == 'purchasePlan'
    ) {
      this.jqueryService.showModal('.onboarding-in-dwolla-modal', {
        backdrop: 'static',
        keyboard: false,
      });
      return false;
    }

    if (
      (this.isMerchant() || this.isClient()) &&
      !this.isBankStatementVerified() &&
      !this.isFilledInfoForDwolla() &&
      action == 'purchasePlan'
    ) {
      return false;
    }

    if (
      this.isClient() &&
      !this.isPasswordSet() &&
      (action == 'purchasePlan' ||
        action == 'addBankAccount' ||
        action == 'saveAccount')
    ) {
      this.jqueryService.showModal('.password-setup-modal', {
        backdrop: 'static',
        keyboard: false,
        showClose: true,
      });
      return false;
    }

    if (
      this.isMerchant() &&
      !this.isHaveBankAccount() &&
      (action == 'purchasePlan' || action == 'payAcceptTransfer')
    ) {
      this.jqueryService.showModal('.funding-source-setup-modal', {
        backdrop: 'static',
        keyboard: false,
      });
      return false;
    }

    if (
      (this.isMerchant() || this.isClient()) &&
      !this.isHaveVerifyBankAccount() &&
      (action == 'purchasePlan' || action == 'payAcceptTransfer')
    ) {
      this.jqueryService.onClick('#verify_funding_source');
      return false;
    }

    if (this.isMerchant() && action == 'dwollaRetry') {
      this.jqueryService.showModal('.onboarding-in-dwolla-modal', {
        backdrop: 'static',
        keyboard: false,
      });
      return false;
    }

    if (this.isMerchant() && action == 'dwollaDocument') {
      this.redirectJustSimple('/account/documents');
      return false;
    }

    if (
      (this.isMerchant() || this.isClient()) &&
      !this.isFilledInfoForDwolla() &&
      action == 'purchasePlan'
    ) {
      this.jqueryService.showModal('.onboarding-in-dwolla-modal', {
        backdrop: 'static',
        keyboard: false,
      });
      return false;
    }

    if (
      this.isClient() &&
      this.isHaveVerifyBankAccount() &&
      action == 'purchasePlan'
    ) {
      if (this.getCountFreeChecks() > 0 && strUrlRedirect == '/send-money') {
        return true;
      }
      if (!(this.isPersonalAccount() && this.isMerchant())) {
        this.jqueryService.showPurchasePlanModal();
      }
      return false;
    }

    if (this.isMerchant() && !this.isHavePlan() && action == 'purchasePlan') {
      if (this.getCountFreeChecks() > 0 && strUrlRedirect == '/send-money') {
        return true;
      }
      if (
        this.getCountFreeReceivs() > 0 &&
        strUrlRedirect == '/receive-money'
      ) {
        return true;
      }

      if (
        !this.isIndividualAccount() &&
        this.isController() &&
        this.isNeedToProcessBeneficiar()
      ) {
        this.topAlertsService.popToast(
          'error',
          'Error',
          '<a href="#/account">Please click here to confirm your beneficial ownership details.</a>'
        );
        return false;
      }

      if ((strUrlRedirect == '/send-money' && this.isSendEnabled())
        || (strUrlRedirect == '/receive-money' && this.isRequestEnabled())
      ) {
        return true;
      }
      if (!(this.isPersonalAccount() && this.isMerchant())) {
        if (!this.hasOnboardingIsCompleteParam()) {
          this.jqueryService.showPurchasePlanModal();
        } else {
          this.topAlertsService.popToast(
            'error',
            'Error',
            'Sorry, you don\'t have an active plan to access this feature.'
          );
        }
      }
      return false;
    }

    return true;
  }

  reInitClient(callback: any = null) {
    if (!this.isLogined() || !localStorage.getItem('currentUserV4')) {
      return;
    }
    if (this.isGhostLogin) {
      this.authenticationService
        .retrieveUser(this.getToken())
        .subscribe((response: any) => {
          const objGhostUser: any = response;
          if (objGhostUser) {
            objGhostUser.token = this.getAuthToken();
            localStorage.setItem('currentUserV4', JSON.stringify(objGhostUser));
            this.authenticationService.$authUser.next(objGhostUser);
          }
        });
    } else {
      this.reLogin();
    }
    setTimeout(() => this.initUser(), 2000);
    if (callback) {
      setTimeout(() => callback(), 1000);
    }
  }

  refreshClient() {
    if (!this.getAuthToken() || !this.objUser) {
      return;
    }
    this.authenticationService.refreshToken().subscribe((response: any) => {
      if (response.success) {
        localStorage.setItem('currentUserV4', JSON.stringify(response));
      }
    });
  }

  canProcessChecks(strTypeCheck: string = '') {
    if (
      (strTypeCheck == 'send-money' || strTypeCheck == 'send-mass-payouts') && this.canSendAction()
    ) {
      return true;
    }

    if (
      strTypeCheck == 'receive-money' && this.canRequestAction()
    ) {
      return true;
    }

    if (
      strTypeCheck == 'payment_link' && this.canPaymentLinkAction()
    ) {
      return true;
    }

    if (
      (strTypeCheck == 'send-money' || strTypeCheck == 'send-mass-payouts') &&
      this.getCountFreeChecks() > 0 &&
      this.isMerchant() &&
      this.isDwollaVerified()
    ) {
      return true;
    }

    if (
      (strTypeCheck == 'send-money' || strTypeCheck == 'send-mass-payouts') &&
      this.getCountFreeChecks() > 0 &&
      this.isClient() &&
      this.isHaveVerifyBankAccount()
    ) {
      return true;
    }

    if (
      (strTypeCheck == 'receive-money' || strTypeCheck == 'payment_link') &&
      this.getCountFreeReceivs() > 0 &&
      this.isMerchant() &&
      this.isDwollaVerified()
    ) {
      return true;
    }

    if (
      (strTypeCheck == 'receive-money' || strTypeCheck == 'payment_link') &&
      this.getCountFreeReceivs() > 0 &&
      this.isClient() &&
      this.isHaveVerifyBankAccount()
    ) {
      return true;
    }

    if (this.isMerchant() && this.isHavePlan() && this.isDwollaVerified()) {
      return true;
    }

    return false;
  }

  showUserMessage(
    messageID: string = '',
    messageType: string = 'error',
    messageTitle: string = 'Error'
  ) {
    this.topAlertsService.popToast(
      messageType,
      messageTitle,
      this.messages.get(messageID)
    );
    return false;
  }

  showErrorMessage(
    message: string = ''
  ) {
    this.topAlertsService.popToast(
      'error',
      'Error',
      message
    );
    return false;
  }

  getUserCheckingActionForSend(strUrlRedirect: string = '') {
    if (this.isMerchant() && this.getOnboardingStep() < 5) {
      return 'this.userService.showErrorMessage("Complete your setup to start processing ACH payments.")';
    }

    if (this.isMerchant() && this.isDwollaRetry()) {
      return 'this.userService.checkAvailableActions("dwollaRetry")';
    }

    if (this.isMerchant() && this.isDwollaSuspended()) {
      return 'this.userService.checkAvailableActions("dwollaSuspended")';
    }

    if (this.isMerchant() && this.isDwollaDocument()) {
      return 'this.userService.checkAvailableActions("dwollaDocument")';
    }

    if (!this.isHaveBankAccount() || !this.isHaveVerifyBankAccount()) {
      return 'this.userService.checkAvailableActions("payAcceptTransfer")';
    }

    if (this.canSendAction() && !this.isSendEnabled()) {
      return 'this.userService.showUserMessage("CLIENT_SEND_DISABLED")';
    }

    if (!this.isMerchant() || !this.isHavePlan()) {
      return (
        'this.userService.checkAvailableActions("purchasePlan", "' +
        strUrlRedirect +
        '")'
      );
    }

    if (!this.canSendAction()) {
      return 'this.userService.showUserMessage("MESSAGE_PLAN_PAYMENT_IS_NOT_COMPLITED")';
    }
  }

  getUserCheckingActionForRequest(strUrlRedirect: string = '') {
    if (this.isMerchant() && this.getOnboardingStep() < 5) {
      return 'this.userService.showErrorMessage("Complete your setup to start processing ACH payments.")';
    }

    if (this.isMerchant() && this.isDwollaRetry()) {
      return 'this.userService.checkAvailableActions("dwollaRetry")';
    }

    if (this.isMerchant() && this.isDwollaDocument()) {
      return 'this.userService.checkAvailableActions("dwollaDocument")';
    }

    if (this.isMerchant() && this.isDwollaSuspended()) {
      return 'this.userService.checkAvailableActions("dwollaSuspended")';
    }

    if (!this.isHaveBankAccount() || !this.isHaveVerifyBankAccount()) {
      return 'this.userService.checkAvailableActions("payAcceptTransfer")';
    }

    if (this.canRequestAction() && !this.isRequestEnabled()) {
      return 'this.userService.showUserMessage("CLIENT_RECEIVE_DISABLED")';
    }

    if (!this.isMerchant() || !this.isHavePlan()) {
      return (
        'this.userService.checkAvailableActions("purchasePlan", "' +
        strUrlRedirect +
        '")'
      );
    }

    if (!this.canRequestAction()) {
      return 'this.userService.showUserMessage("MESSAGE_PLAN_PAYMENT_IS_NOT_COMPLITED")';
    }
  }

  getUserCheckingActionForPaymentLink(strUrlRedirect: string = '') {
    if (this.isMerchant() && this.getOnboardingStep() < 5) {
      return 'this.userService.showErrorMessage("Complete your setup to start processing ACH payments.")';
    }

    if (this.isMerchant() && this.isDwollaRetry()) {
      return 'this.userService.checkAvailableActions("dwollaRetry")';
    }

    if (this.isMerchant() && this.isDwollaDocument()) {
      return 'this.userService.checkAvailableActions("dwollaDocument")';
    }

    if (this.isMerchant() && this.isDwollaSuspended()) {
      return 'this.userService.checkAvailableActions("dwollaSuspended")';
    }

    if (!this.isHaveBankAccount() || !this.isHaveVerifyBankAccount()) {
      return 'this.userService.checkAvailableActions("payAcceptTransfer")';
    }

    if (this.canPaymentLinkAction() && !this.isPaymentLinkEnabled()) {
      return 'this.userService.showUserMessage("CLIENT_PAYMENT_PAGE_DISABLED")';
    }

    if (!this.isMerchant() || !this.isHavePlan()) {
      return (
        'this.userService.checkAvailableActions("purchasePlan", "' +
        strUrlRedirect +
        '")'
      );
    }

    if (!this.canRequestAction()) {
      return 'this.userService.showUserMessage("MESSAGE_PLAN_PAYMENT_IS_NOT_COMPLITED")';
    }
  }

  callAction(stringAction: string = '') {
    eval(stringAction);
  }

  checkCertifiedBeneficialOwner() {
    if (this.isIndividualAccount() || !this.isController()) {
      return;
    }
    this.authenticationService
      .getStatusCertifiedBeneficialOwner(this.getToken())
      .subscribe((status) => {
        if (status) {
          if (status == 'recertify' || status == 'uncertified') {
            this.isCertifiedBeneficialOwner = false;
          } else {
            this.isCertifiedBeneficialOwner = true;
          }
        } else {
          this.isCertifiedBeneficialOwner = true;
        }
      });
  }

  labelIsSameFingerprint(label: string = '') {
    if (label && label.indexOf('Fingerprint') >= 0) {
      return true;
    }

    return false;
  }

  labelIsSameBank(label: string = '') {
    if (label && label.indexOf('Same Bank') >= 0) {
      return true;
    }

    return false;
  }

  labelIsHighReturns(label: string = '') {
    if (label && label.indexOf('High Returns') >= 0) {
      return true;
    }

    return false;
  }

  labelIsSameIp(label: string = '') {
    if (label && label.indexOf('Same IP') >= 0) {
      return true;
    }

    return false;
  }

  labelIsFailBankSync(label: string = '') {
    if (label && label.indexOf('Fail Bank Sync') >= 0) {
      return true;
    }

    return false;
  }

  labelIsNotForShowInPopup(label: string = '') {
    return (
      !this.labelIsSameFingerprint(label) &&
      !this.labelIsSameBank(label) &&
      !this.labelIsSameIp(label) &&
      !this.labelIsHighReturns(label)
    );
  }

  // don't remove this method
  clearLocalStorageById(strLSName: string = '') {
    localStorage.removeItem(strLSName);
  }

  getPageTitle(strSlug: boolean = false, isAppUsers: boolean = false) {
    /*switch (strSlug) {
      case 'customers':
        pageTitle = this.isPartner() ? 'Users' : 'Customers';
        break;
    }*/
    if (isAppUsers) {
      return 'Applications';
    }
    if (this.isRep() && !this.isAdminAccessRep()) {
      return 'Rep Users';
    }
    if (this.isRep() && !this.isAdminAccessRep()) {
      return 'Merchants';
    }
    return strSlug ? 'Merchants' : 'Customers';
  }

  //  ***************************** MENU ITEMS *****************************

  initMenu() {
    this.menu = [
      {
        name: 'Dashboard Analytics',
        iconClasses: 'picons-thin-icon-thin-0377_screen_analytics_pie_graph',
        image: false,
        src: '',
        sref: '/dashboard-analytics',
        subMenu: false,
        id: 'menuItemDashboardAnalytics',
        element_id: 'dashboardAnalytics',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'My Merchants',
        iconClasses: 'np_image os-icon os-icon-users',
        image: false,
        src: '',
        subMenu: true,
        id: 'menuItemMyMerchanta',
        sref: 'agent-merchants',
        element_id: 'my_merchant',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [
          {
            name: 'Merchants',
            iconClasses: 'np_image os-icon os-icon-users menuItemPartnerUsers',
            sref: 'agent-merchants',
            id: 'menuItemMyMerchantsList',
            element_id: 'my_merchants_list',
          },
          {
            name: 'Commissions',
            iconClasses:
              'picons-thin-icon-thin-0377_screen_analytics_pie_graph',
            sref: 'agent-commissions',
            id: 'menuItemMyMerchantsCommissions',
            element_id: 'my_merchant_commissions',
          },
        ],
      },
      {
        name: 'Dashboard',
        iconClasses: 'picons-thin-icon-thin-0377_screen_analytics_pie_graph',
        image: false,
        src: '',
        sref: '/partner-analytics',
        subMenu: false,
        id: 'menuItemPartnerAnalytics',
        element_id: 'partnerAnalytics',
        permissionValue: this.isPartner() || this.isRep(),
        menu: [{}],
      },
      {
        name: 'Admins',
        iconClasses:
          'picons-thin-icon-thin-0705_user_profile_security_password_permissions',
        image: false,
        src: '',
        sref: '/admins',
        subMenu: false,
        id: 'menuItemAdmin',
        element_id: 'admins',
        permissionValue:
          (this.isAdmin() || this.isSuperAdmin()) && !this.isPartner(),
        menu: [{}],
      },
      {
        name: 'Transactions',
        iconClasses:
          'picons-thin-icon-thin-0409_wallet_credit_card_money_payment',
        image: false,
        src: '',
        sref: '/transactions',
        subMenu: false,
        id: 'menuItemTransactions',
        element_id: 'transactions',
        clickAction:
          'this.userService.clearLocalStorageById("transactionsList")',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Dashboard',
        iconClasses: 'os-icon os-icon-layout',
        image: true,
        src: 'np-dashboard-205309-000000.svg',
        mobile_src: 'np-dashboard-205309-000000_1.svg',
        sref: '/transactions',
        subMenu: false,
        id: 'menuItemDashboard',
        element_id: 'dashboard',
        clickAction:
          'this.userService.clearLocalStorageById("transactionsList")',
        permissionValue:
          (this.isMerchant() || this.isCustomer()) && !this.isPartner(),
        menu: [{}],
      },
      {
        name: 'Send a Payment',
        title: this.isClient()
          ? this.messages.get('TEXT_FREE_CHECKS_FOR_INDIVIDUAL')
          : '',
        iconClasses: 'picons-thin-icon-thin-0428_money_payment_dollar_bag_cash',
        image: true,
        src: 'np-send-money-950868-000000.svg',
        mobile_src: 'np-send-money-950868-000000_1.svg',
        subMenu: false,
        id: 'menuItemSendCheck',
        sref: this.canProcessChecks('send-money')
          ? '/send-money'
          : '/transactions',
        clickAction: this.canProcessChecks('send-money')
          ? 'this.userService.clearLocalStorageById("paymentLinks")'
          : this.getUserCheckingActionForSend('/send-money'),
        element_id: 'send_money',
        permissionValue:
          this.isMerchant() && !this.isDwollaSuspended() && !this.isPartner(),
        menu: [{}],
      },
      {
        name: 'Send Mass Payouts',
        title: this.isClient()
          ? this.messages.get('TEXT_FREE_CHECKS_FOR_INDIVIDUAL')
          : '',
        iconClasses: 'picons-thin-icon-thin-0428_money_payment_dollar_bag_cash',
        image: true,
        src: 'np-send-money-991342-000000.svg',
        mobile_src: 'np-send-money-991342-000000_1.svg',
        subMenu: false,
        id: 'menuItemSendMassPayouts',
        sref: this.canProcessChecks('send-mass-payouts')
          ? '/send-mass-payouts'
          : '/transactions',
        clickAction: this.canProcessChecks('send-mass-payouts')
          ? null
          : this.getUserCheckingActionForSend('/send-mass-payouts'),
        element_id: 'send_many_money',
        permissionValue:
          this.isMerchant() && !this.isDwollaSuspended() && !this.isPartner(),
        menu: [{}],
      },
      {
        name: 'Request a Payment',
        iconClasses: 'picons-thin-icon-thin-0143_rotate_clockwise',
        image: true,
        src: 'np-send-money-950868-000001.svg',
        mobile_src: 'np-send-money-950868-000001_1.svg',
        sref: this.canProcessChecks('receive-money')
          ? '/receive-money'
          : '/transactions',
        clickAction: this.canProcessChecks('receive-money')
          ? 'this.userService.clearLocalStorageById("paymentRequests")'
          : this.getUserCheckingActionForRequest('/receive-money'),
        subMenu: false,
        id: 'menuItemRequestCheck',
        element_id: 'request_check',
        permissionValue:
          this.isMerchant() && !this.isDwollaSuspended() && !this.isPartner(),
        menu: [{}],
      },
      {
        name: 'Payment Page',
        iconClasses: 'picons-thin-icon-thin-0143_rotate_clockwise',
        image: true,
        src: 'np-send-money-950868-000001.svg',
        mobile_src: 'np-send-money-950868-000001_1.svg',
        sref: this.canProcessChecks('payment_link')
          ? '/payment-page'
          : '/transactions',
        clickAction: this.canProcessChecks('payment_link')
          ? 'this.userService.clearLocalStorageById("billingLinks")'
          : this.getUserCheckingActionForPaymentLink('/payment-page'),
        subMenu: false,
        id: 'menuItemPaymentLink',
        element_id: 'payment_link',
        permissionValue:
          this.isMerchant() && !this.isDwollaSuspended() && !this.isPartner(),
        menu: [{}],
      },
      {
        name: 'Applications',
        iconClasses: 'np_image os-icon os-icon-users',
        image: false,
        src: '',
        sref: '/partner-apps',
        subMenu: false,
        id: 'menuItemCustomers',
        element_id: 'customers',
        clickAction:
          'this.userService.clearLocalStorageById("merchantCustomers")',
        permissionValue: this.isPartner() || this.isRep(),
        menu: [{}],
      },
      {
        name: 'Merchants',
        iconClasses: 'np_image os-icon os-icon-users',
        image: false,
        src: '',
        sref: '/partner-merchants',
        subMenu: false,
        id: 'menuItemCustomers',
        element_id: 'customers',
        clickAction:
          'this.userService.clearLocalStorageById("merchantCustomers")',
        permissionValue: this.isPartner() || this.isRep(),
        menu: [{}],
      },
      {
        name: 'Commissions',
        iconClasses: 'picons-thin-icon-thin-0377_screen_analytics_pie_graph',
        image: false,
        src: '',
        sref: '/report-analytics',
        subMenu: false,
        id: 'menuItemReportAnalytics',
        element_id: 'reportAnalytics',
        permissionValue:
          this.isPartner() || this.isResidualsRep() || this.isAdminAccessRep(),
        menu: [{}],
      },
      {
        name: 'Customers',
        iconClasses: 'np_image os-icon os-icon-users',
        image: false,
        src: '',
        sref: '/customers',
        subMenu: false,
        id: 'menuItemCustomers',
        element_id: 'customers',
        clickAction:
          'this.userService.clearLocalStorageById("merchantCustomers")',
        permissionValue: this.isMerchant() && !this.isPartner(),
        menu: [{}],
      },
      {
        name: 'Recurring Payments',
        iconClasses: 'np_image icon-feather-repeat',
        image: false,
        src: '',
        sref: '/recurring',
        subMenu: false,
        id: 'menuItemRecurring',
        element_id: 'recurring',
        permissionValue:
          this.isMerchant() && !this.isDwollaSuspended() && !this.isPartner(),
        /*permissionValue: this.isMerchant() && !this.isDwollaSuspended() && !this.isPartner(),*/
        menu: [{}],
      },
      {
        name: 'Teams',
        iconClasses: 'picons-thin-icon-thin-0100_to_do_list_reminder_done',
        image: false,
        src: '',
        sref: '/teams',
        subMenu: false,
        id: 'menuItemTeams',
        element_id: 'Teams',
        permissionValue: this.isPartner() || this.isAdminAccessRep(),
        menu: [{}],
      },
      {
        name: 'Risk Settings',
        iconClasses: 'os-icon os-icon-user',
        image: true,
        src: 'icons8-protect-48.png',
        mobile_src: 'icons8-protect-48.png',
        sref: '/risk-settings',
        subMenu: false,
        id: 'menuItemRiskSettings',
        element_id: 'riskSettings',
        permissionValue: (this.isMerchant() || this.isCustomer()) && this.isSignalEnabled(),
        menu: [{}],
      },
      {
        name: 'Account Settings',
        iconClasses: 'os-icon os-icon-user',
        image: true,
        src: 'np-account-1488075-000000.svg',
        mobile_src: 'np-account-1488075-000000_1.svg',
        sref: '/account',
        subMenu: false,
        id: 'menuItemAccount',
        element_id: 'account',
        permissionValue: this.isMerchant() || this.isCustomer(),
        menu: [{}],
      },
      {
        name: 'Account Settings',
        iconClasses: 'os-icon os-icon-user',
        image: true,
        src: 'np-account-1488075-000000.svg',
        mobile_src: 'np-account-1488075-000000_1.svg',
        sref: '/account/profile',
        subMenu: false,
        id: 'menuItemAccount',
        element_id: 'account',
        permissionValue: this.isPartner() || this.isRep(),
        menu: [{}],
      },
      //      {
      //        name: 'Merchants',
      //        iconClasses: 'picons-thin-icon-thin-0703_users_profile_group_two',
      //        image:false,
      //        src:'',
      //        sref: '/merchants',
      //        subMenu: false,
      //        element_id: 'merchants',
      //        permissionValue: this.isAdmin() || this.isSuperAdmin() ? true : false,
      //        menu: [{}]
      //      },
      {
        name: 'Users',
        iconClasses:
          'picons-thin-icon-thin-0703_users_profile_group_two menuItemUsers',
        image: false,
        src: '',
        sref: '/users',
        subMenu: true,
        id: 'menuItemUsers',
        element_id: 'users',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        clickAction: 'this.userService.clearLocalStorageById("usersList")',
        menu: [
          {
            name: 'Applications',
            iconClasses: 'picons-thin-icon-thin-0703_users_profile_group_two',
            sref: '/users/applications',
            id: 'menuItemApplicationsList',
            element_id: 'applications_list',
          },
          {
            name: 'Merchants',
            iconClasses: 'picons-thin-icon-thin-0703_users_profile_group_two',
            sref: '/users/merchants',
            id: 'menuItemMerchantsList',
            element_id: 'merchants_list',
          },
          {
            name: 'AeroPay',
            iconClasses: 'picons-thin-icon-thin-0703_users_profile_group_two',
            sref: '/users/aeropay',
            id: 'menuItemAeroPayList',
            element_id: 'aeropay_list',
          },
          {
            name: 'Clients',
            iconClasses: 'picons-thin-icon-thin-0703_users_profile_group_two',
            sref: '/users/clients',
            id: 'menuItemClientsList',
            element_id: 'clients_list',
          },
        ],
      },
      {
        name: 'Deal Tracker',
        iconClasses:
          'picons-thin-icon-thin-0703_users_profile_group_two menuItemUsers',
        image: false,
        src: '',
        sref: '/deal-tracker',
        subMenu: false,
        id: 'menuItemDealTracker',
        element_id: 'deal_tracker',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Return Report',
        iconClasses:
          'picons-thin-icon-thin-0703_users_profile_group_two menuItemUsers',
        image: false,
        src: '',
        sref: '/reports/returns',
        subMenu: false,
        id: 'menuItemReturns',
        element_id: 'returns',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Reports',
        iconClasses: 'picons-thin-icon-thin-0377_screen_analytics_pie_graph menuItemPartner',
        image: false,
        src: '',
        subMenu: true,
        id: 'menuItemReports',
        // sref: this.sanitizer.bypassSecurityTrustResourceUrl('javascript:void(0)'),
        sref: '/reports/merchant',
        element_id: 'reports',
        permissionValue:
          this.isAdmin() || this.isSuperAdmin(),
        menu: [
          {
            name: 'Merchant',
            iconClasses:
              'picons-thin-icon-thin-0703_users_profile_group_two',
            sref: '/reports/merchant',
            id: 'menuItemMerchantMasterReport',
            element_id: 'merchant_master_report',
          },
          {
            name: 'Agent',
            iconClasses:
              'picons-thin-icon-thin-0703_users_profile_group_two',
            sref: '/reports/agent',
            id: 'menuItemAgentMasterReport',
          },
          {
            name: 'Partner',
            iconClasses:
              'picons-thin-icon-thin-0703_users_profile_group_two',
            sref: '/reports/partner',
            id: 'menuItemPartnerMasterReport',
            element_id: 'partner_master_report',
          },
        ],
      },
      {
        name: 'Partners',
        iconClasses: 'fa fa-handshake-o menuItemPartner',
        image: false,
        src: '',
        subMenu: true,
        id: 'menuItemPartner',
        // sref: this.sanitizer.bypassSecurityTrustResourceUrl('javascript:void(0)'),
        sref: '/partners',
        element_id: 'partner',
        permissionValue:
          (this.isAdmin() && this.isAvailablePartner()) ||
          (this.isSuperAdmin() && this.isAvailablePartner()),
        menu: [
          {
            name: 'Partners List',
            iconClasses:
              'picons-thin-icon-thin-0703_users_profile_group_two menuItemPartnerUsers',
            sref: '/partners',
            id: 'menuItemPartnerList',
            element_id: 'partner_list',
          },
          {
            name: 'Partners Settings',
            iconClasses:
              'picons-thin-icon-thin-0052_settings_gears_preferences_gearbox',
            sref: '/partners-settings',
            id: 'menuItemPartnersSettings',
            element_id: 'partners_settings',
          },
        ],
      },
      /* {
         name: 'Partners',
         iconClasses: 'picons-thin-icon-thin-0703_users_profile_group_two menuItemPartnerUsers',
         image: false,
         src: '',
         sref: '/partners',
         subMenu: false,
         id: 'menuItemPartnersUsers',
         element_id: 'partners',
         permissionValue: (this.isAdmin() || this.isSuperAdmin()) && environment.subDomen == 'local',
         clickAction: 'this.userService.clearLocalStorageById("partnerList")',
         menu: [{}]
       },*/
      {
        name: 'Payments',
        iconClasses:
          'picons-thin-icon-thin-0409_wallet_credit_card_money_payment',
        image: false,
        src: '',
        sref: '/payments',
        subMenu: false,
        id: 'menuItemPayments',
        element_id: 'payments',
        clickAction: 'this.userService.clearLocalStorageById("paymentsList")',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Marketplace',
        iconClasses:
          'picons-thin-icon-thin-0381_line_structure_relations_hierarchy',
        image: false,
        src: '',
        sref: '/marketplace/users',
        subMenu: false,
        id: 'menuItemMarketplace',
        element_id: 'marketplace',
        permissionValue:
          this.isMerchant() &&
          this.isMarketplace() &&
          this.isHaveApiApp() &&
          environment.availableMarketPlaceSettings &&
          !this.isPartner(),
        menu: [{}],
      },
      {
        name: 'Email',
        iconClasses: 'picons-thin-icon-thin-0321_email_mail_post_at',
        image: false,
        src: '',
        subMenu: true,
        id: 'menuItemEmail',
        sref: '/mail-template',
        // sref: this.sanitizer.bypassSecurityTrustResourceUrl('javascript:void(0)'),
        element_id: 'email',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [
          {
            name: 'System Template',
            iconClasses: 'picons-thin-icon-thin-0316_email_mail_post_open',
            sref: '/mail-template',
            id: 'menuItemEmailTemplate',
            element_id: 'mail_template',
          },
          {
            name: 'Client Template',
            iconClasses: 'picons-thin-icon-thin-0316_email_mail_post_open',
            sref: '/mail-template-client',
            id: 'menuItemEmailTemplateClient',
            element_id: 'mail_template_client',
          },
          {
            name: 'Email History',
            iconClasses: 'picons-thin-icon-thin-0318_email_attachment',
            sref: '/mail-storage',
            id: 'menuItemEmailHistory',
            element_id: 'mail_storage',
          },
        ],
      },
      {
        name: 'Messages',
        iconClasses: 'picons-thin-icon-thin-0279_chat_message_comment_bubble',
        image: false,
        src: '',
        sref: '/messages',
        subMenu: false,
        id: 'menuItemMessages',
        element_id: 'messages',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Invitations',
        iconClasses:
          'picons-thin-icon-thin-0394_business_handshake_deal_contract_sign',
        image: false,
        src: '',
        sref: '/invitations',
        subMenu: false,
        id: 'menuItemInvitations',
        element_id: 'invitations',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Plans',
        iconClasses: 'picons-thin-icon-thin-0100_to_do_list_reminder_done',
        image: false,
        src: '',
        sref: '/plans',
        subMenu: false,
        id: 'menuItemPlans',
        element_id: 'plans',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Funding Sources',
        iconClasses: 'picons-thin-icon-thin-0418_bank_pantheon',
        image: false,
        src: '',
        sref: '/funding-sources',
        subMenu: false,
        id: 'menuItemFundingSources',
        element_id: 'funding_sources',
        permissionValue: this.isSuperAdmin(),
        menu: [{}],
      },
      //      {
      //        name: 'View Our Plans',
      //        iconClasses: 'picons-thin-icon-thin-0014_notebook_paper_todo',
      //        image:false,
      //        src:'',
      //        sref: '/plans',
      //        subMenu: false,
      //        element_id: 'view_our_plans',
      //        permissionValue: this.isMerchant() ? true : false,
      //        menu: [{}]
      //      },
      {
        name: 'Payment Requests',
        iconClasses: 'picons-thin-icon-thin-0411_invoice_dollar_bill_payment',
        image: false,
        src: '',
        sref: '/request-payments',
        subMenu: false,
        id: 'menuItemPaymentRequests',
        element_id: 'request_payments',
        permissionValue:
          this.isMerchant() &&
          this.isDwollaVerified() &&
          this.isCustomer() &&
          !this.isPartner(),
        menu: [{}],
      },
      //      {
      //        name: 'Billing',
      //        iconClasses: 'picons-thin-icon-thin-0411_invoice_dollar_bill_payment',
      //        image:false,
      //        src:'',
      //        sref: '/billing',
      //        subMenu: false,
      //        element_id: 'billing',
      //        permissionValue: this.isMerchant() ? true : false,
      //        menu: [{}]
      //      },
      {
        name: 'Subscriptions & Direct Debits',
        iconClasses: 'picons-thin-icon-thin-0411_invoice_dollar_bill_payment',
        image: false,
        src: '',
        sref: '/subscription',
        subMenu: false,
        id: 'menuItemSubscriptionsInvoices',
        element_id: 'subscriptions_invoices',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      // {
      //   name: 'Campaign Statistics',
      //   iconClasses: 'fa fa-line-chart',
      //   image: false,
      //   src: '',
      //   sref: '/campaign-statistic',
      //   subMenu: false,
      //   id: 'menuItemCampaignStatistics',
      //   element_id: 'campaign_statistic',
      //   permissionValue: this.isAdmin() || this.isSuperAdmin(),
      //   menu: [{}]
      // },
      {
        name: 'Webhooks',
        iconClasses:
          'picons-thin-icon-thin-0381_line_structure_relations_hierarchy',
        image: false,
        src: '',
        sref: '/webhooks',
        subMenu: false,
        id: 'menuItemWebhooks',
        element_id: 'webhooks',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Email Log',
        iconClasses: 'picons-thin-icon-thin-0316_email_mail_post_open',
        image: false,
        src: '',
        sref: '/email-log',
        subMenu: false,
        id: 'menuItemEmailLog',
        element_id: 'emailLog',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Deal Owner Rotations',
        iconClasses: 'picons-thin-icon-thin-0703_users_profile_group_two menuItemUsers',
        image: false,
        src: '',
        sref: '/agent-rotation',
        subMenu: false,
        id: 'menuItemDealOwnerRotations',
        element_id: 'dealOwnerRotations',
        permissionValue: this.isSuperAdmin(),
        menu: [{}],
      },
      {
        name: 'Settings',
        iconClasses:
          'picons-thin-icon-thin-0052_settings_gears_preferences_gearbox',
        image: false,
        src: '',
        subMenu: true,
        id: 'menuItemSettings',
        // sref: this.sanitizer.bypassSecurityTrustResourceUrl('javascript:void(0)'),
        sref: '/statuses',
        element_id: 'settings',
        permissionValue: this.isAdmin() || this.isSuperAdmin(),
        menu: [
          {
            name: 'Paynote Costs',
            iconClasses: 'picons-thin-icon-thin-0411_invoice_dollar_bill_payment',
            sref: '/costs',
            id: 'menuItemCosts',
            element_id: 'costs',
          },
          {
            name: 'Bank Holiday',
            iconClasses:
              'picons-thin-icon-thin-0022_calendar_month_day_planner',
            sref: '/bank-holiday',
            id: 'menuItemBankHoliday',
            element_id: 'bank_holiday',
          },
          {
            name: 'Status List',
            iconClasses: 'picons-thin-icon-thin-0059_error_warning_danger_stop',
            sref: '/statuses',
            id: 'menuItemStatusList',
            element_id: 'status_list',
          },
          {
            name: 'Block List',
            iconClasses: 'picons-thin-icon-thin-0059_error_warning_danger_stop',
            sref: '/settings',
            id: 'menuItemBlockList',
            element_id: 'block_list',
          },
          {
            name: 'Multiple Phone',
            iconClasses: 'picons-thin-icon-thin-0295_phone_hold_call',
            sref: '/multiple-phone',
            id: 'menuItemMultiplePhone',
            element_id: 'multiple_phone',
          },
          {
            name: 'Canned Responses',
            iconClasses: 'icon-feather-message-square',
            sref: '/canned-responses',
            id: 'standard_responses',
            element_id: 'standard_responses',
          },
          {
            name: 'Rules for Signup',
            //            iconClasses: 'picons-thin-icon-thin-0706_user_profile_add_new',
            iconClasses: 'picons-thin-icon-thin-0004_pencil_ruler_drawing',
            sref: '/rules-for-signup',
            id: 'rules_for_signup',
            element_id: 'rules_for_signup',
          },
          {
            name: 'Rules for Transactions',
            iconClasses: 'picons-thin-icon-thin-0004_pencil_ruler_drawing',
            sref: '/rules-for-transactions',
            id: 'rules_for_transactions',
            element_id: 'rules_for_transactions',
          },
          {
            name: 'Email Settings',
            iconClasses: 'picons-thin-icon-thin-0319_email_mail_post_card',
            sref: '/email-settings',
            id: 'email_settings_for_admin',
            element_id: 'email_settings_for_admin',
          },
        ],
      },
      // {
      //   name: 'Logout',
      //   iconClasses: 'os-icon os-icon-signs-11',
      //   image: false,
      //   src: '',
      //   sref: '/login',
      //   subMenu: false,
      //   id: 'menuItemLogOut',
      //   element_id: 'logOut',
      //   permissionValue: true,
      //   menu: [{}]
      // },
    ];
    setTimeout(() => this.jqueryService.initLeftSideBarActions(), 500);
  }

  getInternalBalance() {
    return this.objUser.internal_balance;
  }

  sendFundsFromBalance() {
    if (this.objUser && this.objUser.user && this.objUser.user.settings) {
      return this.objUser.user.settings.send_funds_from_balance;
    }
    return 0;
  }


  getDocsFailureReasons() {
    if (this.objUser && this.objUser.docs_failure_reasons) {
      return this.objUser.docs_failure_reasons;
    } else {
      return null;
    }
  }

  getOnboardingStep() {
    if (this.objUser && this.objUser.user && this.objUser.user.settings) {
      return this.objUser.user.settings.onboarding_step;
    }
    return 5;
  }

  isTestAdmin() {
    if (this.objUser && this.objUser.user && this.objUser.user.email) {
      return this.objUser.user.email === 'mykhailo@datasub.com';
    }
    return false;
  }
}
