import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {TwoFaPopupComponent} from '../views/two-fa-popup/two-fa-popup/two-fa-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {TimerService} from './timer.service';


enum httpMethod {
  post = 'POST',
}

@Injectable({
  providedIn: 'root',
})
export class TwoFaService {
  static $onRequest = new Subject();
  static $onError = new Subject();
  host: string;
  private verifications: any = {};
  private isOpenTwoFa: boolean;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
  ) {
    this.host = environment.host;
    TwoFaService.$onRequest.subscribe((e: any) => {
      this.handleRequest(e.req, e.response);
    });
    TwoFaService.$onError.subscribe((e: any) => {
      this.handleErrorRequest(e);
    });
  }

  verify(url: string, data: any, method: httpMethod = httpMethod.post): Observable<boolean | any> {
    this.verifications[url] = this.verifications[url] ?? new Subject<any>();
    this.http.request(method, this.host + url, {body: data}).subscribe();
    return this.verifications[url];
  }


  handleRequest(request: any, response: any) {
    const subject = this.getSubject(this.parseUrl(response.url));
    if (response?.body?.requires_2fa !== undefined && response.body?.requires_2fa) {
      if (!this.isOpenTwoFa) {
        this.isOpenTwoFa = true;
        return this.dialog.open(TwoFaPopupComponent, {
          data: {request, response: response},
          id: 'Two-fa',
          panelClass: 'two-fa-panel',
        }).afterClosed().subscribe(e => {
          this.isOpenTwoFa = false;
          if (subject) {
            subject.error(e);
            this.closeSubject(this.parseUrl(response.url));
          }
        });
      }
    } else {
      if (subject) {
        subject.next(response.body);
        this.closeSubject(this.parseUrl(response.url));
        this.isOpenTwoFa = false;
      }
    }
  }

  private getSubject(url: string): Subject<boolean> {
    const verifications = this.verifications[url];
    return verifications instanceof Subject ? verifications : null;
  }

  private parseUrl(url: string): string {
    try {
      const parsedUrl = new URL(url);
      const path = parsedUrl.pathname.replace(/^\/api/, '');
      return path || '/';
    } catch (error) {
      return '';
    }
  }

  handleErrorRequest(err) {
    const subject = this.getSubject(this.parseUrl(err.url));
    if (subject) {
        subject.error(err);
        this.closeSubject(this.parseUrl(err.url));
    }
  }

  closeSubject(url: string) {
    const subject = this.verifications[url];

    if (subject) {
      setTimeout(() => {
        subject.complete();
        delete this.verifications[url];
      }, 100);
    }
  }

  validateSteps(steps: string) {
    if (['2/2', '1/1', '0/0', '0/1'].includes(steps)) {
      return '';
    }
    return steps;
  }
}
