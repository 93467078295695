import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthenticationService} from '../auth.service';
import {UserService} from '../_services/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  static logeined: string = null;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const currentUserV4 = <any>JSON.parse(localStorage.getItem('currentUserV4'));
    const headers = <any>{
      'Content-Type': 'application/json; charset=utf-8',
    };

    if (localStorage.getItem('ipClient')) {
      headers['X-Remote-IP'] = localStorage.getItem('ipClient');
    }

    if (currentUserV4 && currentUserV4.manager && currentUserV4.manager.u_token) {
      headers['X-Mng-Token'] = currentUserV4.manager.u_token;
    }
    AuthenticationService.initFPB().then();
    request = request.clone({
      setParams: {
        fpb: this.authService.getFingerPrintBrowser(),
        fpb_request_id: this.authService.getFingerPrintBrowserRequestId(),
        ...request.params,
      },
    });

    const ghostLogin = JSON.parse(localStorage.getItem('adminUser'));
    if (UserService._isGhostLogin && ghostLogin?.user?.email) {
      headers['X-Ghost-Log'] = ghostLogin?.user?.email;
      if (currentUserV4?.user?.email !== undefined && currentUserV4?.user?.email) {
        headers['X-Ghost-Owner'] = currentUserV4?.user?.email;
      }
    }

    if (
      currentUserV4 &&
      currentUserV4.token &&
      !headers.hasOwnProperty('Authorization')
    ) {
      headers['Authorization'] = 'Bearer ' + currentUserV4.token;
      if (JwtInterceptor.logeined == 'manager') {
        headers['Authorization'] = 'Bearer ' + currentUserV4.manager.token;
      } else {
        headers['Authorization'] = 'Bearer ' + currentUserV4.token;
      }

      request = request.clone({
        setHeaders: headers,
      });
    } else {
      request = request.clone({
        setHeaders: headers,
      });
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (
            err?.error?.error &&
            (err?.error?.message == 'Token has expired' ||
              err?.error?.message == 'access_denied' ||
              err?.error?.error == 'token_not_provided' ||
              err?.error?.error == 'token_expired' ||
              err?.error?.error == 'token_invalid' ||
              err?.error?.error == 'user_not_found')
          ) {
            this.router.navigate(['/login']);
          }
        }
        return throwError(err);
      })
    );
  }
}
