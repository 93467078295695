import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {MessagesService} from '../../_services/messages.service';
import {UserService} from '../../_services/user.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {

  public host: string;

  constructor(
    private http: HttpClient,
    private router: ActivatedRoute,
    private userService: UserService,
    private route: Router,
    public messages: MessagesService
  ) {
    this.host = environment.host;
  }

  ngOnInit() {
    const token = this.router.snapshot.paramMap.get('confirm_token');
    this.confirmEmail(token);
  }

  confirmEmail(token: string) {
    const objRequest: any = {
      token: token
    };
    this.http.post<any>(this.host + '/user/email/confirmation', objRequest)
      .subscribe(
        response => {
          if (response.success) {
            localStorage.setItem('currentUserV4', JSON.stringify(response));
          }
        },
        errResponse => {
          if (errResponse.error) {

          }
        }
      );
  }

  goToDashboard() {
    this.userService.initUser();
    this.route.navigate(['/transactions']);
  }

}
