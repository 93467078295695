import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './_guards/auth.guard';
import {DefaultLayoutComponent} from './containers';
import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {RegisterComponent} from './views/register/register.component';
import {SignUpComponent} from './views/sign-up/sign-up.component';
import {RecoveryPasswordComponent} from './views/recovery-password/recovery-password.component';
import {DigitalCheckComponent} from './views/digital-check/digital-check.component';
import {ConfirmEmailComponent} from './views/confirm-email/confirm-email.component';
import {InvoiceFromCustomersComponent} from './views/invoice-from-customers/invoice-from-customers.component';
import {ThankYouPageComponent} from './views/thank-you-page/thank-you-page.component';
import {CreatePasswordComponent} from './views/create-password/create-password.component';
import {ResetPasswordComponent} from './views/reset-password/reset-password.component';
import {ExpressCheckoutComponent} from './views/express-checkout/express-checkout.component';
import {StatusPageComponent} from './views/status-page/status-page.component';
import {ExternalBankAccauntComponent} from './views/external-bank-accaunt/external-bank-accaunt.component';
import {SignupManagerComponent} from './views/signup-manager/signup-manager.component';
import {PasswordSetComponent} from './views/password-set/password-set.component';
import {CaptureAuthComponent} from './views/capture-auth/capture-auth.component';
import {SocialiteAuthComponent} from './views/socialite-auth/socialite-auth.component';
import {ManagerAccountComponent} from './views/manager-account/manager-account.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  {
    path: 'socialite',
    component: SocialiteAuthComponent,
  },
  {
    path: 'invite-rep/:rep_u_token',
    loadChildren: () =>
      import('./views/invite-rep/invite-rep.module').then(
        (m) => m.InviteRepModule
      ),
    data: {
      title: 'Invite Rep',
    },
  },
  {
    path: 'status-page',
    component: StatusPageComponent,
    data: {
      title: 'Status Page',
    },
  },
  {
    path: 'login/:wp_auth_token',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  {
    path: 'create-password',
    component: CreatePasswordComponent,
    data: {
      title: 'Create Password Page',
    },
  },
  {
    path: 'create-password/:create_password_token',
    component: CreatePasswordComponent,
    data: {
      title: 'Create Password Page',
    },
  },
  {
    path: 'password-set/:create_password_token',
    component: PasswordSetComponent,
    data: {
      title: 'Set Password Page',
    },
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    data: {
      title: 'Sign Up Page',
    },
  },
  {
    path: 'sign-up/partner/:partner_name',
    component: SignUpComponent,
    data: {
      title: 'Sign Up Page',
    },
  },
  {
    path: 'sign-up/partner/:partner_name/:rep_name',
    component: SignUpComponent,
    data: {
      title: 'Sign Up Page',
    },
  },
  {
    path: 'sign-up/agent/:partner_name',
    component: SignUpComponent,
    data: {
      title: 'Sign Up Page',
    },
  },
  {
    path: 'signup/:manager_token',
    component: SignupManagerComponent,
    data: {
      title: 'Sign Up Page',
    },
  },
  {
    path: 'sign-up/:invite_code',
    component: SignUpComponent,
    data: {
      title: 'Sign Up Page',
    },
  },
  {
    path: 'recovery-password/:token_password',
    component: RecoveryPasswordComponent,
    data: {
      title: 'Recovery Password',
    },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password',
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page',
    },
  },
  {
    pathMatch: 'full',
    path: 'sandbox/check/:pay_token',
    component: DigitalCheckComponent,
    data: {
      title: 'Here\'s your Check',
      isSandbox: true,
    },
  },
  {
    pathMatch: 'full',
    path: 'check/:pay_token',
    component: DigitalCheckComponent,
    data: {
      title: 'Here\'s your Check',
      isSandbox: false,
    },
  },
  {
    pathMatch: 'full',
    path: 'check/v2/:pay_token',
    loadChildren: () =>
      import('./views/new-digital-check/new-digital-check.module').then(
        (m) => m.NewDigitalCheckModule
      ),
    data: {
      title: 'Here\'s your Check',
      isSandbox: false,
    },
  },
  {
    path: 'sandbox/invoice/:invoice_token',
    component: InvoiceFromCustomersComponent,
    data: {
      title: 'Send Online Check',
      isSandbox: true,
    },
  },
  // TODO:
  {
    path: 'invoice/:invoice_token',
    component: InvoiceFromCustomersComponent,
    data: {
      title: 'Send Online Check',
      isSandbox: false,
    },
  },
  {
    pathMatch: 'full',
    path: 'invoice/v2/:invoice_token',
    loadChildren: () =>
      import(
        './views/new-invoice-from-customers/new-invoice-from-customers.module'
        ).then((m) => m.NewInvoiceFromCustomersModule),
    data: {
      title: 'Here\'s your Check',
      isSandbox: false,
    },
  },
  {
    path: 'sandbox/checkout/:checkout_token',
    component: InvoiceFromCustomersComponent,
    data: {
      title: 'Send Online Check',
      isSandbox: true,
    },
  },
  {
    path: 'checkout/:checkout_token',
    component: InvoiceFromCustomersComponent,
    data: {
      title: 'Send Online Check',
      isSandbox: false,
    },
  },
  {
    path: 'express-checkout/:base64_token',
    component: ExpressCheckoutComponent,
    data: {
      title: 'Express Send Online Check',
      isSandbox: false,
    },
  },
  // old external bank account
  {
    path: 'bank-account/update/:u_token/:ba_token',
    component: ExternalBankAccauntComponent,
    data: {isAddBA: false, isVerifyBA: false, isUpdateBA: true},
  },
  {
    path: 'bank-account/:public_token/:u_token',
    component: ExternalBankAccauntComponent,
    data: {isAddBA: true, isVerifyBA: false, isUpdateBA: false},
  },
  {
    path: 'bank-account/:public_token/:u_token/:ba_token',
    component: ExternalBankAccauntComponent,
    data: {isAddBA: false, isVerifyBA: true, isUpdateBA: false},
  },

  // new external bank account with react and new package for plaid
  // ExtBankAccountModule
  // {
  //   path: "bank-account/update/:u_token/:ba_token",
  //   //component: ExtBankAccountComponent,
  //   loadChildren: () =>
  //     import("./views/ext-bank-account/ext-bank-account.module").then(
  //       (m) => m.ExtBankAccountModule
  //     ),
  //   data: { isAddBA: false, isVerifyBA: false, isUpdateBA: true },
  // },
  // {
  //   path: "bank-account/:public_token/:u_token",
  //   loadChildren: () =>
  //     import("./views/ext-bank-account/ext-bank-account.module").then(
  //       (m) => m.ExtBankAccountModule
  //     ),
  //   data: { isAddBA: true, isVerifyBA: false, isUpdateBA: false },
  // },
  // {
  //   path: "bank-account/:public_token/:u_token/:ba_token",
  //   loadChildren: () =>
  //     import("./views/ext-bank-account/ext-bank-account.module").then(
  //       (m) => m.ExtBankAccountModule
  //     ),
  //   data: { isAddBA: false, isVerifyBA: true, isUpdateBA: false },
  // },

  {
    path: 'confirm-email/:confirm_token',
    component: ConfirmEmailComponent,
    data: {
      title: 'Confirm Email',
    },
  },
  {
    path: 'capture-auth/:u_token',
    component: CaptureAuthComponent,
    data: {
      title: 'Capture Authorization',
    },
  },
  {
    path: 'thank-you',
    component: ThankYouPageComponent,
    data: {
      title: 'Thank You',
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home',
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'customers',
        loadChildren: () =>
          import('./views/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Demo', 'Partner', 'Rep'],
          isPartnerUsers: false,
        },
      },
      {
        path: 'partner-apps',
        loadChildren: () =>
          import('./views/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Demo', 'Partner', 'Rep'],
          isAppUsers: true,
          isPartnerUsers: true,
        },
      },
      {
        path: 'partner-merchants',
        loadChildren: () =>
          import('./views/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Demo', 'Partner', 'Rep'],
          isMerchantUsers: true,
          isPartnerUsers: true,
        },
      },
      // {
      //   path: 'customers/:id',
      //   loadChildren: () => import('./views/customer-detail/customer-detail.module#CustomerDetailModule').then(m => m.CustomerDetailModule),
      //   data: {
      //     expectedRoles: ['Merchant', 'Demo']
      //   }
      // },

      {
        path: 'transaction',
        loadChildren: () =>
          import('./views/dwolla-transactions/dwolla-transactions.module').then(
            (m) => m.DwollaTransactionsModule
          ),
      },

      {
        path: 'transfer-money',
        loadChildren: () =>
          import('./views/transfer-money/transfer-money.module').then(
            (m) => m.TransferMoneyModule
          ),
      },
      {
        path: 'send-money',
        loadChildren: () =>
          import('./views/send-money/send-money.module').then(
            (m) => m.SendMoneyModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Demo'],
        },
      },
      {
        path: 'payment-page',
        loadChildren: () =>
          import('./views/billing-link/billing-link.module').then(
            (m) => m.BillingLinkModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Demo'],
        },
      },
      {
        path: 'receive-money',
        loadChildren: () =>
          import('./views/request-check/request-check.module').then(
            (m) => m.RequestCheckModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Demo'],
        },
      },
      {
        path: 'send-mass-payouts',
        loadChildren: () =>
          import('./views/send-batch/send-batch.module').then(
            (m) => m.SendBatchModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Demo'],
        },
      },
      {
        path: 'partners',
        loadChildren: () =>
          import('./views/partner/partner.module').then((m) => m.PartnerModule),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'partners-settings',
        loadChildren: () =>
          import('./views/partner-settings/partner-settings.module').then(
            (m) => m.PartnerSettingsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'report-analytics',
        loadChildren: () =>
          import('./views/partner-report/partner-report.module').then(
            (m) => m.PartnerReportModule
          ),
        data: {
          expectedRoles: ['Partner', 'Rep'],
        },
      },
      {
        path: 'risk-settings',
        loadChildren: () =>
          import('./_shared_modules/risk-settings/risk-settings.module').then(
            (m) => m.RiskSettingsModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Client', 'Customer', 'Demo'],
        },
      },

      //      {
      //        path: 'buttons',
      //        loadChildren: () => import('./views/buttons/buttons.module#ButtonsModule).then(m => m.ButtonsModule)'
      //      },
      //      {
      //        path: 'charts',
      //        loadChildren: () => import('./views/chartjs/chartjs.module#ChartJSModule).then(m => m.ChartJSModule)'
      //      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },

      //      {
      //        path: 'icons',
      //        loadChildren: () => import('./views/icons/icons.module#IconsModule).then(m => m.IconsModule)'
      //      },
      //      {
      //        path: 'notifications',
      //        loadChildren: () => import('./views/notifications/notifications.module#NotificationsModule).then(m => m.NotificationsModule)'
      //      },
      //      {
      //        path: 'theme',
      //        loadChildren: () => import('./views/theme/theme.module#ThemeModule).then(m => m.ThemeModule)'
      //      },
      //      {
      //        path: 'widgets',
      //        loadChildren: () => import('./views/widgets/widgets.module#WidgetsModule).then(m => m.WidgetsModule)'
      //      }

      {
        path: 'account',
        loadChildren: () =>
          import('./views/account/account.module').then((m) => m.AccountModule),
        data: {
          expectedRoles: [
            'Merchant',
            'Client',
            'Customer',
            'Demo',
            'Partner',
            'Rep'
          ],
        },
      },
      {
        path: 'recurring',
        loadChildren: () =>
          import('./views/manage-recuring/manage-recuring.module').then(
            (m) => m.ManageRecuringModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Client', 'Customer', 'Demo'],
        },
      },
      {
        path: 'account/:tab_name',
        loadChildren: () =>
          import('./views/account/account.module').then((m) => m.AccountModule),
        data: {
          expectedRoles: [
            'Merchant',
            'Client',
            'Customer',
            'Demo',
            'Partner',
            'Rep',
          ],
        },
      },
      {
        path: 'account/:tab_name/:action',
        loadChildren: () =>
          import('./views/account/account.module').then((m) => m.AccountModule),
        data: {
          expectedRoles: [
            'Merchant',
            'Client',
            'Customer',
            'Demo',
            'Partner',
            'Rep',
          ],
        },
      },
      {
        path: 'admin-account',
        loadChildren: () =>
          import('./views/admin-account/admin-account.module').then((m) => m.AdminAccountModule),
        data: {
          expectedRoles: [
            'Admin',
            'SuperAdmin',
          ],
        },
      },
      {
        path: 'manager-account/:tab_name',
        component: ManagerAccountComponent,
        data: {
          expectedRoles: [
            'Merchant',
            'Manager',
          ],
        }
      },
      {
        path: 'admin-account/:tab_name',
        loadChildren: () =>
          import('./views/admin-account/admin-account.module').then((m) => m.AdminAccountModule),
        data: {
          expectedRoles: [
            'Admin',
            'SuperAdmin',
          ],
        },
      },
      {
        path: 'transactions',
        loadChildren: () =>
          import('./views/send-checks/send-checks.module').then(
            (m) => m.SendChecksModule
          ),
        data: {
          expectedRoles: [
            'Admin',
            'SuperAdmin',
            'Merchant',
            'Client',
            'Customer',
            'Demo',
          ],
        },
      },
      {
        path: 'payments',
        loadChildren: () =>
          import('./views/payments/payments.module').then(
            (m) => m.PaymentsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'mail-template',
        loadChildren: () =>
          import('./views/mail/mail.module').then((m) => m.MailModule),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
          isClientMail: false,
        },
      },
      {
        path: 'mail-template-client',
        loadChildren: () =>
          import('./views/mail/mail.module').then((m) => m.MailModule),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
          isClientMail: true,
        },
      },
      {
        path: 'multiple-phone',
        loadChildren: () =>
          import('./views/multiple-phone/multiple-phone.module').then(
            (m) => m.RulesForSignupModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'mail-storage',
        loadChildren: () =>
          import('./views/mail-history/mail-history.module').then(
            (m) => m.MailHistoryModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'users/:dwl_token/:u_token',
        loadChildren: () =>
          import('./views/merchant-profile/merchant-profile.module').then(
            (m) => m.MerchantProfileModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'agent/:u_token',
        loadChildren: () =>
          import('./views/agent-report/agent-report.module').then(
            (m) => m.AgentReportModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'users/:dwl_token/:u_token/:tab_name',
        loadChildren: () =>
          import('./views/merchant-profile/merchant-profile.module').then(
            (m) => m.MerchantProfileModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./views/users/users.module').then((m) => m.UsersModule),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'users/:users_type',
        loadChildren: () =>
          import('./views/users/users.module').then((m) => m.UsersModule),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        }
      },
      {
        path: 'statuses',
        loadChildren: () =>
          import('./views/statuses/statuses.module').then(
            (m) => m.StatusesModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'account-settings',
        // loadChildren: () => import('./views/settings/settings.module).then(m => m.SettingsModule)'
        redirectTo: '/transactions',
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./views/message-suggestion/message-suggestion.module').then(
            (m) => m.MessageSuggestionModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'agent-rotation',
        loadChildren: () =>
          import('./views/agent-rotation/agent-rotation.module').then(
            (m) => m.AgentRotationModule
          ),
        data: {
          expectedRoles: ['SuperAdmin'],
        },
      },
      {
        path: 'invitations',
        loadChildren: () =>
          import('./views/invitations/invitations.module').then(
            (m) => m.InvitationsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'plans',
        loadChildren: () =>
          import('./views/plans/plans.module').then((m) => m.PlansModule),
        data: {
          expectedRoles: [
            'Merchant',
            'Admin',
            'SuperAdmin',
            'Client',
            'Customer',
            'Demo',
          ],
        },
      },
      {
        path: 'teams',
        loadChildren: () =>
          import('./views/teams/teams.module').then((m) => m.TeamsModule),
        data: {
          expectedRoles: ['Partner', 'SuperAdmin', 'Rep'],
        },
      },
      {
        path: 'marketplace',
        loadChildren: () =>
          import('./views/marketplace/marketplace.module').then(
            (m) => m.MarketplaceModule
          ),
        data: {
          expectedRoles: ['Merchant', 'MarketPlace'],
        },
      },
      {
        path: 'marketplace/:tab_name',
        loadChildren: () =>
          import('./views/marketplace/marketplace.module').then(
            (m) => m.MarketplaceModule
          ),
        data: {
          expectedRoles: ['Merchant', 'MarketPlace'],
        },
      },
      {
        path: 'request-payments',
        loadChildren: () =>
          import('./views/client-invoices/client-invoices.module').then(
            (m) => m.ClientInvoicesModule
          ),
        data: {
          expectedRoles: ['Client', 'Customer', 'Demo'],
        },
      },
      {
        path: 'accept-checks',
        loadChildren: () =>
          import('./views/accept-checks/accept-checks.module').then(
            (m) => m.AcceptChecksModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Client', 'Customer', 'Demo'],
        },
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import('./views/subscription/subscription.module').then(
            (m) => m.SubscriptionModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'funding-sources',
        loadChildren: () =>
          import('./views/funding-sources/funding-sources.module').then(
            (m) => m.FundingSourcesModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
          isPrimaryAccount: true,
        },
      },
      {
        path: 'webhooks',
        loadChildren: () =>
          import('./views/webhooks/webhooks.module').then(
            (m) => m.WebhooksModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'costs',
        loadChildren: () =>
          import('./views/costs/costs.module').then(
            (m) => m.CostsModule
          ),
        data: {
          expectedRoles: ['SuperAdmin', 'Admin'],
          isBlockList: true,
        },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./views/admin-settings/admin-settings.module').then(
            (m) => m.AdminSettingsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
          isBlockList: true,
        },
      },
      {
        path: 'deal-tracker',
        loadChildren: () =>
          import('./views/deal-tracker/deal-tracker.module').then(
            (m) => m.DealTrackerModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'reports/returns',
        loadChildren: () =>
          import('./views/returns-report/returns-report.module').then(
            (m) => m.ReturnsReportModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./views/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'email-settings',
        loadChildren: () =>
          import('./views/admin-settings/admin-settings.module').then(
            (m) => m.AdminSettingsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
          isBlockList: false,
        },
      },
      {
        path: 'canned-responses',
        loadChildren: () =>
          import('./views/canned-responses/canned-responses.module').then(
            (m) => m.CannedResponsesModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'rules-for-signup',
        loadChildren: () =>
          import('./views/rules-for-signup/rules-for-signup.module').then(
            (m) => m.RulesForSignupModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'rules-for-transactions',
        loadChildren: () =>
          import(
            './views/rules-for-transactions/rules-for-transactions.module'
            ).then((m) => m.RulesForTransactionsModule),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'admins',
        loadChildren: () =>
          import('./views/admins/admins.module').then((m) => m.AdminsModule),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'admin-settings/:u_token',
        loadChildren: () =>
          import('./views/edit-admin/edit-admin.module').then(
            (m) => m.EditAdminModule
          ),
        data: {
          expectedRoles: ['SuperAdmin'],
        },
      },
      {
        path: 'admin-settings/:u_token/:tab_name',
        loadChildren: () =>
          import('./views/edit-admin/edit-admin.module').then(
            (m) => m.EditAdminModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'agent-merchants',
        loadChildren: () =>
          import('./views/agent-merchants/agent-merchants.module').then(
            (m) => m.AgentMerchantsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'agent-commissions',
        loadChildren: () =>
          import('./views/agent-commissions/agent-commissions.module').then(
            (m) => m.AgentCommissionsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'partner-settings/:u_token',
        loadChildren: () =>
          import('./views/edit-partner/edit-partner.module').then(
            (m) => m.EditPartnerModule
          ),
        data: {
          expectedRoles: ['SuperAdmin', 'Admin'],
        },
      },
      {
        path: 'dashboard-analytics',
        loadChildren: () =>
          import('./views/dashboard-analytics/dashboard-analytics.module').then(
            (m) => m.DashboardAnalyticsModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'partner-analytics',
        loadChildren: () =>
          import('./views/partner-analytics/partner-analytics.module').then(
            (m) => m.PartnerAnalyticsModule
          ),
        data: {
          expectedRoles: ['Partner', 'Rep'],
        },
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('./_shared_modules/billing/billing.module').then(
            (m) => m.BillingModule
          ),
        data: {
          expectedRoles: ['Merchant', 'Client', 'Customer', 'Demo'],
        },
      },
      {
        path: 'campaign-statistic',
        loadChildren: () =>
          import('./views/campaign-statistic/campaign-statistic.module').then(
            (m) => m.CampaignStatisticModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'bank-holiday',
        loadChildren: () =>
          import('./views/bank-holiday/bank-holiday.module').then(
            (m) => m.BankHolidayModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin'],
        },
      },
      {
        path: 'email-log',
        loadChildren: () =>
          import('./views/email-log/email-log.module').then(
            (m) => m.EmailLogModule
          ),
        data: {
          expectedRoles: ['Admin', 'SuperAdmin', 'Merchant'],
        },
      },

      //      {
      //        path: 'recurring',
      //        loadChildren: () => import('./views/recurring/recurring.module).then(m => m.RecurringModule)'
      //      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  //  declarations: [PurchasePlanComponent]
})
export class AppRoutingModule {
}
