import { Component, OnInit, ViewChild, DoCheck } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

import { UserService } from "../../_services/user.service";
import { StatesService } from "../../_services/states.service";
import { ErrorService } from "../../_services/error.service";
import { JqueryService } from "../../_services/jquery.service";

import { validateBirthDate } from "../../_vaidators/birthDate";
import { validateUrlSite } from "../../_vaidators/urlSite";
import { isContainedInArray } from "../../_vaidators/isContainedInArray";
import { validateUsaZipCode } from "../../_vaidators/zipCode";
import {
  validateFirstName,
  validateBussinesName,
  validateLastName,
} from "../../_vaidators/fullName";
import { MessagesService } from "../../_services/messages.service";
import { Utility } from "../../_helpers/utility";

import { TopAlertsService } from "../../_services/top-alerts.service";

import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FakersService } from "../../_services/fakers.service";

@Component({
  selector: "app-onboard-register-in-dwolla",
  templateUrl: "./onboard-register-in-dwolla.component.html",
  styleUrls: ["./onboard-register-in-dwolla.component.css"],
  providers: [ErrorService],
})
export class OnboardRegisterInDwollaComponent implements OnInit, DoCheck {
  minDate = new Date(1918, 0, 1);

  @ViewChild("stepper") stepper;
  @ViewChild("stepper2") stepper2;

  public host: string = environment.host;
  arrBusinessTypes: any;
  arrBusinessStructures: any;
  dtp: any;
  arrStates: any;
  arrCountries: any;
  arrBusinessClassification: any;
  arrBusinessClassificationMap: any = [];
  isPersonalAccount = true;
  isBusinessAccount = false;
  isRequireBusController = false;
  isRequireControllerPassport = false;
  isRequireBeneficiarPassport = false;
  showPopupSteper1 = true;
  showPopupSteper2 = false;
  canShowContinueButton = false;
  canContinueSignUp = true;
  maskEIN: any = [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public maskDateBirth: any = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  };
  public maskPhone: any = [
    "(",
    /\d/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  isDebugMode: boolean = environment.dubugModeForRegDwolla;
  isAutoFillForSandbox: boolean = environment.subDomen == "sandbox";
  isLoading = false;
  isSuccess: boolean;
  isOfficerSameAsOwner = false;
  showDBAandSSN = false;
  loadSecondStep = false;

  public businessTypeFormGroup: FormGroup;
  personalFormGroupFirst: FormGroup;
  personalFormGroupSecond: FormGroup;

  businesFormGroup: FormGroup;
  businesFormGroupStep1: FormGroup;
  businesFormGroupStep2: FormGroup;

  controllerBusinessFormGroup: FormGroup;
  controllerBusinessFormGroupStep1: FormGroup;
  controllerBusinessFormGroupStep2: FormGroup;
  // controllerPassportBusinessFormGroup: FormGroup;
  beneficiarFormGroup: FormGroup;
  businessClassificationOptions: Observable<string[]>;
  objRequiredControls = {
    ein: false,
    businessIncorporationDate: false,
    ownerAddressPostalCode: true,
    beneficialAddressPostalCode: true,
  };
  arrCitizenshipStatus = this._stateService.arrCitizenshipStatus;
  isArrBusinessClassification = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private _formBuilder: FormBuilder,
    private _stateService: StatesService,
    public jqueryService: JqueryService,
    public errorService: ErrorService,
    public messages: MessagesService,
    public utility: Utility,
    public topAlertsService: TopAlertsService,
    public fakersService: FakersService
  ) {
    if (!this.userService.isAdmin() && !this.userService.isSuperAdmin()) {
      this.getBusinessClassification();
    }
  }

  ngDoCheck() {
    if (this.isArrBusinessClassification) {
      this.businesFormGroupStep1 = this._formBuilder.group({
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", Validators.required],
        phone: [""],
        businessName: [
          "",
          Validators.compose([Validators.required, validateBussinesName]),
        ],
        doingBusinessAs: [""],
        businessType: ["", Validators.required],
        businessClassification: ["", Validators.required],
        businessClassificationFilter: [
          "",
          [isContainedInArray(this.arrBusinessClassification)],
        ],
        // ein: ['', Validators.required],
        ein: [""],
        dataTransferProtocol: ["http://", Validators.required],
        website: [
          "",
          Validators.compose([Validators.required, validateUrlSite]),
        ],
        dateOfBirth: [
          "",
          Validators.compose([Validators.required, validateBirthDate]),
        ],
        businessIncorporationDate: [""],
        ssn: ["", Validators.required],
      });

      this.businessClassificationOptions = this.businesFormGroupStep1
        .get("businessClassificationFilter")
        .valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value.toLowerCase()))
        );

      if (this.isDebugMode) {
        this.businesFormGroupStep1.patchValue({
          businessName: "test business name",
          doingBusinessAs: "test test",
          businessType: "soleProprietorship",
          businessClassification: "9ed35a22-7d6f-11e3-9b52-5404a6144203",
          dataTransferProtocol: "http://",
          website: "www.test.com",
          ein: "12-4567890",
          dateOfBirth: new Date(1968, 10, 10),
          businessIncorporationDate: new Date(1968, 10, 10),
          ssn: "1234",
        });
      }

      if (!this.isAutoFillForSandbox) {
        this.autoFillCustomerData();
      }

      if (this.isAutoFillForSandbox) {
        this.businesFormGroupStep1.patchValue({
          firstName: this.fakersService.getFirstName(),
          lastName: this.fakersService.getLastName(),
          businessName: "",
          doingBusinessAs: "",
          businessType: "corporation",
          businessClassification: "9ed3a851-7d6f-11e3-92a4-5404a6144203", // Software
          businessClassificationFilter: "Software", // Software
          dataTransferProtocol: "https://",
          website: "www.seamlesschex.com",
          ein: "12-1234567",
          dateOfBirth: new Date(1990, 1, 1),
          businessIncorporationDate: new Date(2010, 1, 1),
          ssn: "123-34-5678",
          email: this.userService.getEmail(),
        });
        this.changeBusinessType("corporation");

        this.businesFormGroupStep2.patchValue({
          address1: "100 Main Street",
          address2: "",
          city: "Springfield",
          state: "NY",
          postalCode: "12345",
        });

        this.controllerBusinessFormGroupStep1.patchValue({
          firstName: this.fakersService.getFirstName(),
          lastName: this.fakersService.getLastName(),
          dateOfBirth: new Date(1990, 1, 1),
          ssn: "1234",
          title: "Sandbox Business Name",
          citizenshipStatus: "US Citizen",
        });

        this.changeCitizenshipStatus("US Citizen");

        this.controllerBusinessFormGroupStep2.patchValue({
          address1: "100 Main Street",
          address2: "",
          city: "Springfield",
          stateProvinceRegion: "NY",
          postalCode: "12345",
          country: "US",
        });

        this.beneficiarFormGroup.patchValue({
          firstName: this.fakersService.getFirstName(),
          lastName: this.fakersService.getLastName(),
          dateOfBirth: new Date(1990, 1, 1),
          address1: "100 Main Street",
          address2: "",
          city: "Springfield",
          stateProvinceRegion: "NY",
          postalCode: "12345",
          country: "US",

          number: "12345",
          passportCountry: "US",
          citizenshipStatus: "US Citizen",
        });

        this.changeBeneficialCitizenshipStatus("US Citizen");
      }

      this.isArrBusinessClassification = false;
      if (
        !this.userService.isPersonalSignupApprove() &&
        !this.userService.isClient()
      ) {
        this.loadSecondStep = true;
      }
    }

    if (
      this.loadSecondStep &&
      this.businesFormGroupStep1 &&
      this.userService.isHaveBankAccount() &&
      this.userService.isBankStatementUploaded()
    ) {
      console.log('rrrrrrrrrrr4rrrrrrrrrr')
      this.applyAfterClickBannerSetupProfile("business", 1);
      this.loadSecondStep = false;
    }
  }

  ngOnInit() {
    this.userService.initUser();
    this.arrBusinessTypes = [
      { value: "personal", name: "Personal" },
      { value: "business", name: "Business" },
    ];
    this.dtp = [
      { value: "http://", name: "http://" },
      { value: "https://", name: "https://" },
    ];

    this.arrBusinessStructures = [
      { value: "corporation", name: "Corporation" },
      { value: "llc", name: "LLC" },
      { value: "partnership", name: "Partnership" },
      { value: "soleProprietorship", name: "Sole Proprietorship" },
    ];

    this.arrStates = this._stateService.arrStates;
    this.arrCountries = this._stateService.arrCountrise;

    this.businessTypeFormGroup = this._formBuilder.group({
      type: ["", Validators.required],
    });

    this.personalFormGroupFirst = this._formBuilder.group({
      firstName: [
        "",
        Validators.compose([Validators.required, validateFirstName]),
      ],
      lastName: [
        "",
        Validators.compose([Validators.required, validateLastName]),
      ],
      email: [""],
      phone: [""],
      dateOfBirth: [
        "",
        Validators.compose([Validators.required, validateBirthDate]),
      ],
      ssn: ["", Validators.required],
    });
    this.personalFormGroupFirst.get("email").disable();
    this.personalFormGroupFirst.get("phone").disable();
    this.personalFormGroupSecond = this._formBuilder.group({
      address1: ["", Validators.required],
      address2: [""],
      city: ["", Validators.required],
      state: ["", Validators.required],
      postalCode: ["", Validators.required],
    });

    this.businesFormGroupStep2 = this._formBuilder.group({
      address1: ["", Validators.required],
      address2: [""],
      city: ["", Validators.required],
      state: ["", Validators.required],
      // postalCode: ['', Validators.compose([Validators.minLength(5), Validators.maxLength(5), Validators.required])],
      postalCode: [
        "",
        Validators.compose([validateUsaZipCode, Validators.required]),
      ],
    });

    this.controllerBusinessFormGroupStep1 = this._formBuilder.group({
      isOfficerSameAsOwner: [false],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      dateOfBirth: [
        "",
        Validators.compose([Validators.required, validateBirthDate]),
      ],
      ssn: ["", Validators.required],
      title: ["", Validators.required],
      citizenshipStatus: ["", Validators.required],
      controller_number: ["", Validators.required],
      controller_country: ["", Validators.required],
    });
    this.controllerBusinessFormGroupStep2 = this._formBuilder.group({
      address1: ["", Validators.required],
      address2: [""],
      city: ["", Validators.required],
      stateProvinceRegion: ["", Validators.required],
      postalCode: [""],
      country: ["US", Validators.required],
    });

    ////////////// Beneficiar Owner //////////////////////

    this.beneficiarFormGroup = this._formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      dateOfBirth: [
        "",
        Validators.compose([Validators.required, validateBirthDate]),
      ],
      ssn: ["", Validators.required],
      isBeneficialSameAsOfficer: [false],
      address1: ["", Validators.required],
      address2: [""],
      city: ["", Validators.required],
      stateProvinceRegion: ["", Validators.required],
      postalCode: [
        "",
        Validators.compose([Validators.minLength(5), Validators.maxLength(5)]),
      ],
      country: ["US", Validators.required],
      number: ["", Validators.required],
      passportCountry: ["", Validators.required],
      citizenshipStatus: ["", Validators.required],
    });
  }

  private _filter(filterValue: string): string[] {
    const arrBusinessClassificationMap: any = {};
    const arrBusinessClassification = this.arrBusinessClassification.map((x) =>
      Object.assign([], x)
    );

    arrBusinessClassification.forEach((element: any) => {
      element.list = element.list.filter(function (item: any) {
        arrBusinessClassificationMap[item.name] = item.id;
        return item.name.toLowerCase().indexOf(filterValue) >= 0;
      });
    });

    this.arrBusinessClassificationMap = arrBusinessClassificationMap;

    return arrBusinessClassification.filter(function (element: any) {
      return element.list.length > 0;
    });
  }

  disableFunctional(type = "") {
    if (this.userService.isPersonalSignupApprove()) {
      this.selectVerifiedAccountType("personal");
      return;
    }

    if (type === "disable_personal_account") {
      this.errorService.getMessageError({
        message: this.messages.get("DISABLE_SIGNUP_PERSONAL_ACCOUNT"),
      });
    }
    return;
  }

  changeStep(index: number) {
    if (!this.stepper) {
      return;
    }
    this.stepper.selectedIndex = index;
    console.log( this.stepper.selectedIndex )
  }
  changeStepBeneficiarForm(index: number) {
    if (!this.stepper2) {
      return;
    }
    this.stepper2.selectedIndex = index;
  }

  selectVerifiedAccountType(strAccountType: string) {
    if (
      strAccountType === "personal" &&
      !this.userService.isPersonalSignupApprove() &&
      this.userService.isMerchant()
    ) {
      this.canContinueSignUp = false;
      this.lockPerconalAccount();
      this.errorService.getMessageError({
        message: this.messages.get("PERSONAL_SIGNUP_LOCK"),
      });
      setTimeout(() => {
        this.userService.logout();
      }, 10000);
    } else {
      if( !this.userService.isHaveBankAccount() ) {
        this.jqueryService.closeModal('.onboarding-in-dwolla-modal');
        this.jqueryService.showModal('.ach-processing-profile-setup-modal', {
          backdrop: "static",
          width: 750,
          keyboard: false,
          showClose: true,
        });
      } else {
        this.businessTypeFormGroup.patchValue({ type: strAccountType });
        this.changeAccountType({ value: strAccountType });
        this.changeStep(1);
      }
      //this.userService.needEnterFundingSource( true );
    }
  }

  applyAfterClickBannerSetupProfile(strAccountType: string, index: number) {
    console.log('applyAfterClickBannerSetupProfile', 'rrrrrrrrrrr4rrrrrrrrrr')
    this.businessTypeFormGroup.patchValue({ type: strAccountType });
    this.changeAccountType({ value: strAccountType });
    this.changeStep(index);
  }

  lockPerconalAccount() {
    const objRequest = {
      u_token: this.userService.getToken(),
    };
    this.http.post<any>(
      this.host + "/customer/personal/signup/lock",
      objRequest
    );
  }

  autoFillCustomerData() {
    this.personalFormGroupFirst.patchValue({
      firstName: this.userService.getFirstName(),
      email: this.userService.getEmail(),
      lastName: this.userService.getLastName(),
      phone: this.userService.getPhone()
        ? this.userService.getPhone().replace(/[^0-9]*/g, "")
        : "",
    });

    this.businesFormGroupStep1.patchValue({
      firstName: this.userService.getFirstName(),
      email: this.userService.getEmail(),
      lastName: this.userService.getLastName(),
      businessName: this.userService.getBussinesName(),
      phone: this.userService.getPhone()
        ? this.userService.getPhone().replace(/[^0-9]*/g, "")
        : "",
    });

    if (this.isDebugMode) {
      this.personalFormGroupFirst.patchValue({
        dateOfBirth: new Date(1986, 2, 10),
        ssn: "1234",
      });
      this.personalFormGroupSecond.patchValue({
        address1: "test 22 test",
        address2: "test",
        city: "TEST",
        state: "FL",
        postalCode: "12345",
      });

      this.controllerBusinessFormGroupStep1.patchValue({
        isOfficerSameAsOwner: true,
        firstName: this.userService.getFirstName(),
        lastName: this.userService.getLastName(),
        dateOfBirth: new Date(1968, 10, 10),
        // ssn: '1234',
        title: "test business name",
      });

      this.controllerBusinessFormGroupStep2 = this._formBuilder.group({
        address1: "test 22 test",
        address2: "test",
        city: "TEST",
        stateProvinceRegion: "FL",
        postalCode: "12345",
        country: "US",
      });

      this.businesFormGroupStep2.patchValue({
        address1: "test 22 test",
        address2: "test 33 test",
        city: "test",
        state: "FL",
        postalCode: "12345",
      });

      this.beneficiarFormGroup.patchValue({
        isBeneficialSameAsOfficer: false,
        firstName: this.userService.getFirstName(),
        lastName: this.userService.getLastName(),
        dateOfBirth: new Date(1968, 10, 10),
        ssn: "1234",

        address1: "test 22 test",
        address2: "test",
        city: "TEST",
        stateProvinceRegion: "FL",
        postalCode: "12345",
        country: "US",

        number: "12345",
        passportCountry: "US",
      });
    }
    if (this.userService.isDwollaRetry()) {
      const objDwollaUser = this.userService.getDwollaUserInfo();
      const objDwollaUserBeneficial =
        this.userService.getDwollaUserBeneficialInfo();

      if (objDwollaUser) {
        if (this.isDebugMode) {
          return;
        }
        if (this.userService.isIndividualAccount()) {
          this.personalFormGroupSecond.patchValue({
            address1: objDwollaUser.address1 || "",
            address2: objDwollaUser.address2 || "",
            city: objDwollaUser.city || "",
            state: objDwollaUser.state || "",
            postalCode: objDwollaUser.postalCode || "",
          });
        } else {
          this.businesFormGroupStep2.patchValue({
            address1: objDwollaUser.address1 || "",
            address2: objDwollaUser.address2 || "",
            city: objDwollaUser.city || "",
            state: objDwollaUser.state || "",
            postalCode: objDwollaUser.postalCode || "",
          });

          this.businesFormGroupStep1.patchValue({
            firstName: objDwollaUser.firstName || "",
            email: objDwollaUser.email || "",
            lastName: objDwollaUser.lastName || "",
            phone: objDwollaUser.phone
              ? objDwollaUser.phone.replace(/[^0-9]*/g, "")
              : "",
            businessName: objDwollaUser.businessName || "",
            doingBusinessAs: objDwollaUser.doingBusinessAs || "",
            businessType: objDwollaUser.businessType || "",
            businessClassification: objDwollaUser.businessClassification || "",
            businessClassificationFilter:
              objDwollaUser.businessClassificationFilter || "",
            dataTransferProtocol: objDwollaUser.dataTransferProtocol || "",
            website:
              objDwollaUser.website.replace(
                objDwollaUser.dataTransferProtocol,
                ""
              ) || "",
            ein: objDwollaUser.ein || "",
            dateOfBirth: new Date(objDwollaUser.dateOfBirth) || "",
            businessIncorporationDate:
              new Date(objDwollaUser.businessIncorporationDate) || "",
          });

          this.changeBusinessType(objDwollaUser.businessType);
          if (objDwollaUser.controller) {
            this.controllerBusinessFormGroupStep1.patchValue({
              isOfficerSameAsOwner:
                objDwollaUser.controller.isOfficerSameAsOwner || "",
              firstName: objDwollaUser.controller.firstName || "",
              lastName: objDwollaUser.controller.lastName || "",
              dateOfBirth: new Date(objDwollaUser.controller.dateOfBirth) || "",
              title: objDwollaUser.controller.title || "",
              citizenshipStatus:
                objDwollaUser.controller.citizenshipStatus || "",
              controller_number:
                objDwollaUser.controller.controller_number || "",
              controller_country:
                objDwollaUser.controller.controller_country || "",
            });

            this.controllerBusinessFormGroupStep2.patchValue({
              address1: objDwollaUser.controller.address1 || "",
              address2: objDwollaUser.controller.address2 || "",
              city: objDwollaUser.controller.city || "",
              stateProvinceRegion:
                objDwollaUser.controller.stateProvinceRegion || "",
              postalCode: objDwollaUser.controller.postalCode || "",
              country: objDwollaUser.controller.country || "",
            });

            if (objDwollaUserBeneficial) {
              this.beneficiarFormGroup.patchValue({
                address1: objDwollaUserBeneficial.address1,
                address2: objDwollaUserBeneficial.address2,
                citizenshipStatus: objDwollaUserBeneficial.citizenshipStatus,
                city: objDwollaUserBeneficial.city,
                country: objDwollaUserBeneficial.country,
                postalCode: objDwollaUserBeneficial.postalCode,
                dateOfBirth: new Date(objDwollaUserBeneficial.dateOfBirth),
                firstName: objDwollaUserBeneficial.firstName,
                isBeneficialSameAsOfficer:
                  objDwollaUserBeneficial.isBeneficialSameAsOfficer,
                lastName: objDwollaUserBeneficial.lastName,
                stateProvinceRegion:
                  objDwollaUserBeneficial.stateProvinceRegion,
              });

              this.changeBeneficialCitizenshipStatus(
                objDwollaUserBeneficial.citizenshipStatus
              );
            }

            this.changeCitizenshipStatus(
              objDwollaUser.controller.citizenshipStatus
            );
          }
        }
      }
    }
  }

  changeOfficerSameAsOwner() {
    if (this.controllerBusinessFormGroupStep1.value.isOfficerSameAsOwner) {
      this.controllerBusinessFormGroupStep1.patchValue({
        firstName: this.businesFormGroupStep1.value.firstName,
        lastName: this.businesFormGroupStep1.value.lastName,
        dateOfBirth: this.businesFormGroupStep1.value.dateOfBirth,
        //        ssn: this.businesFormGroupStep1.value.ssn,
      });

      this.controllerBusinessFormGroupStep2.patchValue({
        address1: this.businesFormGroupStep2.value.address1,
        address2: this.businesFormGroupStep2.value.address2,
        city: this.businesFormGroupStep2.value.city,
        stateProvinceRegion: this.businesFormGroupStep2.value.state,
        postalCode: this.businesFormGroupStep2.value.postalCode,
      });
    } else {
      this.controllerBusinessFormGroupStep1.patchValue({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        ssn: "",
      });

      this.controllerBusinessFormGroupStep2.patchValue({
        address1: "",
        address2: "",
        city: "",
        stateProvinceRegion: "",
        postalCode: "",
        country: "US",
      });
    }
  }

  selectedBussinessClassification(ev: any) {
    this.businesFormGroupStep1.patchValue({
      businessClassification:
        this.arrBusinessClassificationMap[ev.option.value],
    });
  }

  changeAccountType(target: any) {
    switch (target.value) {
      case "personal":
        this.isPersonalAccount = true;
        this.isBusinessAccount = false;
        this.isRequireBusController = false;
        break;
      case "business":
        this.isPersonalAccount = false;
        this.isBusinessAccount = true;
        break;
    }
  }

  changeCitizenOnHomeAdress(target: any) {
    this.controllerBusinessFormGroupStep2.patchValue({
      country: target,
    });
  }

  changeCitizenshipStatus(target: any) {
    this.isRequireControllerPassport = target !== "US Citizen";
    if (target == "US Citizen") {
      this.arrCountries = this._stateService.arrCountrise;
      this.controllerBusinessFormGroupStep1.controls["ssn"].enable();
      this.controllerBusinessFormGroupStep2.controls[
        "postalCode"
      ].setValidators(
        Validators.compose([validateUsaZipCode, Validators.required])
      );
      this.controllerBusinessFormGroupStep1.controls[
        "controller_number"
      ].disable();
      this.controllerBusinessFormGroupStep1.controls[
        "controller_country"
      ].disable();
      this.objRequiredControls.ownerAddressPostalCode = true;
      this.controllerBusinessFormGroupStep2.patchValue({
        country: "US",
      });
    } else {
      this.arrCountries = this._stateService.arrCountriseWithOutUS;
      this.controllerBusinessFormGroupStep1.controls["ssn"].disable();
      this.controllerBusinessFormGroupStep1.controls[
        "controller_number"
      ].enable();
      this.controllerBusinessFormGroupStep1.controls[
        "controller_country"
      ].enable();
      this.controllerBusinessFormGroupStep2.controls[
        "postalCode"
      ].setValidators(Validators.nullValidator);
      this.controllerBusinessFormGroupStep2.patchValue({
        country: "",
      });
      this.objRequiredControls.ownerAddressPostalCode = false;
    }
  }

  changeBeneficialCitizenshipStatus(target: any) {
    this.isRequireBeneficiarPassport = target !== "US Citizen";
    if (target == "US Citizen") {
      this.arrCountries = this._stateService.arrCountrise;
      this.beneficiarFormGroup.controls["ssn"].enable();
      this.beneficiarFormGroup.controls["postalCode"].setValidators(
        Validators.compose([validateUsaZipCode, Validators.required])
      );
      this.objRequiredControls.beneficialAddressPostalCode = true;
      this.beneficiarFormGroup.controls["number"].disable();
      this.beneficiarFormGroup.controls["passportCountry"].disable();
      this.beneficiarFormGroup.patchValue({
        passportCountry: "US",
        country: "US",
      });
    } else {
      this.arrCountries = this._stateService.arrCountriseWithOutUS;
      this.beneficiarFormGroup.controls["ssn"].disable();
      this.beneficiarFormGroup.controls["postalCode"].setValidators(
        Validators.nullValidator
      );
      this.beneficiarFormGroup.controls["number"].enable();
      this.beneficiarFormGroup.controls["passportCountry"].enable();
      this.objRequiredControls.beneficialAddressPostalCode = false;
    }
  }

  changeBusinessType(target: any) {
    if (target === "soleProprietorship") {
      this.showDBAandSSN = true;
      this.isRequireBusController = false;
      this.businesFormGroupStep1.controls["dateOfBirth"].enable();
      this.businesFormGroupStep1.controls["ssn"].enable();
      this.businesFormGroupStep1.controls["ein"].setValidators(
        Validators.nullValidator
      );
      this.businesFormGroupStep1.controls[
        "businessIncorporationDate"
      ].setValidators(Validators.nullValidator);
      this.objRequiredControls.ein = false;
      this.objRequiredControls.businessIncorporationDate = false;
      this.isRequireBeneficiarPassport = false;
    } else {
      this.isRequireBusController = true;
      this.showDBAandSSN = false;
      this.businesFormGroupStep1.controls["dateOfBirth"].disable();
      this.businesFormGroupStep1.controls["ssn"].disable();
      this.businesFormGroupStep1.controls["ein"].setValidators(
        Validators.compose([Validators.required, Validators.minLength(10)])
      );
      this.businesFormGroupStep1.controls[
        "businessIncorporationDate"
      ].setValidators(Validators.required);
      this.objRequiredControls.ein = true;
      this.objRequiredControls.businessIncorporationDate = true;
    }
  }

  getBusinessClassification() {
    this.http
      .get<any>(this.host + "/dwl/business-classifications/list")
      .subscribe(
        (response) => {
          if (response.success) {
            this.isArrBusinessClassification = true;
            this.arrBusinessClassification = <any>response.data;
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.errorService.getMessageError(errResponse.error);
          }
        }
      );
  }

  getUrlRegisterInDwolla() {
    let url = "/dwl/customer/create";
    //if (this.userService.isClient()) {
    //  url = '/dwl/client/create';
    //}

    return url;
  }

  registerPersonalAccount() {
    this.errorService.clearAlerts();
    if (this.stepper.selectedIndex < 3) {
      return;
    }
    this.isLoading = true;

    if (
      !this.personalFormGroupFirst.valid &&
      !this.personalFormGroupSecond.valid
    ) {
      return;
    }

    const objRequest = Object.assign(
      {},
      this.personalFormGroupFirst.value,
      this.personalFormGroupSecond.value
    );
    objRequest.u_token = this.userService.getToken();
    objRequest.type = this.businessTypeFormGroup.value.type;
    objRequest.dateOfBirth = this.utility.getDateFullFormat(
      this.personalFormGroupFirst.value.dateOfBirth
    );
    objRequest.postalCode =
      this.personalFormGroupSecond.value.postalCode.toString();
    objRequest.ipAddress = localStorage.getItem("ipClient") || null;
    objRequest.status = this.userService.getDwollaStatus();
    objRequest.create_update = true;

    this.http
      .post<any>(this.host + this.getUrlRegisterInDwolla(), objRequest)
      .subscribe(
        (response) => {
          if (response.success) {
            this.canShowContinueButton = true;
            this.isLoading = false;
            this.errorService.getMessageSuccess(response);
            this.userService.reInitClient();
          }
        },
        (errResponse) => {
          this.canShowContinueButton = false;
          this.isLoading = false;
          if (errResponse.error) {
            this.errorService.getMessageError(errResponse.error);
          }
        }
      );
  }

  registerBusinessAccount() {
    this.errorService.clearAlerts();

    if (
      !this.businesFormGroupStep1.valid &&
      !this.businesFormGroupStep2.valid
    ) {
      return false;
    }

    if (
      this.isRequireBusController &&
      !this.controllerBusinessFormGroupStep1.valid &&
      !this.controllerBusinessFormGroupStep2.valid
    ) {
      return false;
    }

    // if (this.isRequireControllerPassport && !this.controllerPassportBusinessFormGroup.valid) {
    //   return false;
    // }

    if (this.stepper.selectedIndex < 3 && !this.isRequireBusController) {
      return;
    }

    if (this.stepper.selectedIndex < 5 && this.isRequireBusController) {
      return;
    }

    this.isLoading = true;

    if (this.isRequireBusController) {
      this.showPopupSteper1 = false;
      this.showPopupSteper2 = true;
    }

    //    this.isLoading = false
    //    return

    const objRequest = Object.assign(
      {},
      this.businesFormGroupStep1.value,
      this.businesFormGroupStep2.value
    );
    objRequest.u_token = this.userService.getToken();
    objRequest.type = this.businessTypeFormGroup.value.type;
    objRequest.postalCode =
      this.businesFormGroupStep2.value.postalCode.toString();
    objRequest.ein = this.businesFormGroupStep1.value.ein.toString();
    objRequest.ipAddress = localStorage.getItem("ipClient") || null;
    objRequest.status = this.userService.getDwollaStatus();
    objRequest.create_update = true;
    if (this.businesFormGroupStep1.value.dateOfBirth) {
      objRequest.dateOfBirth = this.utility.getDateFullFormat(
        this.businesFormGroupStep1.value.dateOfBirth
      );
    }
    if (this.businesFormGroupStep1.value.businessIncorporationDate) {
      objRequest.businessIncorporationDate = this.utility.getDateFullFormat(
        this.businesFormGroupStep1.value.businessIncorporationDate
      );
    }
    if (objRequest.website !== "") {
      objRequest.website = objRequest.dataTransferProtocol + objRequest.website;
    }

    if (this.isRequireBusController) {
      const objController = Object.assign(
        {},
        this.controllerBusinessFormGroupStep1.value,
        this.controllerBusinessFormGroupStep2.value
      );
      objController.dateOfBirth = this.utility.getDateFullFormat(
        this.controllerBusinessFormGroupStep1.value.dateOfBirth
      );

      objController.address = {
        address1: this.controllerBusinessFormGroupStep2.value.address1,
        address2: this.controllerBusinessFormGroupStep2.value.address2,
        city: this.controllerBusinessFormGroupStep2.value.city,
        stateProvinceRegion:
          this.controllerBusinessFormGroupStep2.value.stateProvinceRegion.toString(),
        postalCode:
          this.controllerBusinessFormGroupStep2.value.postalCode.toString(),
        country: this.controllerBusinessFormGroupStep2.value.country,
      };

      if (this.isRequireControllerPassport) {
        objController.passport = {
          number: objController.controller_number,
          country: objController.controller_country,
        };
      }
      objRequest.controller = objController;
    }

    this.http
      .post<any>(this.host + this.getUrlRegisterInDwolla(), objRequest)
      .subscribe(
        (response) => {
          if (response.success) {
            if (this.isRequireBusController) {
              this.showPopupSteper1 = false;
              this.showPopupSteper2 = true;
            }

            this.errorService.getMessageSuccess(response);
            this.canShowContinueButton = true;
            this.isLoading = false;
            // reInitClient caused showPopupSteper2 to become false and popup disappeared
            // this.userService.reInitClient();
            this.jqueryService.pushBusinesLeadConversion();
          }
        },
        (errResponse) => {
          this.canShowContinueButton = false;
          this.isLoading = false;
          this.showPopupSteper1 = true;
          this.showPopupSteper2 = false;
          if (errResponse.error) {
            this.errorService.getMessageError(errResponse.error);
          }
        }
      );
  }

  registerBeneficialOwner() {
    if (!this.beneficiarFormGroup.valid) {
      return false;
    }

    this.changeStepBeneficiarForm(1);
    this.canShowContinueButton = false;
    this.errorService.clearAlerts();
    this.isLoading = true;

    const objRequest = Object.assign({}, this.beneficiarFormGroup.value);
    objRequest.dateOfBirth = this.utility.getDateFullFormat(
      objRequest.dateOfBirth
    );
    objRequest.address = Object.assign({}, this.beneficiarFormGroup.value);
    objRequest.address.stateProvinceRegion =
      objRequest.address.stateProvinceRegion.toString();
    objRequest.address.postalCode = objRequest.address.postalCode.toString();

    if (this.isRequireBeneficiarPassport) {
      //      objRequest.passport = Object.assign({}, this.beneficiarPassportGroup.value)
      objRequest.passport = Object.assign({}, this.beneficiarFormGroup.value);
      objRequest.passport.country = objRequest.passport.passportCountry;
    }
    objRequest.u_token = this.userService.getToken();

    this.http
      .post<any>(
        this.host + "/dwl/customer/beneficial-owner/create",
        objRequest
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.topAlertsService.popToast(
              "success",
              "Success!",
              "Your account is verified. App Pending."
            );
            this.isLoading = false;
            this.certifyBeneficialOwner();
            this.onConfirmSetupDwollaAccount();
            this.userService.reInitClient();
          }
        },
        (errResponse) => {
          this.canShowContinueButton = false;
          this.isLoading = false;
          if (errResponse.error) {
            this.errorService.getMessageError(errResponse.error);
          }
        }
      );
  }

  certifyBeneficialOwner() {
    this.http
      .post<any>(this.host + "/dwl/customer/beneficial-owner/certify", {
        u_token: this.userService.getToken(),
      })
      .subscribe(
        (response) => {
          console.log(response);
        },
        (errResponse) => {
          console.log(errResponse);
        }
      );
  }

  isShowOnLoad() {
    if (
      !this.userService.isMerchant() &&
      !this.userService.isManager() &&
      !this.userService.isRep() &&
      !this.userService.isPartner() &&
      !this.userService.isHaveBankAccount() &&
      !this.userService.isFilledInfoForDwolla()
    ) {
      return true;
    }
    return (
      this.userService.isMerchant() &&
      !this.userService.isManager() &&
      !this.userService.isRep() &&
      !this.userService.isPartner() &&
      this.userService.getSubscription() &&
      this.userService.isPasswordSet() &&
      !this.userService.isHaveBankAccount() &&
      !this.userService.isFilledInfoForDwolla()
    );
  }

  onConfirmSetupDwollaAccount() {
    this.changeStep(1);

    if (this.userService.isDwollaRetry()) {
      this.personalFormGroupFirst.get("ssn").clearValidators();
      this.businesFormGroupStep1.get("ssn").clearValidators();
    }
    this.errorService.clearAlerts();
    this.jqueryService.closeModal(".onboarding-in-dwolla-modal");
    this.userService.reInitClient();
    setTimeout(() => {
      if (
        !this.userService.isHaveBankAccount() &&
        !this.userService.isDwollaSuspended()
      ) {
        this.jqueryService.showModal(".funding-source-setup-modal.modal", {
          backdrop: "static",
          keyboard: false,
          showClose: true,
        });
      }
    }, 1000);

    this.userService.checkCertifiedBeneficialOwner();
  }
}
