import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  itemsCount = 0;
  pageNo = 1;
  pageSize = 10;
  searchQuery = '';
  sortField = 'created_at';
  sortDir = 'DESC';
  sortIcons = true;
  visiblePagination = true;
  sortDir$: BehaviorSubject<string> = new BehaviorSubject<string>('DESC');

  constructor(public userService: UserService) {}

  initPagination() {
    this.itemsCount = 0;
    this.pageNo = 1;
    this.pageSize = 10;
    this.searchQuery = '';
    this.sortField = 'created_at';
    this.sortDir = 'DESC';
    this.sortIcons = true;
    this.visiblePagination = true;
  }

  setParamsForResponce(paramObj: any) {
    // if (!paramObj.data.length) {
    //   this.visiblePagination = false;
    // } else {
    //   this.visiblePagination = true;
    // }
    this.visiblePagination = paramObj.data && paramObj.data.length > 0;
    this.itemsCount = paramObj.total;
    this.pageNo = paramObj.current_page;
  }

  applySort(sortFieldName: string, sortsDir: string, callback: any = null) {
    this.sortField = sortFieldName;
    if (sortsDir == 'DESC') {
      this.sortDir = 'ASC';
      this.sortDir$.next('ASC');
      this.sortIcons = false;
    } else {
      this.sortDir = 'DESC';
      this.sortDir$.next('DESC');
      this.sortIcons = true;
    }
    // this.pageSize = this.pageSize;
    // this.pageNo = this.pageNo;

    if (callback) {
      callback();
    }
  }

  getFilterFromLocalStorage(strStorageName: string = '') {
    return localStorage.getItem(strStorageName)
      ? JSON.parse(localStorage.getItem(strStorageName))
      : null;
  }

  setFilterInLocalStorage(strStorageName: string = '', value: any = {}) {
    localStorage.setItem(strStorageName, JSON.stringify(value));
  }

  resetFilterInLocalStorage(strStorageName: string = '') {
    localStorage.removeItem(strStorageName);
  }

  generateFilterForLocalStorage(
    strStorageName: string = '',
    objFilter: any = {}
  ) {
    const objStorageFilter = <any>Object.assign({}, { filter: objFilter });
    objStorageFilter.pagination = {
      pageSize: this.pageSize,
      pageNo: this.pageNo,
      searchQuery: this.searchQuery,
      sortField: this.sortField,
    };

    this.setFilterInLocalStorage(strStorageName, objStorageFilter);
  }
}
