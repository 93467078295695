<div class="modal-blue-bg onboarding-content">
    <div class="modal-header">
        <h4 class="modal-title">
            Multi-factor Authentication
        </h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div >
                <p style="padding-inline: 15px">
                    {{ this.title }}
                </p>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <form (keydown)="submit($event)">
                        <label>Verification Code</label>
                        <input class="form-control" id="model_verify_pin_code" placeholder="Verification Code" type="text" [(ngModel)]="form.code" name="code" required maxlength="6" #code="ngModel" appDigits>
                        <div class="help-block form-text with-errors form-control-feedback" *ngIf="!code.valid && (code.dirty || code.touched)">
                                <span *ngIf="code.errors.required">
                                    Verification Code is required
                                </span>
                            <span *ngIf="code.errors.minlength">
                                    Verification Code must be at least 6 digits long.
                                </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" style="position: relative;">
        <button type="button" class="slick-prev slick-arrow" (click)="close()">Cancel</button>
        <button [disabled]="loading || timerService.isTimerRun" *ngIf="data?.response?.body?.verify_by_google_app === undefined || !data?.response?.body?.verify_by_google_app"
                class="slick-center" (click)="resend()">
            <span>
              Resend
            </span>
            <span [innerHTML]="timerService.getRemainigTime()"></span>
        </button>
        <button [disabled]="loading || form.code.length < 6" (click)="submit({key: 'Enter',keyCode: 13})" class="slick-next slick-arrow" >Verify</button>
    </div>
</div>
