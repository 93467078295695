import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TimerService} from '../../_services/timer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../_services/user.service';
import {AuthenticationService} from '../../auth.service';

@Component({
  templateUrl: 'socialite-auth.component.html',
  styleUrls: ['socialite-auth.component.css']
})
export class SocialiteAuthComponent implements OnInit {
  model: any = {};
  loading: boolean = false;
  isUseLandingPhone: boolean = false;
  isVerifyByGoogleApp: boolean = false;
  private token: string;
  private host: string = environment.host;
  verificationTitle: string;


  constructor(

  public timerService: TimerService,
    private router: Router,
    private topAlertsService: TopAlertsService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.timerService.initTimerCD('down');
    this.activatedRoute.queryParams.subscribe(params => {
      if (params?.token !== undefined && params?.token) {
        this.router.navigate([], {
          queryParams: {}
        });
        this.token = params?.token;
        this.verifyToken();
      }
      if (this.token === undefined || this.token === '') {
        this.backToLogin();
      }
    });
  }

  login(event: any) {
    let bValid = false;
    if ( event && event.key == 'Enter' && event.keyCode == 13 && this.model.pinCode) {
      bValid = true;
    }
    if (!event && this.model.pinCode) {
      bValid = true;
    }
    if (this.loading || !bValid) return;
    this.loading = true;
    return this.http.post<any>(
      environment.host + '/socialite/auth/login',
      {token: this.token, phone_pin: this.model.pinCode},
      ).subscribe(
      result => {
        if ( result ) {
          AuthenticationService.handleSuccessLogin(result);
          this.initLogin();
        } else {
          this.topAlertsService.popToast('error', '', result.message);
          this.loading = false;
        }
      },
      errResponse => {
        this.loading = false;
        if ( errResponse.error ) {
          this.topAlertsService.popToast('error', 'Error', errResponse?.error?.message ?? errResponse.message);
          this.backToLogin();
        }
      },
      () => this.loading = false,

    );
  }

  verifyToken() {
    if (this.loading) return;
    this.loading = true;
    this.timerService.setTime(60, 's');
    return this.http.post(this.host + '/socialite/auth/verify', {token: this.token}).subscribe((result: any) => {

        if (result) {
          if ( (result.verify_by_google_app || result.message) && result.success) {
            if (result.message && result.message.indexOf('call to your phone')) {
              this.isUseLandingPhone = true;
            }
            this.isVerifyByGoogleApp = result.verify_by_google_app;
            this.topAlertsService.popToast('info', '', result.message);

            this.loading = false;
          } else if (result.success && result.token) {
            AuthenticationService.handleSuccessLogin(result);
            this.initLogin();
          }
        } else {
          this.loading = false;
        }
    },
    (error) => {
      console.log(error);
      this.topAlertsService.popToast('error', '', error?.error?.message ?? error.message);
      this.backToLogin();
    },
      () => this.loading = false
    );
  }

  backToLogin() {
    return this.router.navigate(['/login']);
  }

  initLogin() {
    this.userService.clearIntervals();
    this.userService.lastActive = Date.now();
    this.userService.initUser(true);
    setTimeout(() => { this.userService.initReloginModule(); }, 2000 );
    this.userService.retrieveUserLimits();
    this.authService.initFPBCheck().then();
  }

  resend() {
    if (this.timerService.isTimerRun) {
      return this.topAlertsService.popToast('warn', '', 'Please wait for resend ' + this.timerService.getRemainigTime());
    }
    this.verifyToken();
  }

  getVerificationMessage() {
    if (this.verificationTitle) {
      return this.verificationTitle;
    }
    return '';
  }

}
